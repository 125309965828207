<template>
  <div class="forget-send-container">
    <div class="logo-container">
      <img
        :src="ForgetSendLogo"
        class="forget-send-logo" />
      <img
        :src="ForgetSendCheck"
        class="forget-send-check" />
    </div>
    <p>已發送密码至您的邮箱</p>
  </div>
</template>

<script>
import ForgetSendLogo from '@/assets/account/forgetSendLogo.png'
import ForgetSendCheck from '@/assets/account/forgetSendCheck.png'

export default {
  data () {
    return {
      ForgetSendLogo,
      ForgetSendCheck
    }
  },
  methods: {
    updateLogin (obj) {
      this.$emit('updateLogin', obj)
    }
  },
  created () {
    setTimeout(() => {
      this.updateLogin({
        formType: null
      })
    }, 5000)
  }
}
</script>

<style lang="scss" scoped>
.forget-send-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .logo-container {
    position: relative;
    .forget-send-logo {
      position: absolute;
      left: 0;
      top: 0;
    }
    .forget-send-check {
      position: absolute;
      right: -7px;
      top: -7px;
      // background-color: #FFFFFF;
    }
  }
  p {
    color: #494949;
  }
}
@media (max-width: 575.98px) {
  .forget-send-container {
    min-height: 100%;
    height: 100%;
    justify-content: center;
    padding-bottom: 80px;
    .logo-container {
      position: relative;
      .forget-send-logo {
        position: unset;
      }
      .forget-send-check {
        position: absolute;
        right: -7px;
        top: -7px;
        background-color: #FFFFFF;
        border-radius: 50%;
      }
    }
    p {
      font-size: 18px;
      font-weight: 500;
      color: #494949;
      margin-top: 30px;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .forget-send-container {
    min-height: 100%;
    height: 100%;
    justify-content: center;
    padding-bottom: 80px;
    .logo-container {
      position: relative;
      .forget-send-logo {
        position: unset;
      }
      .forget-send-check {
        position: absolute;
        right: -7px;
        top: -7px;
        background-color: #FFFFFF;
        border-radius: 50%;
      }
    }
    p {
      font-size: 18px;
      font-weight: 500;
      color: #494949;
      margin-top: 30px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .forget-send-container {
    // gap: 30px;
    .logo-container {
      width: 73px;
      height: 65px;
      margin-bottom: 30px;
      .forget-send-logo {
        width: 100%;
        height: 100%;
      }
      .forget-send-check {
        width: 25px;
        height: 25px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .forget-send-container {
    // gap: 30px;
    .logo-container {
      width: 73px;
      height: 65px;
      margin-bottom: 30px;
      .forget-send-logo {
        width: 100%;
        height: 100%;
      }
      .forget-send-check {
        width: 25px;
        height: 25px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
@media (min-width: 1200px) {
  .forget-send-container {
    // gap: 30px;
    .logo-container {
      width: 73px;
      height: 65px;
      margin-bottom: 30px;
      .forget-send-logo {
        width: 100%;
        height: 100%;
      }
      .forget-send-check {
        width: 25px;
        height: 25px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
</style>
