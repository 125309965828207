<template>
  <div class="main">
    <div class="main-container">
      <div class="left-container">
        <logo />
      </div>
      <div class="right-container">
        <commercial v-if="!isMobile" />
        <!-- <search /> -->
      </div>
    </div>
  </div>
</template>

<script>
import Logo from './logo'
import Commercial from './commercial'
// import Search from './search'
import { isMobile } from '@/utils/device'

export default {
  name: 'headerMain',
  components: {
    Logo,
    Commercial
    // Search
  },
  computed: {
    isMobile() {
      return isMobile()
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  height: 108px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  .main-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-container {
      display: flex;
      align-items: center;
    }
    .right-container {
      // width: 620px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}
@media (max-width: 575.98px) {
  .main {
    height: 42px;
    background-color: #fafafa;
    .main-container {
      height: 100%;
      .left-container {
        width: auto;
        height: 100%;
      }
      .right-container {
        display: none;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .main {
    height: 42px;
    background-color: #fafafa;
    .main-container {
      height: 100%;
      .left-container {
        width: auto;
        height: 100%;
      }
      .right-container {
        display: none;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
  .main {
    .main-container {
      width: 1200px;
    }
  }
}
</style>
