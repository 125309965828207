import {
  getGodShopLists,
  followShop,
  getPredictHistory,
  getExpertLastPredict,
} from '@/api/client/godplay'
import { autoAPI } from '@/api/promiseApiHandler'
// import resStatus from '@/constants/resStatus'
// import { showApiErrorMessage } from '@/utils/messageHandler'
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    getGodShopLists: (state, val) => {
      return autoAPI(getGodShopLists, val)
    },
    followShop: (state, val) => {
      return autoAPI(followShop, val)
    },
    getPredictHistory: (state, val) => {
      return autoAPI(getPredictHistory, val)
    },
    getExpertLastPredict: (state, val) => {
      return autoAPI(getExpertLastPredict, val)
    },
  },
}
