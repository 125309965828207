import Vue from 'vue'
import Vuex from 'vuex'
import shop from './modules/shop'
import member from './modules/member'
import godPlay from './modules/godPlay'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    shop,
    member,
    godPlay
  },
  state: {
    isMobile: false,
    isLoading: false,
  },
  mutations: {
    setIsMobile(state, newVal) {
      state.isMobile = newVal
    },
    setLoading(state, newVal) {
      state.isLoading = newVal
    }
  },
  actions: {
  },
})
