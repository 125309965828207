<template>
  <div class="nav">
    <div class="nav-container">
      <input type="checkbox" @click="handleMenuOpen" ref="navInput" />
      <span></span>
      <span></span>
      <span></span>
      <div class="cover" @click="handleCloseMenu"></div>
      <ul>
        <li class="mobile-search-bar">
          <div class="input">
            <b-icon icon="search"></b-icon>
            <input type="text" placeholder="彩票技巧" />
          </div>
        </li>
        <li
          v-for="route in routes"
          :key="route.name"
          :ref="getRef(route)"
          @mouseover="handleMouseover(route)"
        >
          <router-link :to="handleRouteTo(route)" @click.native="handleRouteClick(route)" replace>
            {{ route.meta.context }}
          </router-link>
        </li>
      </ul>
      <!-- <div v-if="showResultList(route)"> -->
      <div
        v-if="isResultHover"
        class="route-hover-container"
        @mouseover="showResultList(true)"
        @mouseleave="showResultList(false)"
      >
        <lot-result @showResultList="showResultList" />
      </div>
    </div>
  </div>
</template>

<script>
import LotResult from './LotResult'
import { getPageRoutes } from '@/utils/route'
import { allLots } from '@/constants/lots'
import { isMobile } from '@/utils/device'

export default {
  name: 'header-nav',
  components: {
    LotResult,
  },
  computed: {
    routes: {
      get() {
        return getPageRoutes()
      },
    },
  },
  data() {
    return {
      isResultHover: false,
    }
  },
  methods: {
    getRef(route) {
      return `nav-${route.name}`
    },
    handleMouseover(route) {
      if (route.name === 'LotteryResult') {
        this.showResultList(true)
      } else {
        this.showResultList(false)
      }
    },
    handleRouteTo(route) {
      const to = { name: route.name }
      switch (route.name) {
        case 'LotteryResult':
          to.query = {
            tab: allLots[0].id,
            id: allLots[0].children[0].id,
          }
          break
        case 'LotteryInfo':
          to.query = {
            tab: 'LotNews',
          }
          break
      }
      return to
    },
    handleRouteClick(route) {
      this.$emit('refreshView', true)
      switch (route.name) {
        case 'LotteryResult':
          this.showResultList(false)
          break
      }
      if (isMobile()) {
        this.handleCloseMenu()
      }
    },
    // handleMouseleave (route) {
    //   if (route.name === 'LotteryResult') {
    //     this.showResultList(false)
    //   }
    // },
    showResultList(bool) {
      this.isResultHover = bool
    },
    handleMenuOpen(e) {
      if (e.target.checked) {
        document.querySelector('#app').style.height = '100vh'
        document.querySelector('#app').style.overflow = 'hidden'
      } else {
        document.querySelector('#app').style.height = 'unset'
        document.querySelector('#app').style.overflow = 'unset'
      }
    },
    handleCloseMenu() {
      const elem = this.$refs.navInput
      elem.click()
    },
  },
}
</script>

<style lang="scss" scoped>
.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(77.4deg, #0053ff 3.38%, #579fff 91.23%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer;
  .nav-container {
    width: 100%;
    box-sizing: border-box;
    input[type='checkbox'] {
      display: none;
      width: 52px;
      height: 52px;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
      -webkit-touch-callout: none;
    }
    span {
      display: none;
      width: 23px;
      height: 2px;
      margin-bottom: 6px;
      position: relative;
      background: #005fde;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }
    span:first-child {
      transform-origin: 0% 0%;
    }
    span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
    input[type='checkbox']:checked ~ span {
      opacity: 1;
      transform: rotate(-43deg) translate(2px, -2px);
      background: #005fde;
    }
    input[type='checkbox']:checked ~ span:nth-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    input[type='checkbox']:checked ~ span:nth-child(2) {
      transform: rotate(43deg) translate(3px, 1px);
    }
    .cover {
      position: absolute;
      width: 100vw;
      height: calc(100vh - 52px);
      top: 52px;
      left: 0;
      background: black;
      opacity: 0;
      z-index: 2;
      transform: translate(-100%, 0);
      transition: opacity 0.8s cubic-bezier(0.77, 0.2, 0.05, 1),
        transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }
    input[type='checkbox']:checked ~ .cover {
      opacity: 0.75;
      transform: translate(0, 0);
    }
    ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      cursor: pointer;
      li.mobile-search-bar {
        display: none;
        border-bottom: none;
        .input {
          width: 100%;
          line-height: 0;
          border-radius: 20px;
          background: #0049b3;
          color: #ffffff;
          .bi-search {
            margin-left: 0;
          }
          input {
            width: 80%;
            padding: 8px;
            background: transparent;
            border: none;
            color: #ffffff;
            &::placeholder {
              color: rgba(255, 255, 255, 0.7);
            }
          }
        }
      }
      li {
        height: 100%;
        font-size: 18px;
        font-weight: 500;
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          text-align: center;
          line-height: 60px;
          color: #ffffff;
          padding: 0 40px;
        }
        a:hover {
          // text-decoration: none;
          background-color: #56ccf2;
        }
      }
      .router-link-active {
        background-color: #56ccf2;
      }
    }
    .route-hover-container {
      width: 100%;
    }
  }
}
@media (max-width: 575.98px) {
  .nav {
    background: #fafafa;
    .nav-container {
      display: block;
      height: 52px;
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
      -webkit-user-select: none;
      user-select: none;
      padding-top: 16px;
      padding-left: 15px;
      input[type='checkbox'],
      span {
        display: block;
      }
      ul {
        position: absolute;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 320px;
        height: calc(100vh - 52px);
        min-height: calc(100vh - 52px);
        overflow-y: scroll;
        margin: 12px 0 0 -45px;
        padding: 30px 30px 50px 60px;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */
        transform-origin: 0% 0%;
        transform: translate(-100%, 0);
        transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        background: #005fde;
        z-index: 99;
        // li.mobile-search-bar {
        //   display: block;
        // }
        li {
          width: 100%;
          height: 65px;
          line-height: 65px;
          border-bottom: 1px solid #2f84ed;
          a {
            transition: color 0.3s ease;
            text-align: left;
            padding: 0;
          }
          a:hover {
            background-color: transparent;
            color: #f2c94c;
          }
        }
        .router-link-active {
          background-color: transparent;
          color: #f2c94c;
        }
      }
      input[type='checkbox']:checked ~ ul {
        transform: none;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .nav {
    background: #fafafa;
    .nav-container {
      display: block;
      height: 52px;
      position: relative;
      top: 0;
      left: 10px;
      z-index: 1;
      -webkit-user-select: none;
      user-select: none;
      padding-top: 16px;
      padding-left: 15px;
      input[type='checkbox'],
      span {
        display: block;
      }
      ul {
        position: absolute;
        width: 320px;
        height: calc(100vh - 52px);
        min-height: calc(100vh - 52px);
        overflow-y: scroll;
        flex-direction: column;
        flex-wrap: nowrap;
        margin: 12px 0 0 -50px;
        padding: 30px 30px 50px 68px;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */
        transform-origin: 0% 0%;
        transform: translate(-100%, 0);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        background: #005fde;
        z-index: 99;
        // li.mobile-search-bar {
        //   display: block;
        // }
        li {
          width: 100%;
          height: 65px;
          line-height: 65px;
          border-bottom: 1px solid #2f84ed;
          a {
            transition: color 0.3s ease;
            text-align: left;
            padding: 0;
          }
          a:hover {
            background-color: transparent;
            color: #f2c94c;
          }
        }
        .router-link-active {
          background-color: transparent;
          color: #f2c94c;
        }
      }
      input[type='checkbox']:checked ~ ul {
        transform: none;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .nav {
    .nav-container {
      ul {
        li {
          width: calc(100vw / 9);
          font-size: 16px;
          a {
            padding: 0;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .nav {
    .nav-container {
      ul {
        li {
          width: calc(100vw / 9);
          font-size: 18px;
          a {
            padding: 0;
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .nav {
    .nav-container {
      height: 60px;
      max-width: 1200px;
      ul {
        li {
          width: calc(100% / 9);
          font-size: 18px;
          white-space: nowrap;
          a {
            padding: 0;
          }
        }
      }
    }
  }
}
</style>
