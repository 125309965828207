import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

export function consoleLogin (data) {
  return request({
    url: '/api/console/login',
    method: 'post',
    data: getFormData(data)
  })
}

export function consoleLogout () {
  return request({
    url: '/api/console/logout',
    method: 'post'
  })
}
