const memberRoutes = [
  {
    path: '/member/home',
    name: 'MemberHome',
    component: () => import('@/views/Member/Home'),
    hidden: true,
    meta: {
      context: '我的首页',
    },
  },
  {
    path: '/member/info',
    name: 'MemberInfo',
    component: () => import('@/views/Member/Info'),
    meta: {
      context: '我的资料',
    },
  },
  // {
  //   path: '/member/collection',
  //   name: 'MemberCollection',
  //   component: () => import('@/views/Member/Collection'),
  //   meta: {
  //     context: '竞猜师收藏'
  //   }
  // },
  // {
  //   path: '/member/mission',
  //   name: 'MemberMission',
  //   component: () => import('@/views/Member/Mission'),
  //   meta: {
  //     context: '任务大厅'
  //   }
  // },
  {
    path: '/member/post',
    name: 'MemberPost',
    component: () => import('@/views/Member/Post'),
    meta: {
      context: '我的发布',
    },
    children: [
      {
        path: '/member/post/postArticle',
        name: 'MemberPostArticle',
        component: () => import('@/views/Member/Post/PostArticle'),
        meta: {
          context: '发布文章',
        },
      },
      {
        path: '/member/post/manage',
        name: 'MemberPostManage',
        component: () => import('@/views/Member/Post/Manage'),
        meta: {
          context: '内容管理',
        },
      },
    ],
  },
  {
    path: '/member/message',
    name: 'MemberMessage',
    component: () => import('@/views/Member/Message'),
    meta: {
      context: '消息中心',
    },
    children: [
      {
        path: '/member/message/myNews',
        name: 'MemberMessageMyNews',
        component: () => import('@/views/Member/Message/MyNews'),
        meta: {
          context: '我的消息',
        },
      },
      {
        path: '/member/message/system',
        name: 'MemberMessageSystem',
        component: () => import('@/views/Member/Message/System'),
        meta: {
          context: '系統通知',
        },
      },
    ],
  },
  {
    path: '/member/store',
    name: 'MemberStore',
    component: () => import('@/views/Member/Store'),
    meta: {
      context: '我的商店',
      notShowChildren: true
    },
    children: [
      {
        path: 'createStore',
        name: 'MemberStoreCreateStore',
        component: () => import('@/views/Member/Store/CreateStore'),
        meta: {
          context: '创建商店',
        },
      },
      {
        path: 'storeIntro',
        name: 'MemberStoreStoreIntro',
        component: () => import('@/views/Member/Store/StoreIntro'),
        meta: {
          context: '商店简介',
        },
      },
      {
        path: 'storePredict',
        name: 'MemberStoreStorePredict',
        component: () => import('@/views/Member/Store/StorePredict'),
        meta: {
          context: '发预测号',
        },
      },
      {
        path: 'storePredictHistory',
        name: 'MemberStoreStorePredictHistory',
        component: () => import('@/views/Member/Store/StorePredictHistory'),
        meta: {
          context: '预测历史',
        },
      },
      {
        path: 'storeModify',
        name: 'MemberStoreStoreModify',
        component: () => import('@/views/Member/Store/CreateStore'),
        meta: {
          context: '修改商店',
        },
      },
    ],
  },
]

export default memberRoutes
