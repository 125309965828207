<template>
  <div class="screenAlert-warning">
    <span class="screenAlert-bg"></span>
		<span class="screenAlert-body pulseWarningIns"></span>
		<span class="screenAlert-dot pulseWarningIns"></span>
	</div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.screenAlert-warning{
  position: relative;
  width: 70px;
  height: 70px;
  box-sizing: content-box;
  border-radius: 50%;
  // border: 4px solid #F8BB86;
  background: linear-gradient(234.8deg, #FF9356 7.81%, #EE4A72 73.1%);
  margin: 0 auto 15px;
  padding: 0;
  -webkit-animation: scaleWarning .75s infinite alternate;
  animation: scaleWarning .75s infinite alternate;
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    // width: 100%;
    // height: 100%;
    background-color: transparent!important;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: pulseWarning 2s linear infinite;
    animation: pulseWarning 2s linear infinite;
  }
  &::after{
    content: '';
    display: block;
    position: absolute;
    width: 62px;
    height: 62px;
    top: 4px;
    left: 4px;
    z-index: 1;
    background: #FFF;
    // background-color: transparent!important;
    // background: linear-gradient(234.8deg, #FF9356 7.81%, #EE4A72 73.1%);
    border-radius: 50%;
  }
  .screenAlert-bg{
    display: block;
    position: absolute;
    width: 70px;
    height: 70px;
    top: 0;
    left: 0;
    background: linear-gradient(234.8deg, #FF9356 7.81%, #EE4A72 73.1%);
    border-radius: 50%;
    -webkit-animation: rotate 5s linear infinite;
    animation: rotate 5s linear infinite;
  }
  .screenAlert-body {
    position: absolute;
    width: 4px;
    height: 36px;
    top: 11px;
    left: 50%;
    // background-color: #F8BB86;
    background-color: #EE4A72;
    // background: linear-gradient(234.8deg, #FF9356 7.81%, #EE4A72 73.1%);
    border-radius: 2px;
    margin-left: -2px;
    z-index: 2;
  }
  .screenAlert-dot{
    position: absolute;
    width: 6px;
    height: 6px;
    bottom: 11px;
    left: 50%;
    // background-color: #F8BB86;
    background-color: #EE4A72;
    border-radius: 50%;
    margin-left: -3px;
    z-index: 2;
  }
  .pulseWarningIns{
    -webkit-animation: pulseWarningIns 2s linear infinite;
    animation: pulseWarningIns 2s linear infinite;
  }
}
@-webkit-keyframes scaleWarning{
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scaleWarning{
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes pulseWarning{
  0% {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity:.5;
  }
  30% {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: .5;
    }
  100% {
    background-color: #F8BB86;
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes pulseWarning{
  0% {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: .5;
  }
  30% {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: .5;
  }
  100% {
    // background-color:#F8BB86;
    background-color: linear-gradient(234.8deg, #FF9356 7.81%, #EE4A72 73.1%);
    -webkit-transform: scale(2);
    transform:scale(2);
    opacity: 0
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes pulseWarningIns{
  0% {
    background-color:#FF9356;
    // background: linear-gradient(234.8deg, #ffaa79 7.81%, #f86d90 73.1%);
  }
  50% {
    background-color:#EE4A72;
    // background: linear-gradient(234.8deg, #FF9356 7.81%, #EE4A72 73.1%);
  }
  100% {
    background-color:#FF9356;
    // background: linear-gradient(234.8deg, #FF9356 7.81%, #EE4A72 73.1%);
  }
}
@keyframes pulseWarningIns{
  0% {
    background-color: #FF9356;
    // background: linear-gradient(234.8deg, #ffaa79 7.81%, #f86d90 73.1%);
  }
  50% {
    background-color:#EE4A72;
    // background: linear-gradient(234.8deg, #FF9356 7.81%, #EE4A72 73.1%);
  }
  100% {
    background-color: #FF9356;
    // background: linear-gradient(234.8deg, #FF9356 7.81%, #EE4A72 73.1%);
  }
}
</style>
