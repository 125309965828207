<template>
  <div class="head-container">
    <div class="head-item">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          v-for="route in routerMatched"
          :key="route.name"
          :to="{ name: route.name }">
          {{ route.meta.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="head-item">
      <p @click="handleLogout" style="cursor:pointer;">登出</p>
    </div>
  </div>
</template>

<script>
import { consoleLogout } from '@/api/admin/console'
import { goPage } from '@/utils/route'
import { rmCookie } from '@/utils/cookie'
import { loginCookie } from '@/constants/login'

export default {
  data () {
    return {
      routerMatched: []
    }
  },
  methods: {
    handleLogout () {
      consoleLogout()
        .then(res => {
          this.$message({
            message: res.message,
            type: 'success'
          })
          rmCookie(loginCookie.admin)
          goPage({ name: 'AdminLogin' })
        })
    },
    initRouterMatched () {
      this.routerMatched = [...this.$router.history.current.matched]
      this.routerMatched.shift()
    }
  },
  created () {
    this.initRouterMatched()
  },
  watch: {
    '$route' () {
      this.initRouterMatched()
    }
  }
}
</script>

<style lang="scss" scoped>
.head-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}
</style>
