export function fillDigit (num, digit, fill) {
  let str = `${num}`
  while (str.length < digit) {
    str = `${fill}${str}`
  }
  return str
}

export function ignoreHTMLTag (str) {
  return str.replace(/(<([^>]+)>)/gi, '')
}

export function byteLength (str) {
  let b = 0
  if (str && str !== '') {
    for(var i = 0; i < str.length; i ++) {
      if(str.charCodeAt(i) > 255) {
        b += 2
      }else {
        b ++
      }
    }
  }
  return b
}

export function byteSlice (str, len) {
  let s = '', count = len
  for(var i = 0; i < str.length; i ++) {
    if ( count <= 0 && i < str.length - 1 ) {
      s += '...'
      break
    }
    if(str.charCodeAt(i) > 255) {
      count = count - 2
    } else {
      count  = count - 1
    }
    s += str[i]
    
  }
  return s
}
