// reference: https://github.com/js-cookie/js-cookie
import Cookies from 'js-cookie'

export function getCookie (key) {
  return Cookies.get(key)
}

export function setCookie (key, val, expires) {
  if (expires) {
    return Cookies.set(key, val, { expires: expires })
  }
  return Cookies.set(key, val)
}

export function rmCookie (key) {
  return Cookies.remove(key)
}
