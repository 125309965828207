import { allAnalysis } from './analysis'
import { allTrend } from './trend'

const chartAnalysis = ['numAnalysis', 'historyNum'] // , 'lzAnalysis', 'hcAnalysis', 'dslsHistory'
const chartTrend = ['basicTrend', 'dyTrend', 'xtTrend'] // , 'dtTrend', 'xtTrend'
const headArr = ['百位', '十位', '个位']
const xtHeadArr = ['前三形态', '中三形态', '后三形态']

export const chartLotOversea = {
  analysis: allAnalysis.filter(item => chartAnalysis.includes(item.id)),
  trend: allTrend.filter(item => chartTrend.includes(item.id)),
  headArr: headArr,
  xtHeadArr: xtHeadArr,
  resultMinNum: 0,
  resultMaxNum: 9,
  resultFillZero: false
}

const chartJND3DTrend = [...chartTrend, 'hzTrend']
let xtIdx = chartJND3DTrend.indexOf('xtTrend')
chartJND3DTrend.splice(xtIdx, 1)
export const chartJND3D = {
  ...chartLotOversea,
  trend: allTrend.filter(item => chartJND3DTrend.includes(item.id)),
  isResultRepeat: true
}

const chartRDAnalysis = ['historyNum'] // , 'lzAnalysis'
const chartRDTrend = [] // 'lsTrend', 'hzTrend', 'dsTrend', 'zhTrend'
export const chartLotRD = {
  ...chartLotOversea,
  analysis: allAnalysis.filter(item => chartRDAnalysis.includes(item.id)),
  trend: allTrend.filter(item => chartRDTrend.includes(item.id))
}

const chartMNGAnalysis = ['historyNum'] // , 'lzAnalysis'
const chartMNGTrend = [] // 'lsTrend', 'hzTrend', 'dsTrend', 'zhTrend'
export const chartLotMNG = {
  ...chartLotOversea,
  analysis: allAnalysis.filter(item => chartMNGAnalysis.includes(item.id)),
  trend: allTrend.filter(item => chartMNGTrend.includes(item.id))
}
