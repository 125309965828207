export const realImage = {
  init: async function(el, binding){
    let baseURL = process.env.VUE_APP_DEMO_API_ROOT || ''
    let imgURL = baseURL + binding.value
    if (imgURL) {
      let exist = await realImage.imageIsExist(imgURL)
      if (exist) {
        if (el.tagName == 'IMG') {
          el.setAttribute('src', imgURL)
        } else {
          el.setAttribute('style', `background-image: url('${imgURL}')`)
        }
      } 
    }
  },
  imageIsExist: function(url) {
    return new Promise((resolve) => {
      var img = new Image()
      img.onload = function () {
        if (this.complete == true){
          resolve(true)
          img = null
        }
      }
      img.onerror = function () {
        resolve(false)
        img = null
      }
      img.src = url
    })
  }
}