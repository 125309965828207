<template>
  <div>
    <!-- <button
      v-if="false"
      @click="handleSeeRoutes">see routes</button> -->
    <ul class="footerRoutes">
      <li
        v-for="route in footerRoutes"
        :key="route.name">
        <router-link :to="{ name: route.name }">{{ route.meta.context }}</router-link>
        <div class="footerSubRoutes">
          <ul>
            <li
              v-for="subRoute in subRoutes[route.name]"
              :key="subRoute.id">
              <router-link :to="handleSubRouteTo(route.name, subRoute)">{{ subRoute.context }}</router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getPageRoutes, getMobilePageRoutes } from '@/utils/route'
import { isMobile } from '@/utils/device'

export default {
  name: 'footerRoutes',
  computed: {
    routes: {
      get () {
        return isMobile() ? getMobilePageRoutes() : getPageRoutes()
      }
    },
    footerRoutes: {
      get () {
        return this.routes.filter(route => route.name !== 'Index')
      }
    }
  },
  data () {
    return {
      subRoutes: {
        LotteryResult: [
          // {
          //   id: 'lotteryHistory',
          //   context: '历史开奖'
          // }
        ],
        LotteryChart: [
          // {
          //   id: 'lotteryHighFrequency',
          //   context: '高频彩种'
          // },
          // {
          //   id: 'lotteryLowFrequency',
          //   context: '低频彩种'
          // },
          // {
          //   id: 'lotteryFast',
          //   context: '极速彩种'
          // },
          // {
          //   id: 'lotteryOversea',
          //   context: '境外彩种'
          // },
          // {
          //   id: 'lotteryComputed',
          //   context: '计算型彩种'
          // }
        ],
        LotteryInfo: [
          {
            id: 'LotNews',
            context: '彩票新闻'
          },
          // {
          //   id: 'lotteryRule',
          //   context: '彩票玩法'
          // },
          {
            id: 'LotTrick',
            context: '彩票技巧'
          },
          {
            id: 'LotShare',
            context: '彩友分享'
          }
        ],
        Welfare: [
          // {
          //   id: 'lotteryEvent',
          //   context: '活动专区'
          // }
        ],
        Contact: []
      }
    }
  },
  methods: {
    handleSubRouteTo (routeName, subRoute) {
      const to = {
        name: routeName
      }
      switch (routeName) {
        case 'LotteryInfo':
          to.query = {
            tab: subRoute.id
          }
          break
      }
      return to
    }
  }
}
</script>

<style lang="scss" scoped>
.footerRoutes {
  display: flex;
  justify-content: space-between;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  li {
    a {
      color: #FAFAFA;
    }
  }
}
@media (max-width: 575.98px) {
  .footerRoutes {
    display: inline-flex;
    // gap: 35px;
    & >li {
      position: relative;
      margin-right: 35px;
      &:last-child{margin-right: 0;}
      a {
        font-weight: 600;
        font-size: 12px;
      }
      .footerSubRoutes {display: none;}
      &::after{
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 10px;
        background: #FFF;
        top: 4px;
        right: -17px;
      }
      &:last-child::after{display: none;}
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .footerRoutes {
    display: inline-flex;
    // gap: 35px;
    & >li {
      position: relative;
      margin-right: 35px;
      &:last-child{margin-right: 0;}
      a {
        font-weight: 600;
        font-size: 12px;
      }
      .footerSubRoutes {display: none;}
      &::after{
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 10px;
        background: #FFF;
        top: 4px;
        right: -17px;
      }
      &:last-child::after{display: none;}
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .footerRoutes {
    display: inline-flex;
    // gap: 25px;
    & >li {
      margin-right: 25px;
      &:last-child{margin-right: 0;}
      a {
        font-weight: 600;
        font-size: 13px;
      }
      .footerSubRoutes {
        margin-top: 8px;
        ul {
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          // gap: 8px;
          li {
            margin-bottom: 8px;
            &:last-child{margin-bottom: 0;}
            a {
              font-weight: normal;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .footerRoutes {
    display: inline-flex;
    // gap: 50px;
    & >li {
      margin-right: 50px;
      &:last-child{margin-right: 0;}
      a {
        font-weight: 600;
        font-size: 14px;
      }
      .footerSubRoutes {
        margin-top: 8px;
        ul {
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          // gap: 8px;
          li {
            margin-bottom: 8px;
            &:last-child{margin-bottom: 0;}
            a {
              font-weight: normal;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .footerRoutes {
    display: inline-flex;
    // gap: 50px;
    & >li {
      margin-right: 50px;
      &:last-child{margin-right: 0;}
      a {
        font-weight: 600;
        font-size: 14px;
      }
      .footerSubRoutes {
        margin-top: 8px;
        ul {
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          // gap: 8px;
          li {
            margin-bottom: 8px;
            &:last-child{margin-bottom: 0;}
            a {
              font-weight: normal;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
