import { allAnalysis } from './analysis'
import { allTrend } from './trend'

const chartAnalysis = ['numAnalysis', 'historyNum'] // , 'lzAnalysis', 'hcAnalysis', 'dslsHistory', 'dslslz', 'dtlz'
const chartTrend = ['basicTrend', 'dyTrend', 'dtTrend', 'championTrend'] // , 'numTrend'
const headArr = ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名']
const DtCompare = ['冠军', '第五名']

export const chartLotPK10 = {
  analysis: allAnalysis.filter(item => chartAnalysis.includes(item.id)),
  trend: allTrend.filter(item => chartTrend.includes(item.id)),
  headArr: headArr,
  resultMinNum: 1,
  resultMaxNum: 10,
  resultFillZero: true,
  DtCompare: DtCompare
}
