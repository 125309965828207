<template>
  <div class="message-container" :class="!isMobile ? 'card-container': ''">
    <div class="title-detail-container">
      <div 
        class="title-back"
        @click="handleBack">
        <b-icon icon="arrow-left" aria-hidden="true"></b-icon>
        </div>
      <h2>{{ messageDetail.title }}</h2>
    </div>
    <div class="content-detail-container">
      <div 
        class="content-item" 
        style="white-space: pre-wrap;">
        {{ messageDetail.content }}
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/device"

export default {
  props: {
    messageDetail: {
      required: true
    },
    login: {
      type: Object,
      required: true
    }
  },
  computed: {
    isMobile () {
      return isMobile()
    }
  },
  methods: {
    handleBack() {
      this.$emit('handleBack', false)
    }
  },
}
</script>

<style lang="scss" scoped>
.message-container {
  background-color: #FFF;
  .title-detail-container {
    padding: 18px 30px;
    text-align: center;
    border-bottom: 1px solid #E0E0E0;
    h2 {
      font-weight: 500;
      font-size: 21px;
      line-height: 29px;
      word-break: break-all;
      color: #4F4F4F;
      margin-left: 10px;
    }
    .title-back {
      cursor: pointer;
      float: left;
      font-size: 20px;
      color: #BDBDBD;
      margin: 0 20px 0 -10px;
      vertical-align: middle;
    }
  }
  .content-detail-container {
    overflow-y: auto;
    min-height: 80vh;
    padding: 30px;
    font-size: 15px;
    line-height: 21px;
    color: #333333;
    .content-item {
      text-align: left;
      word-break: break-word;
    }
  }
}
@media (max-width: 575.98px) {
  .message-container {
    background-color: #FFF;
    .title-detail-container {
      padding: 18px 30px;
      text-align: center;
      border-bottom: 1px solid #E0E0E0;
      h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        word-break: break-all;
        margin-left: 40px;
      }
      .title-back {
        cursor: pointer;
        float: left;
        font-size: 20px;
        color: #999;
      }
    }
    .content-detail-container {
      overflow-y: auto;
      height: 80vh;
      padding: 30px;
      font-size: 15px;
      .content-item {
        text-align: left;
        word-break: break-all;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
}
</style>
