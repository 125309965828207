import { getCookie, setCookie } from './cookie'
import { setLocalDefault, getLocal, removeLocal } from './storage'

export function isMemberLogin () {
  return getCookie('memberLogin') === 'true' && getLocal('isLogin') === 'true'
}

export function memberLogin () {
  setCookie('memberLogin', true)
  setLocalDefault('isLogin', true)
}

export function memberLogout () {
  setCookie('memberLogin', false)
  setLocalDefault('isLogin', false)
}

export function isMemberApi (url) {
  const adminApiRegex = /\/api\/users\/me/
  return adminApiRegex.test(url)
}
