<template>
  <div class="sign-in-container">
    <div class="form-container">
      <!-- 用户名称 -->
      <mc-input
        v-model="query.username"
        placeholder="用户名/邮箱" />
      <!-- 登录密码 -->
      <mc-input
        v-model="query.password"
        placeholder="密码"
        type="password" />
    </div>
    <div class="agree-container">
      <div class="check-container">
        <input
          v-model="query.auto_login"
          type="checkbox"
          true-value="on"
          false-value="off" />
        <label>7日免登录</label>
      </div>
      <div class="forget-container">
        <a @click="handleForget">忘记密码？</a>
      </div>
    </div>
    <div class="submit-container">
      <button
        class="btn-login"
        @click="handleSignIn">登录</button>
      <button
        class="btn-register"
        @click="handleRegister">免费注册加入会员</button>
    </div>
  </div>
</template>

<script>
import McInput from '@/components/McInput'
import { postLogin } from '@/api/client/member'
import resStatus from '@/constants/resStatus'
// import { setCookie } from '@/utils/cookie'
import { goPageBlank, goPage } from '@/utils/route'
import { memberLogin } from '@/utils/member'
import { isMobile } from '@/utils/device'

export default {
  components: {
    McInput
  },
  props: {
    login: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      query: {
        username: '',
        password: '',
        auto_login: 'on'
      }
    }
  },
  methods: {
    handleForget () {
      this.updateLogin({
        formType: 'Forget'
      })
    },
    updateLogin (obj) {
      this.$emit('updateLogin', obj)
    },
    handleSignIn () {
      this.$emit('handleLoading', true)
      postLogin(this.query)
        .then(res => {
          this.$emit('handleLoading', false)
          switch (res.status) {
            case resStatus.OK:
              this.handleSignInSuccess(res)
              this.handleRouteMember()
              break
            default:
              this.showSignInFail(res.message)
          }
        })
        .catch(err => {
          this.$emit('handleLoading', false)
          this.showSignInFail(err)
        })
    },
    handleRouteMember () {
      isMobile() ? goPage({ name: 'MemberMobile' }) : goPageBlank({ name: 'Member' })
    },
    handleSignInSuccess (res) {
      this.updateLogin({
        data: res.data,
        isLogin: true
      })
      // setCookie('account', res.data)
      memberLogin()
      this.showSignInSuccess()
    },
    handleRegister () {
      this.updateLogin({
        formType: 'SignUp'
      })
    },
    showSignInSuccess () {
      this.updateLogin({
        formType: 'SignInSuccess'
      })
    },
    showSignInFail (msg) {
      this.updateLogin({
        formType: 'Error',
        formErrMsg: msg
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-in-container {
  margin-top: clamp(10px, 70px, 15vh);
  display: flex;
  flex-direction: column;
  .form-container {
    display: flex;
    flex-direction: column;
  }
  .agree-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .check-container {
      text-align: left;
      input {
        cursor: pointer;
        vertical-align: bottom;
      }
    }
    .forget-container {
      a {
        cursor: pointer;
        color: #0075FF;
      }
    }
  }
  .submit-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
      border: none;
    }
    .btn-login {
      background-color: #0056D2;
      color: #FFFFFF;
    }
    .btn-register {
      background-color: #E6F1FF;
      color: #0075FF;
      border: 1px solid #0075FF;
    }
  }
}
@media (max-width: 375.98px) {
  .sign-in-container {
    // gap: 15px;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 7px;}
      & > div:last-child {margin-bottom: 0;}
    }
    .agree-container {
      .check-container {
        input {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        label {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .forget-container {
        a {
          font-size: 14px;
        }
      }
    }
    .submit-container {
      margin-top: 20px;
      // gap: 20px;
      button {
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        border-radius: 6px;
        margin-bottom: 25px;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
@media (min-width: 376px) and (max-width: 575.98px) {
  .sign-in-container {
    // gap: 15px;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 7px;}
      & > div:last-child {margin-bottom: 0;}
    }
    .agree-container {
      .check-container {
        input {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        label {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .forget-container {
        a {
          font-size: 14px;
        }
      }
    }
    .submit-container {
      margin-top: 20px;
      // gap: 20px;
      button {
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        border-radius: 6px;
        margin-bottom: 25px;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .sign-in-container {
    // gap: 15px;
    margin-top: 70px;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 7px;}
      & > div:last-child {margin-bottom: 0;}
    }
    .agree-container {
      .check-container {
        input {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        label {
          font-size: 14px;
        }
      }
      .forget-container {
        a {
          font-size: 14px;
        }
      }
    }
    .submit-container {
      margin-top: 20px;
      // gap: 20px;
      button {
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        border-radius: 6px;
        margin-bottom: 25px;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .sign-in-container {
    // gap: 15px;
    margin-top: 0;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 8px;}
      & > div:last-child {margin-bottom: 0;}
    }
    .agree-container {
      .check-container {
        input {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          vertical-align: sub;
        }
        label {
          font-size: 14px;
        }
      }
      .forget-container {
        a {
          font-size: 14px;
        }
      }
    }
    .submit-container {
      margin-top: 15px;
      // gap: 20px;
      button {
        font-size: 18px;
        height: 50px;
        border-radius: 6px;
        margin-bottom: 20px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .sign-in-container {
    // gap: 15px;
    margin-top: 0;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 8px;}
      & > div:last-child {margin-bottom: 0;}
    }
    .agree-container {
      .check-container {
        input {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        label {
          font-size: 14px;
        }
      }
      .forget-container {
        a {
          font-size: 14px;
        }
      }
    }
    .submit-container {
      margin-top: 15px;
      // gap: 20px;
      button {
        font-size: 18px;
        height: 50px;
        border-radius: 6px;
        margin-bottom: 20px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .sign-in-container {
    // gap: 15px;
    margin-top: 0;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 8px;}
      & > div:last-child {margin-bottom: 0;}
    }
    .agree-container {
      .check-container {
        input {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        label {
          font-size: 14px;
        }
      }
      .forget-container {
        a {
          font-size: 14px;
        }
      }
    }
    .submit-container {
      margin-top: 15px;
      // gap: 20px;
      button {
        font-size: 18px;
        height: 50px;
        border-radius: 6px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
