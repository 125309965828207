import { chartLotMM } from './chart/lotMM'
import imgCQFFC from '@/assets/lot-logo/CQFFC.png'
import imgTXFFC from '@/assets/lot-logo/TXFFC.png'
import imgQQFFC from '@/assets/lot-logo/QQFFC.png'
import imgTX2FCS from '@/assets/lot-logo/TX2FCS.png'
import imgTX2FCD from '@/assets/lot-logo/TX2FCD.png'
import imgCQ5FC from '@/assets/lot-logo/CQ5FC.png'
import imgTX5FC from '@/assets/lot-logo/TX5FC.png'
import imgQQ30S from '@/assets/lot-logo/QQ30S.png'
import imgCQSSC30S from '@/assets/lot-logo/CQSSC30S.png'
import imgTXFFC30S from '@/assets/lot-logo/TXFFC30S.png'
import imgQIQFFC from '@/assets/lot-logo/QIQFFC.jpg'

const lotMMBasic = [
  {
    id: 'CQFFC',
    name: '重庆分分彩',
    logo: imgCQFFC
  },
  {
    id: 'TXFFC',
    name: '腾讯分分彩',
    logo: imgTXFFC
  },
  {
    id: 'QQFFC',
    name: 'QQ分分彩',
    logo: imgQQFFC
  },
  {
    id: 'QIQFFC',
    name: '奇趣分分彩',
    logo: imgQIQFFC
  },
  {
    id: 'TX2FCS',
    name: '腾讯2分彩-双',
    logo: imgTX2FCS
  },
  {
    id: 'TX2FCD',
    name: '腾讯2分彩-单',
    logo: imgTX2FCD
  },
  {
    id: 'CQ5FC',
    name: '重庆5分',
    logo: imgCQ5FC
  },
  {
    id: 'TX5FC',
    name: '腾讯5分',
    logo: imgTX5FC
  },
  {
    id: 'QQ30S',
    name: 'QQ30秒',
    logo: imgQQ30S
  },
  {
    id: 'CQSSC30S',
    name: '多彩重庆30秒',
    logo: imgCQSSC30S
  },
  {
    id: 'TXFFC30S',
    name: '多彩腾讯30秒',
    logo: imgTXFFC30S
  }
]

const lotMMRuleTemplate = {
  display: {
    total: true,
    dg: true,
    f3: true,
    m3: true,
    l3: true
  },
  link: {
    history: true,
    trend: true
    // LSHistory: true
  },
  chart: chartLotMM
}

export const lotMM = lotMMBasic
  .map(lot => {
    switch (lot.id) {
      case 'TXFFC':
        return ({
          ...lot,
          rule: {
            ...lotMMRuleTemplate,
            link: {
              ...lotMMRuleTemplate.link,
              prediction: true
            }
          }
        })
      case 'QIQFFC':
        return ({
          ...lot,
          rule: {
            ...lotMMRuleTemplate,
            link: {
              history: true
            }
          }
        })
      default:
        return ({
          ...lot,
          rule: lotMMRuleTemplate
        })
    }
  })

// export const lotMM = [
//   {
//     id: 'CQFFC',
//     name: '重庆分分彩',
//     logo: imgCQFFC,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true
//         // LSHistory: true
//       },
//       chart: chartLotMM
//     }
//   },
//   {
//     id: 'TXFFC',
//     name: '腾讯分分彩',
//     logo: imgTXFFC,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         mf3: true,
//         mm3: true,
//         ml3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLotMM
//     }
//   },
//   {
//     id: 'QQFFC',
//     name: 'QQ分分彩',
//     logo: imgQQFFC,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true
//         // LSHistory: true
//       },
//       chart: chartLotMM
//     }
//   },
//   {
//     id: 'QIQFFC',
//     name: '奇趣分分彩',
//     logo: imgQIQFFC,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true
//       },
//       chart: chartLotMM
//     }
//   },
//   {
//     id: 'TX2FCS',
//     name: '腾讯2分彩-双',
//     logo: imgTX2FCS,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true
//         // LSHistory: true
//       },
//       chart: chartLotMM
//     }
//   },
//   {
//     id: 'TX2FCD',
//     name: '腾讯2分彩-单',
//     logo: imgTX2FCD,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true
//         // LSHistory: true
//       },
//       chart: chartLotMM
//     }
//   },
//   {
//     id: 'CQ5FC',
//     name: '重庆5分',
//     logo: imgCQ5FC,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true
//         // LSHistory: true
//       },
//       chart: chartLotMM
//     }
//   },
//   {
//     id: 'TX5FC',
//     name: '腾讯5分',
//     logo: imgTX5FC,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true
//         // LSHistory: true
//       },
//       chart: chartLotMM
//     }
//   },
//   {
//     id: 'QQ30S',
//     name: 'QQ30秒',
//     logo: imgQQ30S,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true
//         // LSHistory: true
//       },
//       chart: chartLotMM
//     }
//   },
//   {
//     id: 'CQSSC30S',
//     name: '多彩重庆30秒',
//     logo: imgCQSSC30S,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true
//         // LSHistory: true
//       },
//       chart: chartLotMM
//     }
//   },
//   {
//     id: 'TXFFC30S',
//     name: '多彩腾讯30秒',
//     logo: imgTXFFC30S,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true
//         // LSHistory: true
//       },
//       chart: chartLotMM
//     }
//   }
// ]
