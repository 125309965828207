import { getLotTypes } from '@/api/client/lotInfo'
import {
  getMyShop,
  createMyShop,
  modifyMyShop,
  getLotteries,
  getLotteryIssues,
  predictLot,
  getLotteryTypeWithPredict,
  getPredictHistory,
  syncPredictOnline,
  getExpertProfile,
} from '@/api/client/shop'
import { autoAPI } from '@/api/promiseApiHandler'
import resStatus from '@/constants/resStatus'
import { showApiErrorMessage } from '@/utils/messageHandler'
export default {
  namespaced: true,
  state: {
    lottery_types: [],
    lottery_types_sort: [],
  },
  mutations: {
    setLotteryTypes(state, val) {
      state.lottery_types = val
    },
  },
  actions: {
    getLotteryTypes: ({ state }, val) => {
      return new Promise((resolve, reject) => {
        if (state.lottery_types.length === 0) {
          getLotTypes()
            .then((res) => {
              if (res.status === resStatus.OK) {
                state.lottery_types = res.data
                state.lottery_types_sort = res.data.map((it) => it.type)
                resolve(res)
              } else {
                reject(res)
              }
            })
            .catch(reject)
        } else {
          resolve({
            data: state.lottery_types,
          })
        }
      })
    },
    getMyShop: (state, val) => {
      return autoAPI(getMyShop, val, false)
    },
    createMyShop: (state, val) => {
      return autoAPI(createMyShop, val)
    },
    modifyMyShop: (state, val) => {
      return autoAPI(modifyMyShop, val)
    },
    getLotteries: (state, val) => {
      return autoAPI(getLotteries)
    },
    getLotteryIssues: (state, val) => {
      return autoAPI(getLotteryIssues, val, false)
    },
    predictLot: (state, val) => {
      return autoAPI(predictLot, val)
    },
    getLotteryTypeWithPredict: (state, val) => {
      return autoAPI(getLotteryTypeWithPredict, val)
    },
    getPredictHistory: (state, val) => {
      return autoAPI(getPredictHistory, val)
    },
    getLotteriesAndLotteryTypeWithPredict: (state, val) => {
      return new Promise((resolve, reject) => {
        Promise.all([getLotteries(), getLotteryTypeWithPredict()]).then((res) => {
          const isAllOK = res.every(
            (it) => it.status === resStatus.OK || it.status === resStatus.OK2
          )
          if (isAllOK) {
            resolve(res)
          } else {
            showApiErrorMessage({
              message: '伺服器错误请稍后再试',
            })
          }
        })
      })
    },
    syncPredictOnline: (state, val) => {
      return autoAPI(syncPredictOnline, val)
    },
    getExpertProfile: (state, val) => {
      return autoAPI(getExpertProfile, val)
    },
  },
}
