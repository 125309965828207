import { chartLotQ3 } from './chart/lotQ3'
import imgJSKS from '@/assets/lot-logo/JSKS.png'
import imgHLK3 from '@/assets/lot-logo/HLK3.png'
import imgHBK3 from '@/assets/lot-logo/HBK3.png'
import imgJLK3 from '@/assets/lot-logo/JLK3.png'
import imgAHK3 from '@/assets/lot-logo/AHK3.png'
import imgGXKS from '@/assets/lot-logo/GXKS.png'

const lotQ3Basic = [
  {
    id: 'JSKS',
    name: '江苏快三',
    logo: imgJSKS
  },
  {
    id: 'HLK3',
    name: '河南快三',
    logo: imgHLK3
  },
  {
    id: 'HBK3',
    name: '湖北快三',
    logo: imgHBK3
  },
  {
    id: 'JLK3',
    name: '吉林快三',
    logo: imgJLK3
  },
  {
    id: 'AHK3',
    name: '安徽快三',
    logo: imgAHK3
  },
  {
    id: 'GXKS',
    name: '广西快三',
    logo: imgGXKS
  }
]

const lotQ3RuleTemplate = {
  display: {
    total: true,
    fsc: true
  },
  link: {
    history: true,
    trend: true,
    prediction: true
    // LSHistory: true
  },
  chart: chartLotQ3
}

export const lotQ3 = lotQ3Basic
  .map(lot => {
    switch (lot.id) {
      default:
        return ({
          ...lot,
          rule: lotQ3RuleTemplate
        })
    }
  })

// export const lotQ3 = [
//   {
//     id: 'JSKS',
//     name: '江苏快三',
//     logo: imgJSKS,
//     rule: {
//       display: {
//         total: true,
//         fsc: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLotQ3
//     }
//   },
//   {
//     id: 'HLK3',
//     name: '河南快三',
//     logo: imgHLK3,
//     rule: {
//       display: {
//         total: true,
//         fsc: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLotQ3
//     }
//   },
//   {
//     id: 'HBK3',
//     name: '湖北快三',
//     logo: imgHBK3,
//     rule: {
//       display: {
//         total: true,
//         fsc: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLotQ3
//     }
//   },
//   {
//     id: 'JLK3',
//     name: '吉林快三',
//     logo: imgJLK3,
//     rule: {
//       display: {
//         total: true,
//         fsc: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLotQ3
//     }
//   },
//   {
//     id: 'AHK3',
//     name: '安徽快三',
//     logo: imgAHK3,
//     rule: {
//       display: {
//         total: true,
//         fsc: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLotQ3
//     }
//   },
//   {
//     id: 'GXKS',
//     name: '广西快三',
//     logo: imgGXKS,
//     rule: {
//       display: {
//         total: true,
//         fsc: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLotQ3
//     }
//   }
// ]
