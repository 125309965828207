import { chartHappy81012 } from './chart/happy81012'
// import imgBJKL8 from '@/assets/lot-logo/BJKL8.png'
import imgGXKL10 from '@/assets/lot-logo/GXKL10.png'
import imgGDKL10 from '@/assets/lot-logo/GDKL10.png'
import imgTJKL10 from '@/assets/lot-logo/TJKL10.png'
import imgHNKL10 from '@/assets/lot-logo/HNKL10.png'
import imgCQXYNC from '@/assets/lot-logo/CQXYNC.png'
import imgSCKL12 from '@/assets/lot-logo/SCKL12.png'
import { remove } from 'lodash'

const duplicateChart = JSON.parse(JSON.stringify(chartHappy81012))
// remove analysis: numAnalysis
remove(duplicateChart.analysis, (item) => {
  return item.id === 'numAnalysis'
})

const happy81012Basic = [
  // {
  //   id: 'BJKL8',
  //   name: '北京快乐8',
  //   logo: imgBJKL8
  // },
  {
    id: 'GXKL10',
    name: '广西快乐10',
    logo: imgGXKL10
  },
  {
    id: 'GDKL10',
    name: '广东快乐10',
    logo: imgGDKL10
  },
  {
    id: 'TJKL10',
    name: '天津快乐10',
    logo: imgTJKL10
  },
  {
    id: 'HNKL10',
    name: '湖南快乐10',
    logo: imgHNKL10
  },
  {
    id: 'CQXYNC',
    name: '重庆幸运农场',
    logo: imgCQXYNC
  },
  {
    id: 'SCKL12',
    name: '四川快乐12',
    logo: imgSCKL12
  }
]

const happy81012RuleTemplate = {
  display: {
    total: true,
    lbs: true,
    dg4: true
  },
  link: {
    history: true,
    trend: true,
    prediction: true
    // LSHistory: true
  },
  chart: chartHappy81012
}

export const happy81012 = happy81012Basic
  .map(lot => {
    switch (lot.id) {
      case 'GXKL10':
      case 'SCKL12':
        return ({
          ...lot,
          rule: {
            ...happy81012RuleTemplate,
            display: {
              total: true,
              lbs: true,
              dg: true
            },
            chart: {
              ...duplicateChart,
              headArr: ['第一位', '第二位', '第三位', '第四位', '第五位'],
              resultMinNum: 1,
              resultMaxNum: 21
            }
          }
        })
      default:
        return ({
          ...lot,
          rule: happy81012RuleTemplate
        })
    }
  })

// export const happy81012 = [
//   // {
//   //   id: 'BJKL8',
//   //   name: '北京快乐8',
//   //   logo: imgBJKL8,
//   //   rule: {
//   //     display: {
//   //       total: true
//   //     },
//   //     link: {
//   //       history: true,
//   //       trend: true,
//   //       prediction: true
//   //       // LSHistory: true
//   //     }
//   //   }
//   // },
//   {
//     id: 'GXKL10',
//     name: '广西快乐10',
//     logo: imgGXKL10,
//     rule: {
//       display: {
//         total: true,
//         lbs: true,
//         dg: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: {
//         ...duplicateChart,
//         headArr: ['第一位', '第二位', '第三位', '第四位', '第五位'],
//         resultMinNum: 1,
//         resultMaxNum: 21
//       }
//     }
//   },
//   {
//     id: 'GDKL10',
//     name: '广东快乐10',
//     logo: imgGDKL10,
//     rule: {
//       display: {
//         total: true,
//         lbs: true,
//         dg4: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartHappy81012
//     }
//   },
//   {
//     id: 'TJKL10',
//     name: '天津快乐10',
//     logo: imgTJKL10,
//     rule: {
//       display: {
//         total: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartHappy81012
//     }
//   },
//   {
//     id: 'HNKL10',
//     name: '湖南快乐10',
//     logo: imgHNKL10,
//     rule: {
//       display: {
//         total: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartHappy81012
//     }
//   },
//   {
//     id: 'CQXYNC',
//     name: '重庆幸运农场',
//     logo: imgCQXYNC,
//     rule: {
//       display: {
//         total: true,
//         lbs: true,
//         dg4: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartHappy81012
//     }
//   }
//   // {
//   //   id: 'SCKL12',
//   //   name: '四川快乐12',
//   //   logo: imgSCKL12,
//   //   rule: {
//   //     display: {
//   //       total: true,
//   //       dg: true
//   //     },
//   //     link: {
//   //       history: true,
//   //       trend: true,
//   //       prediction: true
//   //       // LSHistory: true
//   //     },
//   //     chart: duplicateChart
//   //   }
//   // }
// ]
