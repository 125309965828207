
export default {
  namespaced: true,
  state: {
    // '1' 彩民
    // '2' 競猜師
    category: null,
  },
  mutations: {
    setCategory(state, val) {
      state.category = val
    }
  },
  actions: {

  }
}
