<template>
  <header v-if="isMobile" class='app-header'>
    <Header
      :login="login"
      @updateLogin="updateLogin" />
  </header>
  <div v-else class="member-header">
    <div class="header-container">
      <div class="header-left-container">
        <!-- <span>前往多彩网商户端</span> -->
        <div class="header-logo-container">
          <img
            class="header-logo"
            src="@/assets/member/member-header-logo.png" />
        </div>
      </div>
      <div
        v-if="login.isLogin"
        class="header-right-container is-sign-in">
        <account
          :login="login"
          @updateLogin="updateLogin" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/views/Layout/Header'
import Account from '@/views/Layout/Header/Head/Account'
import { isMobile } from "@/utils/device"

export default {
  components: {
    Header,
    Account
  },
  props: {
    login: {
      type: Object,
      required: true
    }
  },
  computed: {
    isMobile: {
      get () {
        return isMobile()
      }
    }
  },
  methods: {
    updateLogin (obj) {
      this.$emit('updateLogin', obj)
    }
  }
}
</script>

<style lang="scss" scoped>
  .member-header {
    background: #1A265E;
    display: flex;
    height: 38px;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    .header-container {
      display: flex;
      width: 100%;
      height: inherit;
      justify-content: space-between;
      align-items: center;
      color: #F2F2F2;
      .header-left-container {
        height: inherit;
        font-size: 14px;
        margin: 0 10px;
        .header-logo-container{
          display: flex;
          position: relative;
          align-items: center;
          height: inherit;
          &::before{
            content: '';
            display: inline-block;
            position: absolute;
            width: 1.5px;
            height: 20px;
            right: 4.35em;
            top: 50%;
            transform: translateY(-50%);
            background-color: #E0E0E0;
            vertical-align: middle;
          }
          &::after{
            content: '会员中心';
            display: inline-block;
            font-size: 14px;
            font-weight: 600;
            vertical-align: middle;
          }
          img {
            width: auto;
            height: 80%;
            margin-right: 1em;
            vertical-align: middle;
          }
        }
        span {
          cursor: pointer;
        }
      }
      .header-right-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        // gap: 5px;
        margin: 0 5px;
        & > div{margin-right: 10px;}
        span {
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 575.98px) {}
  @media (min-width: 576px) and (max-width: 767.98px) {}
  @media (min-width: 768px) and (max-width: 991.98px) {}
  @media (min-width: 992px) and (max-width: 1199.98px) {}
  @media (min-width: 1200px) {
    .member-header {
      .header-container {
        width: 1200px;
        .header-left-container{
          margin: 0;
        }
        .header-right-container{
          margin: 0;
        }
      }
    }
  }
</style>
