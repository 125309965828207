<template>
  <div class="check">
    <div class="check-content">
      <div
        class="check-icon">
        <img :src="CheckIcon">
      </div>
      <p>已绑定</p>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/account/forgetSendCheck.png'

export default {
  data () {
    return {
      CheckIcon
    }
  }
}
</script>

<style lang="scss" scoped>
.check {
  display: inline-block;
  margin-left: 20px;
  vertical-align: sub;
  .check-content {
    display: flex;
    align-items: center;
    color: #9B9B9B;
    .check-icon {
      display: flex;
      align-items: center;
      width: 18px;
      height: 18px;
      margin-right: 7px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      font-size: 13px;
    }
  }
}
@media (max-width: 575.98px) {
  .check {
    margin-left: 10px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .check {
    margin-left: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {}
</style>
