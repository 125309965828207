import { allAnalysis } from './analysis'
import { allTrend } from './trend'

const chartAnalysis = ['numAnalysis', 'historyNum'] // , 'lzAnalysis', 'hcAnalysis', 'dslsHistory'
const chartTrend = ['basicTrend', 'dyTrend', 'dtTrend', 'xtTrend'] //, 'xtTrend'
const headArr = ['万位', '千位', '百位', '十位', '个位']
const DtCompare = ['万位', '个位']
const xtHeadArr = ['前三形态', '中三形态', '后三形态']

export const chartLotHH = {
  analysis: allAnalysis.filter(item => chartAnalysis.includes(item.id)),
  trend: allTrend.filter(item => chartTrend.includes(item.id)),
  headArr: headArr,
  xtHeadArr: xtHeadArr,
  resultMinNum: 0,
  resultMaxNum: 9,
  DtCompare: DtCompare,
  resultFillZero: false
}
