import { chartLot11P5 } from './chart/lot11P5'
import imgGD11X5 from '@/assets/lot-logo/GD11X5.png'
import imgSD11X5 from '@/assets/lot-logo/SD11X5.png'
import imgJX11X5 from '@/assets/lot-logo/JX11X5.png'
import imgSH11X5 from '@/assets/lot-logo/SH11X5.png'
import imgAF11X5 from '@/assets/lot-logo/AF11X5.png'
import imgHLJ11X5 from '@/assets/lot-logo/HLJ11X5.png'
import imgJS11X5 from '@/assets/lot-logo/JS11X5.png'
import imgLL11X5 from '@/assets/lot-logo/LL11X5.png'
import imgGX11X5 from '@/assets/lot-logo/GX11X5.png'
import imgYN11X5 from '@/assets/lot-logo/YN11X5.png'
import imgHB11X5 from '@/assets/lot-logo/HB11X5.png'

const lot11P5Basic = [
  {
    id: 'GD11X5',
    name: '广东',
    logo: imgGD11X5
  },
  {
    id: 'SD11X5',
    name: '山东',
    logo: imgSD11X5
  },
  {
    id: 'JX11X5',
    name: '江西',
    logo: imgJX11X5
  },
  {
    id: 'SH11X5',
    name: '上海',
    logo: imgSH11X5
  },
  {
    id: 'AF11X5',
    name: '安徽',
    logo: imgAF11X5
  },
  {
    id: 'HLJ11X5',
    name: '黑龙江',
    logo: imgHLJ11X5
  },
  {
    id: 'JS11X5',
    name: '江苏',
    logo: imgJS11X5
  },
  {
    id: 'LL11X5',
    name: '辽宁',
    logo: imgLL11X5
  },
  {
    id: 'GX11X5',
    name: '广西',
    logo: imgGX11X5
  },
  {
    id: 'YN11X5',
    name: '云南',
    logo: imgYN11X5
  },
  {
    id: 'HB11X5',
    name: '湖北',
    logo: imgHB11X5
  }
]

const lot11P5RuleTemplate = {
  display: {
    total: true,
    totalP5: true,
    pf3: true,
    pm3: true,
    pl3: true
  },
  link: {
    history: true,
    trend: true,
    prediction: true
    // LSHistory: true
  },
  chart: chartLot11P5
}

export const lot11P5 = lot11P5Basic
  .map(lot => {
    switch (lot.id) {
      default:
        return ({
          ...lot,
          rule: lot11P5RuleTemplate
        })
    }
  })

// export const lot11P5 = [
//   {
//     id: 'GD11X5',
//     name: '广东',
//     logo: imgGD11X5,
//     rule: {
//       display: {
//         total: true,
//         totalP5: true,
//         pf3: true,
//         pm3: true,
//         pl3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLot11P5
//     }
//   },
//   {
//     id: 'SD11X5',
//     name: '山东',
//     logo: imgSD11X5,
//     rule: {
//       display: {
//         total: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLot11P5
//     }
//   },
//   {
//     id: 'JX11X5',
//     name: '江西',
//     logo: imgJX11X5,
//     rule: {
//       display: {
//         total: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLot11P5
//     }
//   },
//   {
//     id: 'SH11X5',
//     name: '上海',
//     logo: imgSH11X5,
//     rule: {
//       display: {
//         total: true,
//         totalP5: true,
//         pf3: true,
//         pm3: true,
//         pl3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLot11P5
//     }
//   },
//   {
//     id: 'AF11X5',
//     name: '安徽',
//     logo: imgAF11X5,
//     rule: {
//       display: {
//         total: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLot11P5
//     }
//   },
//   {
//     id: 'HLJ11X5',
//     name: '黑龙江',
//     logo: imgHLJ11X5,
//     rule: {
//       display: {
//         total: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLot11P5
//     }
//   },
//   {
//     id: 'JS11X5',
//     name: '江苏',
//     logo: imgJS11X5,
//     rule: {
//       display: {
//         total: true,
//         totalP5: true,
//         pf3: true,
//         pm3: true,
//         pl3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLot11P5
//     }
//   },
//   {
//     id: 'LL11X5',
//     name: '辽宁',
//     logo: imgLL11X5,
//     rule: {
//       display: {
//         total: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLot11P5
//     }
//   },
//   {
//     id: 'GX11X5',
//     name: '广西',
//     logo: imgGX11X5,
//     rule: {
//       display: {
//         total: true,
//         totalP5: true,
//         pf3: true,
//         pm3: true,
//         pl3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLot11P5
//     }
//   },
//   {
//     id: 'YN11X5',
//     name: '云南',
//     logo: imgYN11X5,
//     rule: {
//       display: {
//         total: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLot11P5
//     }
//   },
//   {
//     id: 'HB11X5',
//     name: '湖北',
//     logo: imgHB11X5,
//     rule: {
//       display: {
//         total: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLot11P5
//     }
//   }
// ]
