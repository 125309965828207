import request from '@/utils/axios'
import { objToUrlQuery } from '@/utils/axiosTools'

// 獲取專家商店列表
export function getGodShopLists(data) {
  return request({
    url: `/api/god/shops?${objToUrlQuery(data)}`,
    method: 'get',
  })
}

// 關注專家
export function followShop(followID) {
  return request({
    url: `/api/god/shops/${followID}?action=follow`,
    method: 'get',
  })
}

// 取得專家單一預測號
export function getPredictHistory(data) {
  const id = data.id
  delete data.id
  return request({
    url: `/api/god/shops/${id}/predict?${objToUrlQuery(data)}`,
    method: 'get',
  })
}

// 取得專家個人頁面(近期預測)
export function getExpertLastPredict(data) {
  const id = data.id
  delete data.id
  return request({
    url: `/api/god/shops/${id}/predict?${objToUrlQuery(data)}`,
    method: 'get',
  })
}
