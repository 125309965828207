// Reference: https://github.com/axios/axios
import axios from 'axios'
import resCode from '@/constants/resCode'
import resStatus from '@/constants/resStatus'
import { isAdminApi, adminLogout } from '@/utils/admin'
import { isMemberApi, memberLogout } from '@/utils/member'
import { goPage } from '@/utils/route'

const instance = axios.create({
  // Api base url
  baseURL: process.env.VUE_APP_DEMO_API_ROOT || '/',
  // baseURL: '/',
  // timeout: 15000
  timeout: 30000
})

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    return config
  },
  // Handle error
  (err) => {
    return Promise.reject(err)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  (res) => {
    if (res.status === resCode.OK) {
      if (isAdminApi(res.config.url)) {
        if (
          res.data.status === resStatus.adminNotLogin
        ) {
          // Admin not login
          adminLogout()
          goPage({ name: 'AdminLogin' })
        }
      } else if (isMemberApi(res.config.url)) {
        if (
          res.data.status === resStatus.memberNotLogin
        ) {
          // member not login
          memberLogout()
          goPage({ name: 'Home' })
        }
      }
      return res.data
    } else {
      return res
    }
  },
  // Handle error
  (err) => {
    return Promise.reject(err)
  }
)

export default instance
