<script>
import demo from './SignUpSuccess'

export default {
  ...demo,
  data () {
    return {
      msg: '登录成功'
    }
  }
}
</script>
