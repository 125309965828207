<template>
  <div class="main-container">
    <div v-if="route === 'MemberInfo'">
      <Head :login="login" @refreshLoginData="refreshLoginData" />
      <Info :login="login" />
    </div>
    <div v-if="route === 'MemberMessage'">
      <div class="title-cotainer"> 消息中心 </div>
      <el-tabs @tab-click="handleRender" v-model="activeName" stretch>
        <el-tab-pane label="我的消息" name="myNews">
          <MyNews v-if="myNews" :title="false" :login="login" />
        </el-tab-pane>
        <el-tab-pane label="系統通知" name="system">
          <System v-if="system" :title="false" :login="login" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Head from '../../Layout/Main/Head'
import Info from '../../Info'
import MyNews from '../../Message/MyNews'
import System from '../../Message/System'

import { postLogout } from '@/api/client/member'
import resStatus from '@/constants/resStatus'
import { goPage } from '@/utils/route'
import { memberLogout } from '@/utils/member'
import { isMobile } from '@/utils/device'

export default {
  data() {
    return {
      activeName: 'myNews',
      system: true,
      myNews: true,
    }
  },
  components: {
    Head,
    Info,
    MyNews,
    System,
  },
  props: {
    login: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isMobile: {
      get() {
        return isMobile()
      },
    },
    route() {
      return this.$route.params.route
    },
  },
  created() {
    if (!this.isMobile) goPage({ name: 'MemberMessage' })
  },
  methods: {
    handleRender(tab, evt) {
      if (tab.name === 'system') {
        this.system = true
        this.myNews = false
      } else {
        this.system = false
        this.myNews = true
      }
    },
    refreshLoginData() {
      this.$emit('refreshLoginData')
    },
    updateLogin(obj) {
      this.$emit('updateLogin', obj)
    },
    handleLogout() {
      postLogout()
        .then((res) => {
          switch (res.status) {
            case resStatus.OK:
              if (
                this.$route.matched[0].name === 'Member' ||
                this.$route.matched[1].name === 'GodPlay'
              ) {
                goPage({ name: 'Home' })
              }
              this.handleLogoutSuccess()
              break
            default:
              console.log('logout error: ', res)
          }
        })
        .catch((err) => {
          console.error('logout error: ', err)
        })
    },
    handleLogoutSuccess() {
      this.updateLogin({
        data: null,
        isLogin: false,
      })
      memberLogout()
    },
  },
  watch: {
    login(curData) {
      if (!curData.isLogin && !curData.data) {
        goPage({ name: 'Home' })
      }
      if (!curData.formType) {
        document.querySelector('#app').style.height = 'unset'
        document.querySelector('#app').style.overflow = 'unset'
      } else {
        document.querySelector('#app').style.height = '100vh'
        document.querySelector('#app').style.overflow = 'hidden'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__nav-scroll {
  // width: 176px;
  // margin: 0 auto;
  // color: #005FDE;
}
::v-deep .el-tabs__item {
  padding: 0;
  color: #000;
  height: 60px;
  line-height: 60px;
  font-size: 17px;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item.is-active {
  color: #005fde;
  font-weight: 800;
}
.title-cotainer {
  background: #56ccf2;
  padding: 20px 0;
  color: #fff;
  font-size: 19px;
  font-weight: 800;
}
.main-container {
  .main-content {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
  }
  .logout-content {
    width: 100%;
    font-size: 18px;
    line-height: 25px;
    padding: 15px 0;
    text-align: center;
    margin: 10px 0 30px;
    color: #4f4f4f;
    background-color: #fff;
  }
}
@media (max-width: 575.98px) {
  .main-container {
    width: 100%;
    .main-content {
      flex-direction: column;
      // background-color: #F3F9FF;
      .content-main {
        width: 100%;
        height: 45.555vh;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-container {
    width: 100%;
    .main-content {
      .content-main {
        width: 100%;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-container {
    width: 100%;
    padding: 10px 0 30px;
    .main-content {
      gap: 20px;
      padding: 30px 35px 45px;
      .content-side {
        width: 24.167vw;
      }
      .content-main {
        // width: 788px;
        min-width: 65.67vw;
        margin-left: 15px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-container {
    width: 100%;
    padding: 10px 0 30px;
    .main-content {
      gap: 20px;
      padding: 30px 40px 45px;
      .content-side {
        width: 24.167vw;
      }
      .content-main {
        // width: 788px;
        min-width: 65.67vw;
        margin-left: 20px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .main-container {
    width: 1200px;
    padding: 10px 0 30px;
    .main-content {
      // gap: 20px;
      padding: 30px 51px 50px;
      .content-side {
        width: 290px;
        margin-right: 20px;
      }
      .content-main {
        width: 788px;
      }
    }
  }
}
</style>
