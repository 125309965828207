import router from '@/router'
import { has } from 'lodash'

export function goWebsite (url, blank) {
  if (!blank) {
    window.location.href = url
  } else {
    window.open(url, '_blank')
  }
}

export function goPage (objRoute) {
  router.push(objRoute)
}

export function goPageBlank (objRoute) {
  const routeUrl = router.resolve(objRoute)
  window.open(routeUrl.href, '_blank')
}

export function getRouteByName (name) {
  const allRoutes = router.options.routes
  return (
    allRoutes
      .filter(route => {
        return route.name === name
      })[0]
  )
}

export function getPageRoutes () {
  return getRouteByName('Root').children.filter(obj => {
    return !has(obj, 'hidden')
  })
}

export function getMobilePageRoutes () {
  const showArr = ['LotteryResult', 'LotteryChart', 'Welfare', 'HelpCenter']
  return getRouteByName('Root').children.filter(obj => {
    return showArr.includes(obj.name)
  })
}
