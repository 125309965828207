import request from '@/utils/axios'
// import { getFormData } from '@/utils/axiosTools'

// 取得彩種分類
export function getLotTypes() {
  return request({
    url: '/api/lottery_types',
    method: 'get',
  })
}
