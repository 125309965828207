import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import ElementUI from 'element-ui'

import '@/bootstrap'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss'
import '@/styles/modifyElementUI.scss'

import './handleRouterNavigation'
import { realImage } from '@/utils/realImage'
import common from '@/utils/common.js'
import * as message from '@/utils/messageHandler.js'
import resStatus from '@/constants/resStatus'

import VueCarousel from 'vue-carousel';

// 處理資源路徑 $common.resSrc
Vue.prototype.$common = common
Vue.prototype.$resStatus = resStatus
Vue.prototype.$MSG = message

// Stone要求不希望看到控制台出現 unHandler promise的訊息
// 因此可在promise事件後加上.catch(this.$autoCatch)
// 若是自己handle promise catch事件則不用使用此函數
Vue.prototype.$autoCatch = function (err) {
  if (process.env.NODE_ENV == 'development') {
    console.error("Promise autoCatch error:", err);
  }
}

Vue.directive('real-img', function (el, binding) { realImage.init(el, binding) })
Vue.use(ElementUI)
Vue.use(VueCarousel);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
