<template>
  <a
    href="#"
    class="close-icon"
    @click.prevent="handleCloseClick" />
</template>

<script>
export default {
  methods: {
    handleCloseClick () {
      this.$emit('handleCloseClick')
    }
  }
}
</script>

<style lang="scss" scoped>
// .close-icon {
//   opacity: 1;
// }
// .close-icon:hover {
//   opacity: 1;
//
.close-icon:before, .close-icon:after {
  position: absolute;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #494949;
}
.close-icon:before {
  transform: rotate(45deg);
}
.close-icon:after {
  transform: rotate(-45deg);
}
</style>
