<template>
  <div class="loading-view" v-if="show">
    <div class="vertical-centered-box">
      <div class="content">
        <span class="loader-text">Loading</span>
        <div class="loader-circle"></div>
        <div class="loader-line-mask one">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-mask two">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-mask three">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-mask four">
          <div class="loader-line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
$blendmode: hard-light;
$size: 100px;
$thickness: 8px;

$blue: #2c82a7;
$green: #75b62a;
$red: #e63d75;
$yellow: #FFE066;

$timing1: 2s;
$timing2: 1.8s;
$delay: $timing1/4;

.loading-view {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  .vertical-centered-box {
    position: absolute;
    width: 120px;
    height: 120px;
    text-align: center;
    background: #FFF;
    border-radius: 8px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &:after {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em;
    }
    .content {
      box-sizing: border-box;
      animation: fadein .5s linear forwards;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      font-size: 0;
      opacity: 0;
      .loader-text{
        font-size: 12px;
        color: rgb(131, 131, 131);
      }
      .loader-line-mask {
        position: absolute;
        left: 50%;
        top: 50%;
        // .size(@size/2, @size);
        width: 35px;
        height: 70px;
        // margin-left: -$size/2;
        // margin-top: -$size/2;
        margin-left: -35px;
        margin-top: -35px;
        overflow: hidden;
        // .transform-origin(@size/2 @size/2);
        transform-origin: 35px 35px;
        mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        -webkit-mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        mix-blend-mode: $blendmode;
        opacity: 0.8;
        
        .loader-line {
          width: 70px;
          height: 70px;
          border-radius: 50%;
        }
        
        &.one {
          animation: rotate $timing1 infinite linear;
          .loader-line {
            box-shadow: inset 0 0 0 8px $green;
          }
        }
        
        &.two {
          animation: rotate $timing2 $delay infinite linear;
          .loader-line {
            box-shadow: inset 0 0 0 8px $red;
          }
        }
        
        &.three {
          animation: rotate $timing1 $delay*2 infinite linear;
          .loader-line {
            box-shadow: inset 0 0 0 8px $yellow;
          }
        }
        
        &.four {
          animation: rotate $timing2 ($delay*3) - (($timing1 - $timing2) / 2) infinite linear;
          .loader-line {
            box-shadow: inset 0 0 0 8px $blue;
          }
        }
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  } 
  100% {
    transform: rotate(360deg); 
  }
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  } 
  100% {
    transform: rotate(360deg); 
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  } 
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  } 
  100% {
    opacity: 1;
  }
}
</style>
