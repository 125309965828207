<template>
  <div class="side-container">
    <div
      v-for="(route, idx) in memberRoutes"
      class="route-item"
      @click="handleChangeRoute(route, idx)"
      :class="isRouteActive(route, idx)"
      :key="'route-' + idx"
    >
      <div
        class="arrow-fr"
        v-if="route.children && route.children.length > 0 && isShowChildren(route)"
      >
        <img
          :src="
            openNavIndex !== idx
              ? require('@/assets/arrow-down.png')
              : require('@/assets/arrow-up.png')
          "
        />
      </div>
      <div>{{ route.meta.context }}</div>
      <div class="route-child" v-if="openNavIndex === idx && isShowChildren(route)">
        <div
          :class="isSubRouteActive(childItem)"
          v-for="(childItem, childIndex) in route.children"
          :key="childIndex"
        >
          <div @click.stop="handleChangeSubRoute(childItem)">{{ childItem.meta.context }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goPage, getRouteByName } from '@/utils/route'
import { isMobile } from '@/utils/device'

export default {
  computed: {
    isMobile: {
      get() {
        return isMobile()
      },
    },
    memberRoutes() {
      const arr = getRouteByName('Member').children
      const newArr = []
      arr.forEach((res) => {
        if (res.name === 'MemberStore' && this.$store.state.member.category === '2') {
          newArr.push(res)
        } else {
          if (res.name !== 'MemberHome' && res.name !== 'MemberStore') {
            newArr.push(res)
          }
        }
      })
      return newArr
      // return getRouteByName('Member').children
    },
  },
  data() {
    return {
      openNavIndex: null,
    }
  },
  created() {
    this.handleRouteActive()
  },
  methods: {
    isShowChildren(route) {
      return !route.meta?.notShowChildren
    },
    handleRouteActive() {
      if (this.isMobile) return
      this.memberRoutes.forEach((res, idx) => {
        if (this.$route.name.includes(res.name)) {
          this.openNavIndex = idx
        }
      })
    },
    isRouteActive(route, idx) {
      let retClass = ''
      if (this.$route.name.includes(route.name)) {
        if (idx === 0) {
          retClass = 'first-active'
        } else if (idx === this.memberRoutes.length - 1) {
          retClass = 'last-active'
        }
        return `active ${retClass}`
      }
      return retClass
    },
    isSubRouteActive(route) {
      const retClass = ''
      if (location.href.includes(route.path)) {
        return 'subActive'
      }
      return retClass
    },
    handleChangeSubRoute(route) {
      if (!location.href.includes(route.path)) {
        goPage({
          name: route.name,
        })
      }
    },
    handleChangeRoute(route, idx) {
      // route的 meta.notShowChildren 如果是true 就一定不會顯示兒子
      if (route.meta?.notShowChildren) {
        this.openNavIndex = idx
        goPage({
          name: route.name,
        })
      } else {
        if (route.children) {
          if (this.openNavIndex === idx) {
            this.openNavIndex = null
          } else {
            this.openNavIndex = idx
          }
        }
        if (!route.children) {
          this.openNavIndex = null
        }
      }

      if (!location.href.includes(route.path)) {
        if (!isMobile) {
          if (route.children) {
            goPage({
              name: route.children[0].name,
            })
          } else {
            goPage({
              name: route.name,
            })
          }
        } else {
          if (!route.children) {
            goPage({
              name: route.name,
            })
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.arrow-fr {
  float: right;
}
.side-container {
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  .route-child {
    line-height: 45px;
    // border: 1px #eee solid;
    font-size: 15px;
    padding: 0px 15px;
    color: #4f4f4f;
  }
  .subActive {
    color: #005fde;
  }
  .route-item {
    // height: 59px;
    font-weight: 500;
    font-size: 20px;
    padding: 0 32px;
    line-height: 59px;
    color: #4f4f4f;
    text-align: left;
    cursor: pointer;
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
    }
    // border-top: 1px transparent solid;
    // border-bottom: 1px transparent solid;
  }
  .active {
    background-color: #f3f9ff;
    color: #005fde !important;
    position: relative;
  }
  .active::before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 8px;
    height: 100%;
    background-color: #005fde;
    left: 0;
    top: 0;
  }
  .first-active::before {
    border-top-left-radius: 5px;
  }
  .last-active::before {
    border-bottom-left-radius: 5px;
  }
}
@media (max-width: 575.98px) {
  .side-container {
    border-radius: 0;
    box-shadow: none;
    .active {
      background-color: unset !important;
      color: unset !important;
      // border-top: 1px #eee solid;
      // border-bottom: 1px #eee solid;
    }
    .active::before {
      background-color: unset !important;
    }
    .route-item {
      font-size: 21px;
      border-bottom: 1px solid #e0e0e0;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .side-container {
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    .active {
      background-color: unset !important;
      color: unset !important;
      // border-top: 1px #eee solid;
      // border-bottom: 1px #eee solid;
    }
    .active::before {
      background-color: unset !important;
    }
    .route-item {
      font-size: 21px;
      border-bottom: 1px solid #e0e0e0;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .side-container {
    .route-item {
      // min-height: 50px;
      // height: fit-content;
      font-size: 18px;
      line-height: 50px;
      padding: 0 28px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
}
</style>
