<template>
  <div class="mobile-container">
    <header class='app-header'>
      <Header
        :login="login"
        @updateLogin="updateLogin" />
    </header>
    <div class='app-main'>
      <Main
        @refreshLoginData="refreshLoginData"
        :login="login" />
    </div>
    <div class='app-footer'>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from '@/views/Layout/Header'
import Main from './Main'
import Footer from '@/views/Layout/Footer'
import demo from '@/views/Layout'

export default {
  components: {
    Header,
    Main,
    Footer
  },
  data () {
    return {
      ...demo.data()
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisiable)
  },
  destroyed() {
    document.removeEventListener('visibilitychange', this.handleVisiable)
  },
  methods: {
    ...demo.methods
  },
  created () {
    this.checkLogin()
  }
}
</script>

<style lang="scss" scoped>
.mobile-container {
  .app-main {
    min-height: 100vh;
  }
}
.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow:hidden;
  .app-header {
    width: 100vw;
    position: relative;
    z-index: 99;
  }
  .app-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:#F3F9FF;
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .app-container{
    .app-main{
      min-height: calc(100vh - 52px - 65px - 35px); //NavHeight:52px, Footer: 65px, CopyRight: 35px
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .app-container{
    .app-main{
      min-height: calc(100vh - 52px - 65px - 35px); //NavHeight:52px, Footer: 65px, CopyRight: 35px
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {}
</style>
