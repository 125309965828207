<template>
  <div class="copyright-container">
    <p>Copyright ©多彩网 2019~{{ currentYear }} All Right Reserved.</p>
  </div>
</template>

<script>
import { getYear } from '@/utils/time'

export default {
  computed: {
    currentYear() {
      const nowDate = new Date()
      return getYear(nowDate)
    }
  }
}
</script>

<style lang="scss" scoped>
.copyright-container {
  width: 100vw;
  height: 35px;
  line-height: 35px;
  color: #fafafa;
  background-color: #1a265e;
  p {
    font-size: 12;
  }
}
</style>
