<template>
  <div class="nav-lot-result-container">
    <div
      v-for="tab in allLots"
      :key="tab.id"
      class="tab-row">
      <h3>{{ tab.context }}</h3>
      <ul class="child-container">
        <li
          v-for="child in tab.children"
          :key="child.id">
          <router-link
            class="child-item"
            @click.native="handleClickLotteryResult"
            :to="{
              name: 'LotteryResult',
              query: {
                tab: tab.id,
                id: child.id
              }
            }">
            {{ child.name }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { allLots } from '@/constants/lots'

export default {
  data () {
    return {
      allLots: allLots
    }
  },
  methods: {
    // handleGetData () {
    //   console.log('tab data: ', allLots)
    // },
    handleClickLotteryResult () {
      this.$emit('showResultList', false)
    }
  }
}
</script>

<style lang="scss" scoped>
$classW: 135px;
.nav-lot-result-container {
  width: 100%;
  background-color: #FFFFFF;
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 15px 0px #dddddd;
  .tab-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 30px;
    h3 {
      min-width: $classW;
      color: #005FDE;
      font-size: 18px;
      text-align: left;
      line-height: 25px;
    }
    ul.child-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      flex-grow: 99;
      // column-gap: 35px;
      // row-gap: 5px;
      li {
        width: 120px;
        text-align: left;
        margin-right: 35px;
        margin-bottom: 5px;
        .child-item {
          font-size: 14px;
          line-height: 25px;
          color: #000000;
          text-align: left;
          padding: 3px 9px;
        }
        .router-link-exact-active, .child-item:hover {
          background: #2F84ED;
          color: #FAFAFA;
          border-radius: 3px;
        }
      }
    }
  }
  .tab-row:not(:last-child) {
    border-bottom: 1px solid #E0E0E0;
  }
}
@media (max-width: 575.98px) {
  .nav-lot-result-container{
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .nav-lot-result-container{
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {
  .nav-lot-result-container {
    width: 1200px;
  }
}
</style>
