import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './syncRoutes'

Vue.use(VueRouter)

// const pagesScrollToTop = [
//   'LotteryResult',
//   'LotteryChartDetail',
//   'LotteryInfo',
//   'LotteryInfoDetail',
//   'WelfareDetail'
// ]

const router = new VueRouter({
  // mode: 'history', // 後端支援可開
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 切換頁面時，回到最頂端
    // if (pagesScrollToTop.includes(to.name)) {
    return {
      x: 0,
      // y: 146
      y: 0,
      behavior: 'smooth'
    }
    // }
  }
})

// 解決路由重複報錯的Bug
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
