const resStatus = {
  OK: '0000',
  OK2: '0001',
  error: '1000',
  ban: '1001',
  todayNotYet: '1002',
  fail: '1010',
  memberNotLogin: '1004',
  adminNotLogin: '1005'
}

export default resStatus
