<template>
  <div class="success-checkmark">
    <div class="check-icon">
      <span class="icon-line line-tip"></span>
      <span class="icon-line line-long"></span>
      <div class="icon-circle"></div>
      <div class="icon-fix"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.success-checkmark {
  width: 70px;
  height: 70px;
  margin: 0 auto 15px;
  .check-icon {
    width: 62px;
    height: 62px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4CAF50;
      
    &::before {
      top: 1px;
      left: 0;
      width: 24px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }
  
    &::after {
      top: 0;
      left: 22px;
      width: 48px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }
  
    &::before, &::after {
      content: '';
      height: 80px;
      position: absolute;
      background: #FFFFFF;
      transform: rotate(-45deg);
    }
    .icon-line {
      height: 4px;
      background-color: #4CAF50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;
      &.line-tip {
        top: 36px;
        left: 10px;
        width: 20px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }
      &.line-long {
        top: 30px;
        right: 5px;
        width: 38px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }
    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 62px;
      height: 62px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, .5);
    }
  
    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 68px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #FFFFFF;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    // width: 0;
    // left: 1px;
    // top: 19px;
    width: 0;
    left: 0;
    top: 15px;
  }
  54% {
    // width: 0;
    // left: 1px;
    // top: 19px;
    width: 0;
    left: 0;
    top: 15px;
  }
  70% {
    // width: 50px;
    // left: -8px;
    // top: 37px;
    width: 40px;
    left: -6px;
    top: 30px;
  }
  84% {
    // width: 17px;
    // left: 21px;
    // top: 48px;
    width: 14px;
    left: 17px;
    top: 38px;
  }
  100% {
    // width: 25px;
    // left: 14px;
    // top: 45px;
    width: 20px;
    left: 10px;
    top: 36px;
  }
}

@keyframes icon-line-long {
  0% {
    // width: 0;
    // right: 46px;
    // top: 54px;
    width: 0;
    right: 37px;
    top: 43px;
  }
  65% {
    // width: 0;
    // right: 46px;
    // top: 54px;
    width: 0;
    right: 37px;
    top: 43px;
  }
  84% {
    // width: 55px;
    // right: 0px;
    // top: 35px;
    width: 44px;
    right: 0px;
    top: 28px;
  }
  100% {
    // width: 47px;
    // right: 8px;
    // top: 38px;
    width: 38px;
    top: 30px;
    right: 5px;
  }
}
</style>
