import { allAnalysis } from './analysis'
import { allTrend } from './trend'

const chartAnalysis = ['numAnalysis', 'historyNum'] // , 'dslsHistory'
const chartTrend = ['basicTrend', 'dyTrend', 'hzTrend', 'oeTrend', 'lsTrend'] // , 'lsTrend'
const headArr = ['百位', '十位', '个位']

export const chartLotQ3 = {
  analysis: allAnalysis.filter(item => chartAnalysis.includes(item.id)),
  trend: allTrend.filter(item => chartTrend.includes(item.id)),
  headArr: headArr,
  isResultRepeat: true,
  resultFillZero: false,
  resultMinNum: 1,
  resultMaxNum: 6
}
