<template>
  <div v-if="!route.hidden">
    <el-submenu
      v-if="route.children && childRoute.length > 0"
      :index="idx">
      <template slot="title">
        <i :class="route.meta.icon"></i>
        <span slot="title">{{ route.meta.title }}</span>
      </template>
      <el-menu-item-group>
        <route-child-item
          v-for="(child, subIdx) in route.children"
          :key="'child-route' + subIdx"
          :route="child"
          :idx="String(subIdx)" />
        <!-- <el-menu-item
          v-for="(child, subIdx) in route.children"
          :key="'child-route-' + subIdx"
          :index="child.path">
          {{ child.meta.title }}
        </el-menu-item> -->
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item
      v-else
      :index="route.path">
      <i :class="route.meta.icon"></i>
      <span slot="title">{{ route.meta.title }}</span>
    </el-menu-item>
  </div>
</template>

<script>
export default {
  components: {
    RouteChildItem: () => import('./RouteItem')
  },
  props: {
    route: {
      type: Object,
      required: true
    },
    idx: {
      type: String,
      required: true,
      default: '1'
    }
  },
  data () {
    return {
      childRoute: null
    }
  },
  created () {
    if (this.route.children) {
      this.childRoute = this.route.children.filter(obj => {
        return !obj.invisiable
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu-item {
  text-align: left;
}
.el-submenu {
  text-align: left;
}
</style>
