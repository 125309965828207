<template>
  <div class="account-container">
    <div class="avatar-container" @click="handleToggleOption">
      <div class="photo-container">
        <!-- <img v-if="login.data && login.data.photo !== ''" :src="login.data.photo" /> -->
        <div
          v-if="login.isLogin && login.data.photo && login.data.photo !== ''"
          :style="{ 'background-image': `url('${$common.resSrc(login.data.photo)}')` }"
        ></div>
        <b-icon v-else icon="person-fill"></b-icon>
      </div>
      <div class="name-container">{{ login.data.username }}</div>
    </div>
    <div v-if="showOption" class="option-container">
      <div class="option-item" @click="handleGoPage('MemberInfo')"> 我的资料 </div>
      <div class="option-item" @click="handleLogout"> 退出 </div>
    </div>
  </div>
</template>

<script>
import { postLogout } from '@/api/client/member'
import resStatus from '@/constants/resStatus'
// import { rmCookie } from '@/utils/cookie'
import { goPage } from '@/utils/route'
import { memberLogout } from '@/utils/member'

export default {
  props: {
    login: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showOption: false,
    }
  },
  methods: {
    updateLogin(obj) {
      this.$emit('updateLogin', obj)
    },
    handleToggleOption() {
      this.showOption = !this.showOption
    },
    handleGoPage(pageName) {
      this.handleToggleOption()
      goPage({ name: pageName })
    },
    handleLogout() {
      postLogout()
        .then((res) => {
          switch (res.status) {
            case resStatus.OK:
              if (
                this.$route.matched[0].name === 'Member' ||
                this.$route.matched[1].name === 'GodPlay'
              ) {
                goPage({ name: 'Home' })
              }
              this.handleLogoutSuccess()
              break
            default:
              console.log('logout error: ', res)
          }
        })
        .catch((err) => {
          console.error('logout error: ', err)
        })
    },
    handleLogoutSuccess() {
      this.updateLogin({
        data: null,
        isLogin: false,
      })
      memberLogout()
    },
  },
}
</script>

<style lang="scss" scoped>
$photo-w: 26px;
$option-w: 130px;
.account-container {
  position: relative;
  .avatar-container {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap: 10px;
    .photo-container {
      width: $photo-w;
      height: $photo-w;
      background-color: #c2d6ff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
      & > div {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
      }
    }
  }
  .option-container {
    position: absolute;
    display: flex;
    width: $option-w;
    flex-direction: column;
    align-items: center;
    top: 33px;
    left: calc((-#{$option-w} / 2) + (#{$photo-w} / 2));
    // height: 224px;
    background-color: #ffffff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 10px 0 10px;
    .option-item {
      width: 100%;
      font-size: 14px;
      color: #4f4f4f;
      cursor: pointer;
      padding: 5px 0;
    }
    .option-item:hover {
      background-color: #e0f7ff;
    }
  }
  .option-container:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 60px;
    background-color: #ffffff;
    padding-bottom: 9px;
    width: 10px;
    transform-origin: 0 0;
    transform: rotate(-59deg) skewX(-25deg);
    border-radius: 1px;
    z-index: 1;
  }
}
@media (max-width: 575.98px) {
}
@media (min-width: 576px) and (max-width: 767.98px) {
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
}
</style>
