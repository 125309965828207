export const categoryType = {
  1: '彩民',
  2: '竞猜师'
}

export const categoryOption = Object.keys(categoryType).map(key => {
  return {
    value: key,
    label: categoryType[key]
  }
})
