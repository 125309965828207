<template>
  <div
    class="commercial-container"
    v-if="ads"
  >
    <a
      :href="ads.href"
      target="_blank"
      @click="sendVisit(ads.id)"
    >
      <img :src="$common.resSrc(ads.photo)" />
    </a>
  </div>
</template>

<script>

import { visit } from '@/api/client/visit'
import { getBanners } from '@/api/client/banner'
import resStatus from '@/constants/resStatus'
import { isMobile } from '@/utils/device'

export default {
  data () {
    return {
      ads: null,
      visitQuery: {
        to: 'ad',
        uuid: '',
        id: ''
      },
      bannerQuery: {
        position_ids: '1'
      }
    }
  },
  methods: {
    sendVisit (id) {
      this.visitQuery.id = id
      visit(this.visitQuery)
    },
    getCommercials () {
      getBanners(this.bannerQuery)
        .then(res => {
          if (res.status === resStatus.OK) {
            if (res.data[1]) {
              this.ads = res.data[1][0]
            }
          }
        })
    }
  },
  created () {
    this.getCommercials()
  }
}
</script>

<style lang="scss" scoped>
.commercial-container {
  width: 280px;
  height: 70px;
  img {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 767.98px) {
  .commercial-container {
    width: unset;
    height: unset;
    padding: 0 12px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .commercial-container {
    width: 190px;
    height: 47.5px;
    margin-right: 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .commercial-container {
    margin-right: 15px;
  }
}
@media (min-width: 1200px) {
}
</style>
