<template>
  <div class="app-container">
    <header class="app-header">
      <Header :login="login" @refreshView="refreshView" @updateLogin="updateLogin" />
    </header>
    <div class="app-main" :style="appMainStyle">
      <router-view
        class="app-content"
        :login="login"
        :reloadPage="needReloadPage"
        @refreshView="refreshView"
        @updateLogin="updateLogin"
        @refreshLoginData="refreshLoginData"
        @handleLoading="handleLoading"
      />
    </div>
    <div class="app-footer">
      <Footer />
    </div>
    <Account :login="login" @updateLogin="updateLogin" @handleLoading="handleLoading" />
    <Loading :show="showLoad" />
  </div>
</template>

<script>
import Header from './Header'
import Footer from './Footer'
import Account from './Account'
// import { getCookie } from '@/utils/cookie'
import { getMe } from '@/api/client/member'
import resStatus from '@/constants/resStatus'
// import { setCookie, rmCookie } from '@/utils/cookie'
import { isMemberLogin, memberLogin, memberLogout } from '@/utils/member'
import { isMobile } from '@/utils/device'
import Loading from '@/components/Loading'

export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    Account,
    Loading,
  },
  data() {
    return {
      showLoad: false,
      needReloadPage: false,
      login: !isMobile()
        ? {
            formType: null,
            formErrMsg: '',
            allTypes: {
              SignUp: '注册',
              SignIn: '会员登录',
              Forget: '找回密码',
            },
            isLogin: false,
            data: null,
          }
        : {
            formType: null,
            formErrMsg: '',
            allTypes: {
              SignUp: '欢迎注册多彩网',
              SignIn: '欢迎登入多彩网',
              Forget: '找回密码',
            },
            isLogin: false,
            data: null,
          },
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisiable)
  },
  destroyed() {
    document.removeEventListener('visibilitychange', this.handleVisiable)
  },
  methods: {
    handleVisiable(e) {
      if (e.target.visibilityState === 'visible') {
        this.checkLogin()
      }
    },
    handleLoading(boo) {
      this.showLoad = boo
    },
    updateLogin(obj) {
      this.login = {
        ...this.login,
        ...obj,
      }
    },
    checkLogin() {
      this.refreshLoginData()
    },
    handleSignInSuccess(res) {
      this.updateLogin({
        data: res.data,
        isLogin: true,
      })
    },
    refreshLoginData() {
      if (isMemberLogin()) {
        getMe()
          .then((res) => {
            switch (res.status) {
              case resStatus.OK:
                this.$store.commit('member/setCategory', res.data.category)
                this.updateLogin({
                  data: res.data,
                  isLogin: true,
                })
                memberLogin()
                break
              default:
                this.handleUserNotLogin(res.message)
            }
          })
          .catch((err) => {
            this.handleUserNotLogin(err)
          })
      } else {
        this.handleUserNotLogin()
      }
    },
    handleUserNotLogin(msg) {
      if (msg) {
        this.$message.error(msg)
        this.updateLogin({
          data: null,
          isLogin: false,
        })
      }
      memberLogout()
      this.updateLogin({
        data: null,
        isLogin: false,
      })
    },
    refreshView(boo) {
      this.needReloadPage = boo
    },
  },
  computed: {
    appMainStyle() {
      if (this.$route.name === 'Download') {
        return 'background: transparent linear-gradient(180deg, #f3f9ff 0%, #d1f3ff 100%) 0% 0% no-repeat padding-box;'
      } else {
        return ''
      }
    },
  },
  created() {
    this.checkLogin()
  },
  watch: {
    login(curData, preData) {
      if (curData.isLogin !== preData.isLogin) {
        this.refreshView(true)
      }
      if (!curData.formType) {
        document.querySelector('#app').style.height = 'unset'
        document.querySelector('#app').style.overflow = 'unset'
      } else {
        document.querySelector('#app').style.height = '100vh'
        document.querySelector('#app').style.overflow = 'hidden'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .app-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f3f9ff;
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .app-container {
    .app-main {
      justify-content: flex-start;
      min-height: calc(100vh - 52px - 65px - 35px); //NavHeight:52px, Footer: 65px, CopyRight: 35px
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .app-container {
    .app-main {
      justify-content: flex-start;
      min-height: calc(100vh - 52px - 65px - 35px); //NavHeight:52px, Footer: 65px, CopyRight: 35px
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
  .app-content {
    width: 1200px;
  }
}
</style>
