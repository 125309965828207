<template>
  <div class="msg-container">
    <warning-animate />
    {{ login.formErrMsg }}
  </div>
</template>

<script>
import WarningAnimate from '@/components/WarningAnimate'

export default {
  components: {
    WarningAnimate
  },
  props: {
    login: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateLogin (obj) {
      this.$emit('updateLogin', obj)
    }
  },
  created () {
    setTimeout(() => {
      this.updateLogin({
        formType: null
      })
    }, 5000)
  }
}
</script>

<style lang="scss" scoped>
.msg-container {
  margin-top: -20px;
  color: #EB5757;
  font-size: 18px;
  font-weight: 500;
}
</style>
