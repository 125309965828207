import { getCookie, rmCookie } from './cookie'
import { loginCookie } from '@/constants/login'

export function isAdminPage (to) {
  if (to.meta.isAdminPage) {
    return true
  }
  return false
}

export function isAdminLogin () {
  return getCookie(loginCookie.admin)
}

export function adminLogout () {
  rmCookie(loginCookie.admin)
}

export function isAdminApi (url) {
  const adminApiRegex = /\/api\/console/
  return adminApiRegex.test(url)
}

export function isMemberEditable (data) {
  return data.created_by === 'user'
}
