<template>
  <el-menu 
    :default-active="defaultActive" 
    router
    style="overflow-x: hidden;">
    <route-item
      v-for="(route, idx) in rootRoute.children"
      :key="'route' + idx"
      :route="route"
      :idx="String(idx)" />
  </el-menu>
</template>

<script>
import RouteItem from './RouteItem'
import { getRouteByName } from '@/utils/route'

export default {
  components: {
    RouteItem
  },
  data () {
    return {
      rootRoute: null,
      defaultActive: ''
    }
  },
  methods: {
    handleDefaultActive() {
      this.rootRoute.children.forEach(k => {
        if (location.href.includes(k.path))
          this.handleChild(k)
      })
    },
    handleChild(k) {
      if (k.path && !k.hasOwnProperty('children'))
        return this.defaultActive = k.path
      k.children.forEach(key => {
        if (location.href.includes(key.path))
          this.defaultActive = key.path
      })
    },
  },
  created () {
    this.rootRoute = getRouteByName('AdminRoot')
    this.handleDefaultActive()
  }
}
</script>

<style lang="scss" scoped>
.el-menu {
  height: inherit;
}
</style>
