import { chartLotHH } from './chart/lotHH'
import imgSHSSL from '@/assets/lot-logo/SHSSL.png'
import { remove } from 'lodash'

export const lotOther = [
  {
    id: 'SHSSL',
    name: '上海时时乐',
    logo: imgSHSSL,
    rule: {
      display: {
        uth: true
      },
      link: {
        history: true,
        positionTrend: true,
        prediction: true
        // LSHistory: true
      },
      chart: {
        ...chartLotHH,
        headArr: ['百位', '十位', '个位'],
        trend: remove([...chartLotHH.trend], (obj) => {
          return obj.id !== 'dtTrend' && obj.id !== 'xtTrend'
        })
      }
    }
  }
]
