import { lotHH } from './allLots/lotHH'
import { lotPK10 } from './allLots/lotPK10'
import { lot11P5 } from './allLots/lot11P5'
import { lotQ3 } from './allLots/lotQ3'
import { happy81012 } from './allLots/happy81012'
// import { lotMM } from './allLots/lotMM'
import { lotNation } from './allLots/lotNation'
import { lotOversea } from './allLots/lotOversea'
import { lotOther } from './allLots/lotOther'

export const allLots = [
  {
    context: '時時、分分彩', // context: '时时彩',
    id: 'LotHH',
    children: lotHH
  },
  // {
  //   context: '分分彩',
  //   id: 'LotMM',
  //   children: lotMM
  // },
  {
    context: 'PK10/飞艇',
    id: 'LotPK10',
    children: lotPK10
  },
  {
    context: '11选5',
    id: 'Lot11P5',
    children: lot11P5.map(item => {
      return {
        ...item,
        name: item.name + '11选5'
      }
    })
  },
  {
    context: '快3',
    id: 'LotQ3',
    children: lotQ3
  },
  {
    context: '快乐10/12',
    id: 'Happy81012',
    children: happy81012
  },
  {
    context: '全国彩系列',
    id: 'LotNation',
    children: lotNation
  },
  {
    context: '境外彩系列',
    id: 'LotOversea',
    children: lotOversea
  },
  {
    context: '其他彩票',
    id: 'LotOther',
    children: lotOther
  }
]
