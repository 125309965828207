<template>
  <div class="mc-input-container">
    <div
      v-if="showInvalid"
      class="invalid-container">
      {{ invalidFeedback }}
    </div>
    <input
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
      class="mc-input"
      :class="invalidClass"
      :placeholder="placeholder"
      :type="type" />
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    state: {
      type: Boolean
    },
    'invalid-feedback': {
      type: String
    },
    placeholder: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  computed: {
    isInvalid: {
      get () {
        return (
          this.invalidFeedback &&
          this.state === false
        )
      }
    },
    showInvalid: {
      get () {
        if (this.isInvalid) {
          return true
        }
        return false
      }
    },
    invalidClass: {
      get () {
        if (this.isInvalid) {
          return 'is-invalid'
        }
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mc-input-container {
  width: 100%;
  min-height: 73px;
  height: 73px;
  position: relative;
  .invalid-container {
    width: 100%;
    text-align: left;
    color: #FF3D00;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
  }
  .invalid-container::before {
    content: 'i';
    display: inline-block;
    width: 13px;
    height: 13px;
    line-height: 13px;
    border-radius: 50%;
    background-color: #FF3D00;
    color: #FFFFFF;
    text-align: center;
    vertical-align: baseline;
    font-size: 9px;
    font-weight: bold;
  }
  .mc-input {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    // height: calc(1.5em + 0.75rem + 2px);
    height: 50px;
    // padding: 0.375rem 0.75rem;
    padding: 14px 18px;
    // font-size: 1rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 6px;
  }
  .mc-input.is-invalid {
    border: 1px solid #FF3D00;
  }
}
@media (max-width: 320.98px) {
  .mc-input-container {
    min-height: 66px;
    height: 66px;
    .invalid-container {
      font-weight: 400;
    }
    .mc-input{
      height: 45px;
      padding: 10px 12px;
      border: 1px solid #9B9B9B;
      // font-size: clamp(12px, calc((100vw - 60px - 2px - 25px) / 18), 16px);
      &::placeholder{
        color: #A7A7A7;
        font-size: clamp(12px, calc((100vw - 60px - 2px - 25px) / 18), 16px);
      }
    }
  }
}
@media (min-width: 321px) and (max-width: 575.98px) {
  .mc-input-container {
    .invalid-container {
      font-weight: 400;
    }
    .mc-input{
      padding: 14px 12px;
      border: 1px solid #9B9B9B;
      &::placeholder{
        color: #A7A7A7;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mc-input-container {
    .invalid-container {
      font-weight: 400;
    }
    .mc-input{
      height: 45px;
      padding: 13px 16px;
      font-size: 15px;
      border: 1px solid #9B9B9B;
      &::placeholder{
        color: #A7A7A7;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mc-input-container {
    min-height: 66px;
    height: 66px;
    .mc-input {
      height: 45px;
      padding: 13px 16px;
      font-size: 15px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {}
</style>
