<template>
  <div 
    v-if="!detailVisable"
    class="message-container" 
    :class="!isMobile ? 'card-container': ''">
    <div 
      class="title-container"
      v-if="title">
      <h2>我的消息</h2>
    </div>
    <div 
      class="content-container" 
      @scroll="onScroll"
      id="scrollToMe">
      <div v-if="messageList.length > 0">
        <div 
          class="content-item" 
          v-for="(item, index) in messageList" 
          :key="index"
          @click="handleDetail(item)">
          <div class="title"> {{ item.title }}</div>
          <div class="time">{{ parseTime(item.updated_at) }}</div>
          <div class="content">{{ item.content }}</div>
        </div>
        <div class="no-more-title">没有更多消息啦</div>
      </div>
      <div v-if="messageList.length === 0">
        <img class="no-data-img" src="@/assets/Waiting.png">
        <div class="no-data-content">你没有收到消息噢～</div>
      </div>
    </div>
  </div>
  <MessageDetail 
    v-else
    :login="login"
    :messageDetail="messageDetail"
    @handleBack="handleBack" />
</template>

<script>
import resStatus from '@/constants/resStatus'
import { isMobile } from "@/utils/device"
import { getMessageList } from '@/api/client/message'
import MessageDetail from '@/views/Member/Message/MessageDetail'

export default {
  props: {
    login: {
      type: Object,
      required: true
    },
    title: {
      default: true,
    }
  },
  components: {
    MessageDetail,
  },
  computed: {
    isMobile () {
      return isMobile()
    }
  },
  data () {
    return {
      messageDetail: null,
      detailVisable: false,
      messageList: [],
      totalPages: null,
      query: {
        page: 1,
        pageSize: 20,
        type: 'customer_service'
      },
      scrollTop: 0,
    }
  },
  created() {
    this.handleData()
  },
  methods: {
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      this.scrollTop = scrollTop || 0
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.query.page < this.totalPages) {
          this.query.page += 1
          getMessageList(this.query).then(res => {
            switch (res.status) {
              case resStatus.OK:
                if (res.data.length > 0) {
                  res.data.forEach(res => {
                    this.messageList.push(res)
                  })
                } else {
                  // this.emptyEnable = true
                }
                break
              default:
                this.$message.error(res.message)
            }
          }).catch(err => {
            this.$message.error(err)
          })
        } else {
          // this.emptyEnable = true
        }
      }
    },
    handleBack(bool) {
      this.detailVisable = bool
      this.handleScroll()
      // window.scrollTo({top: this.scrollTop, left: 0, behavior: 'smooth'})
    },
    handleDetail(item) {
      this.messageDetail = item
      this.detailVisable = true
      // window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    },
    parseTime(date) {
      let time = new Date(date.replace(/-/g, '/')).getTime() / 1000
      let timeNow = Date.now() / 1000
      let remaining = Math.floor(timeNow - time)
      if (remaining < 60) return '剛剛'
      if (remaining > 60 && remaining < 3600)
        return `${Math.floor(remaining / 60)}分鐘前`
      if (remaining > 3600 && remaining < 86400)
        return `${Math.floor(remaining / 3600)}小時前`
      return date
    },
    handleScroll() {
      this.$nextTick(() => {
        document.querySelector('#scrollToMe').scrollTop = this.scrollTop
      })
    },
    handleData() {
      getMessageList(this.query).then(res => {
        switch (res.status) {
          case resStatus.OK:
            if (res.data.length > 0) {
              this.messageList = res.data
              this.totalPages = res.totalPages
              this.handleScroll()
            } else {
              // this.emptyEnable = true
            }
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.no-data-img {
  width: 300px;
}
.no-data-content {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 50px;
}
.message-container {
  background-color: #FFF;
  .title-container {
    padding: 18px 30px;
    text-align: left;
    border-bottom: 1px solid #E0E0E0;
    h2 {
      font-weight: 500;
      font-size: 21px;
    }
  }
  .content-container {
    .no-more-title {
      text-align: center;
      margin: 15px 0;
      color: #999;
    }
    overflow-y: auto;
    height: 80vh;
    padding: 0 30px 20px;
    .content-item {
      cursor: pointer;
      padding: 25px 20px 15px 0;
      text-align: left;
      border-bottom: 1px #E0E0E0 solid;
      .title {
        font-size: 15px;
        font-weight: 800;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .time {
        margin: 5px 0;
        font-size: 12px;
        color: #999;
      }
      .content {
        font-size: 13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .no-data-img {
    width: 220px;
    margin: 73px auto 23px;
  }
  .no-data-content {
    font-weight: 400;
    font-size: 18px;
  }
  .message-container {
    height: auto;
    min-height: inherit;
    .title-container {
      border-bottom: none;
      background: #56CCF2;
      h2 {
        color: #FFF;
        text-align: center;
      }
    }
    .content-container {
      height: auto;
      padding: 0 15px 20px;
      .content-item {
        padding: 15px 0;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .no-data-img {
    width: 220px;
    margin: 73px auto 23px;
  }
  .no-data-content {
    font-weight: 400;
    font-size: 18px;
  }
  .message-container {
    height: auto;
    min-height: inherit;
    .title-container {
      border-bottom: none;
      background: #56CCF2;
      h2 {
        color: #FFF;
        text-align: center;
      }
    }
    .content-container {
      height: auto;
      padding: 0 15px 20px;
      .content-item {
        padding: 15px 0;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .no-data-img {
    width: 280px;
    margin: 67px auto 23px;
  }
  .no-data-content {
    font-weight: 400;
    font-size: 18px;
  }
  .message-container {
    height: auto;
    min-height: inherit;
    .content-container {
      height: auto;
      padding: 0 15px 20px;
      .content-item {
        padding: 15px 0;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
}
</style>
