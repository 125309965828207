<template>
  <div class="main-head" :style="{ 'background-image': 'url(' + DefaultBg + ')' }">
    <div class="thumbnail-container">
      <div
        @click="handleBtnChangeImg"
        class="photo-container"
        :class="photo == '' ? 'no-photo' : ''"
        :style="{ 'background-image': `url('${$common.resSrc(photo)}')` }"
      >
        <!-- <img v-if="photo !== ''" :src="photo" /> -->
        <b-icon v-if="photo == ''" icon="person-fill"></b-icon>
        <!-- <img :src="uploadPhoto" /> -->
        <input
          style="display: none"
          ref="uploadImg"
          type="file"
          accept="image/*"
          @change="uploadImg($event)"
        />
      </div>
      <div class="name-container">
        <div class="username-container">
          <p class="username">{{ username }}</p>
          <div class="level">Lv.{{ level }}</div>
        </div>
        <p class="nickname">{{ showCategory(category) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
// import demoUpload from '@/views/Member/Info/index'
import resStatus from '@/constants/resStatus'
import { postHeadshot } from '@/api/client/member'
import DefaultBg from '@/assets/member/member-default-bg.png'
import { categoryType } from '@/constants/member'

export default {
  props: {
    login: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      DefaultBg,
    }
  },
  computed: {
    photo: {
      get() {
        if (this.hasData()) {
          return this.login.data.photo
        }
        return ''
      },
    },
    username: {
      get() {
        if (this.hasData()) {
          return this.login.data.username
        }
        return ''
      },
    },
    level: {
      get() {
        if (this.hasData()) {
          return this.login.data.level
        }
        return ''
      },
    },
    category: {
      get() {
        if (this.hasData()) {
          return this.login.data.category
        }
        return null
      },
    },
  },
  methods: {
    hasData() {
      return this.login.data
    },
    handleBtnChangeImg() {
      this.$refs.uploadImg.click()
    },
    uploadImg(event) {
      postHeadshot(event.target.files[0])
        .then((res) => {
          switch (res.status) {
            case resStatus.OK:
              this.$emit('refreshLoginData')
              break
            default:
              this.$message.error(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    showCategory(type) {
      if (type) {
        return categoryType[type]
      }
    },
  },
  watch: {
    login() {},
  },
}
</script>

<style lang="scss" scoped>
.main-head {
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  // filter: grayscale(60%);
  // background: linear-gradient(78.86deg, #0053FF 6.92%, #579FFF 91.5%);
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    // backdrop-filter: blur(1px);
    top: 0;
    left: 0;
    z-index: 1;
  }
  .thumbnail-container {
    position: relative;
    width: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    .photo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #c2d6ff;
      border: 2px solid #f2c94c;
      background-size: cover;
      background-position: center;
      overflow: hidden;
      cursor: pointer;
      // img {
      //   width: 100%;
      //   height: 100%;
      //   border-radius: 50%;
      // }
    }
    .no-photo {
      background-color: #c2d6ff;
      .b-icon {
        font-size: 58px;
        color: #ffffff;
      }
    }
    // .photo-container:hover {
    //   filter: grayscale(100%);
    // }
    .name-container {
      color: #ffffff;
      display: flex;
      flex-direction: column;
      text-align: left;
      .username-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .level {
          width: fit-content;
          height: fit-content;
          background: linear-gradient(342.51deg, #917225 15.18%, #bba058 51.5%, #c8ad67 80.7%);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .main-head {
    height: 16vh;
    min-height: 130px;
    .thumbnail-container {
      top: 5.5vh;
      left: 20px;
      // gap: 25px;
      .photo-container {
        width: 60px;
        height: 60px;
        vertical-align: middle;
      }
      .name-container {
        // gap: 5px;
        margin-left: 10px;
        vertical-align: middle;
        .username-container {
          // gap: 10px;
          margin-bottom: 4px;
          .username {
            font-weight: 500;
            font-size: 16px;
            margin-right: 10px;
          }
          .level {
            font-size: 12px;
            padding: 2px 9px;
            border-radius: 50px;
          }
        }
        .nickname {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-head {
    height: 16vh;
    min-height: 130px;
    .thumbnail-container {
      top: 5.5vh;
      left: 20px;
      // gap: 25px;
      .photo-container {
        width: 60px;
        height: 60px;
        vertical-align: middle;
      }
      .name-container {
        // gap: 5px;
        margin-left: 10px;
        vertical-align: middle;
        .username-container {
          // gap: 10px;
          margin-bottom: 4px;
          .username {
            font-weight: 500;
            font-size: 16px;
            margin-right: 10px;
          }
          .level {
            font-size: 12px;
            padding: 2px 9px;
            border-radius: 50px;
          }
        }
        .nickname {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-head {
    height: 200px;
    .thumbnail-container {
      top: 75px;
      left: 6.92vw;
      // gap: 25px;
      .photo-container {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
      .name-container {
        // gap: 5px;
        .username-container {
          margin-bottom: 5px;
          // gap: 10px;
          .username {
            font-weight: 500;
            font-size: 21px;
            margin-right: 10px;
          }
          .level {
            font-size: 12px;
            padding: 2px 9px;
            border-radius: 50px;
          }
        }
        .nickname {
          font-size: 14px;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-head {
    height: 200px;
    .thumbnail-container {
      top: 75px;
      left: 6.92vw;
      // gap: 25px;
      .photo-container {
        width: 90px;
        height: 90px;
        margin-right: 25px;
      }
      .name-container {
        // gap: 5px;
        .username-container {
          // gap: 10px;
          margin-bottom: 5px;
          .username {
            font-weight: 500;
            font-size: 24px;
            margin-right: 10px;
          }
          .level {
            font-size: 12px;
            padding: 2px 9px;
            border-radius: 50px;
          }
        }
        .nickname {
          font-size: 14px;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .main-head {
    height: 200px;
    .thumbnail-container {
      top: 75px;
      left: 83px;
      // gap: 25px;
      .photo-container {
        width: 90px;
        height: 90px;
        margin-right: 25px;
      }
      .name-container {
        // gap: 5px;
        .username-container {
          // gap: 10px;
          margin-bottom: 5px;
          .username {
            font-weight: 500;
            font-size: 24px;
            margin-right: 10px;
          }
          .level {
            font-size: 12px;
            padding: 2px 9px;
            border-radius: 50px;
          }
        }
        .nickname {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
