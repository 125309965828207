import { allAnalysis } from './analysis'
import { allTrend } from './trend'

const chartAnalysis = ['numAnalysis', 'historyNum'] // , 'lzAnalysis', 'hcAnalysis'
const chartTrend = ['basicTrend', 'dyTrend', 'hzTrend'] // , 'dsTrend', 'zhTrend'
const headArr = ['百位', '十位', '个位']

export const chartLotNation = {
  analysis: allAnalysis.filter(item => chartAnalysis.includes(item.id)),
  trend: allTrend.filter(item => chartTrend.includes(item.id)),
  headArr: headArr,
  resultMinNum: 0,
  resultMaxNum: 9,
  resultFillZero: false,
  isResultRepeat: true
}

export const chartLot3D = {
  ...chartLotNation,
  analysis: allAnalysis.filter(item => {
    return [...chartAnalysis].includes(item.id) // , 'dslsHistory'
  })
}

export const chartTCPL5 = {
  ...chartLotNation,
  headArr: ['万位', '千位', '百位', '十位', '个位']
}

const chartXGAnalysis = ['historyNum']
const chartXGTrend = []
export const chartLotXG = {
  ...chartLotNation,
  analysis: allAnalysis.filter(item => chartXGAnalysis.includes(item.id)),
  trend: allTrend.filter(item => chartXGTrend.includes(item.id))
}
