import { chartLotOversea, chartJND3D, chartLotRD, chartLotMNG } from './chart/lotOversea'
import imgHN300 from '@/assets/lot-logo/HN300.png'
import imgHN60 from '@/assets/lot-logo/HN60.png'
import imgjnd30s from '@/assets/lot-logo/JND30S.png'
import imgjnd3d from '@/assets/lot-logo/JND3D.png'
import imgjnd11x5 from '@/assets/lot-logo/JND11X5.png'
import imgYN300 from '@/assets/lot-logo/YN300.png'
import imgYN60 from '@/assets/lot-logo/YN60.png'
import imgRB60 from '@/assets/lot-logo/RB60.png'
import imgTG60 from '@/assets/lot-logo/TG60.png'
import imgTG300 from '@/assets/lot-logo/TG300.png'
import imgTG11X5 from '@/assets/lot-logo/TG11X5.png'
import imgXG60 from '@/assets/lot-logo/XG60.png'
import imgXG300 from '@/assets/lot-logo/XG300.png'
import imgRD60 from '@/assets/lot-logo/RD60.png'
import imgRD120 from '@/assets/lot-logo/RD120.png'
import imgMNG52 from '@/assets/lot-logo/MNG52.png'
import imgjssm from '@/assets/lot-logo/JSSM.png'
import { remove } from 'lodash'
import { chartLotHH } from './chart/lotHH'
import { chartLot11P5 } from './chart/lot11P5'
import { chartLotPK10 } from './chart/lotPK10'

const duplicateChart = JSON.parse(JSON.stringify(chartLotOversea))
// remove analysis: numAnalysis
remove(duplicateChart.analysis, (item) => {
  return item.id === 'numAnalysis'
})

export const lotOversea = [
  {
    id: 'HN300',
    name: '河内五分彩',
    logo: imgHN300,
    rule: {
      display: {
        total: true,
        dg: true,
        mf3: true,
        mm3: true,
        ml3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotHH
    }
  },
  {
    id: 'HN60',
    name: '河内分分彩',
    logo: imgHN60,
    rule: {
      display: {
        total: true,
        dg: true,
        mf3: true,
        mm3: true,
        ml3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotHH
    }
  },
  {
    id: 'JND30S',
    name: '加拿大30秒',
    logo: imgjnd30s,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotHH
    }
  },
  {
    id: 'JND3D',
    name: '加拿大3D',
    logo: imgjnd3d,
    rule: {
      display: {
        uth: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartJND3D
    }
  },
  {
    id: 'JND11X5',
    name: '加拿大11选5',
    logo: imgjnd11x5,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLot11P5
    }
  },
  {
    id: 'YN300',
    name: '印尼五分彩',
    logo: imgYN300,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotHH
    }
  },
  {
    id: 'YN60',
    name: '印尼分分彩',
    logo: imgYN60,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotHH
    }
  },
  {
    id: 'RB60',
    name: '日本分分彩',
    logo: imgRB60,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotHH
    }
  },
  {
    id: 'TG60',
    name: '泰国60秒',
    logo: imgTG60,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotHH
    }
  },
  {
    id: 'TG300',
    name: '泰国300秒',
    logo: imgTG300,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotHH
    }
  },
  {
    id: 'TG11X5',
    name: '泰国11选5',
    logo: imgTG11X5,
    rule: {
      display: {
        total: true,
        mf3: true,
        mm3: true,
        ml3: true
      },
      link: {
        history: true,
        trend: true,
        prediction: true
        // LSHistory: true
      },
      chart: chartLot11P5
    }
  },
  {
    id: 'XG60',
    name: '西贡分分彩',
    logo: imgXG60,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotHH
    }
  },
  {
    id: 'XG300',
    name: '西贡五分彩',
    logo: imgXG300,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotHH
    }
  },
  {
    id: 'RD60',
    name: '瑞典1分彩',
    logo: imgRD60,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotRD
    }
  },
  {
    id: 'RD120',
    name: '瑞典2分彩',
    logo: imgRD120,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotRD
    }
  },
  {
    id: 'MNG52',
    name: '摩纳哥Super 52',
    logo: imgMNG52,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: chartLotMNG
    }
  },
  {
    id: 'JSSM',
    name: '急速赛马',
    logo: imgjssm,
    rule: {
      display: {
        total: true,
        dg: true,
        f3: true,
        m3: true,
        l3: true
      },
      link: {
        history: true,
        trend: true
        // LSHistory: true
      },
      chart: {
        ...chartLotPK10,
        analysis: remove([...chartLotPK10.analysis], (obj) => {
          return obj.id !== 'numAnalysis'
        })
      }
      // {
      //   ...chartLotPK10,
      //   ...duplicateChart
      // }
    }
  }
]
