import { allAnalysis } from './analysis'
import { allTrend } from './trend'

const chartAnalysis = ['numAnalysis', 'historyNum'] // , 'dslsHistory'
const chartTrend = ['basicTrend', 'dyTrend']
const headArr = ['第一位', '第二位', '第三位', '第四位', '第五位', '第六位', '第七位', '第八位']

export const chartHappy81012 = {
  analysis: allAnalysis.filter(item => chartAnalysis.includes(item.id)),
  trend: allTrend.filter(item => chartTrend.includes(item.id)),
  headArr: headArr,
  resultMinNum: 1,
  resultMaxNum: 20,
  resultFillZero: true
}
