import request from '@/utils/axios'
import { getFormData, objToUrlQuery } from '@/utils/axiosTools'

// 取得商店
export function getMyShop() {
  return request({
    url: '/api/users/me/shops',
    method: 'get',
  })
}

// 新建商店
export function createMyShop(data) {
  return request({
    url: '/api/users/me/shops',
    method: 'post',
    data: getFormData(data),
  })
}

// 修改商店
export function modifyMyShop(data) {
  const id = data.id
  delete data.id
  return request({
    url: `/api/users/me/shops/${id}`,
    method: 'post',
    data: getFormData(data),
  })
}

// 取得彩種列表
export function getLotteries() {
  return request({
    url: `/api/lotteries`,
    method: 'get',
  })
}

// 獲得彩種期號
export function getLotteryIssues(lotType) {
  return request({
    url: `/api/lottery_issues?code=${lotType}`,
    method: 'get',
  })
}

// 預測獎號
export function predictLot(data) {
  const id = data.id
  delete data.id
  return request({
    url: `/api/users/me/shops/${id}/predict`,
    method: 'post',
    data: getFormData(data),
  })
}

// 取得彩種分類(大神有預測)
export function getLotteryTypeWithPredict() {
  return request({
    url: `/api/users/me/lottery_types`,
    method: 'get',
  })
}

// 取得單一彩種預測獎號或預測歷史獎號
export function getPredictHistory(data) {
  const id = data.id
  delete data.id
  return request({
    url: `/api/users/me/shops/${id}/predict?${objToUrlQuery(data)}`,
    method: 'get',
  })
}

export function syncPredictOnline() {
  return request({
    url: `/api/visits?to=god_predict_online`,
    method: 'get',
  })
}

// 取得專家單一預測號
export function getExpertProfile(data) {
  return request({
    url: `/api/god/shops/${data.id}`,
    method: 'get',
  })
}
