<template>
  <div class="app-footer">
    <div class="footer-container">
      <div class="footer-main">
        <footer-routes />
        <footer-contact />
      </div>
    </div>
    <footer-copyright />
  </div>
</template>

<script>
import FooterRoutes from './Routes'
import FooterContact from './Contact'
import FooterCopyright from './Copyright'

export default {
  name: 'MCFooter',
  components: {
    FooterRoutes,
    FooterContact,
    FooterCopyright
  }
}
</script>

<style lang="scss" scoped>
.app-footer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1A2342;
  // position: relative;
  // z-index: 99;
  .footer-container {
    .footer-main {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: #FAFAFA;
      background-color: #1A2342;
    }
  }
}

@media (max-width: 575.98px) {
  .app-footer {
    .footer-container {
      width: 100%;
      .footer-main {
        height: 65px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .app-footer {
    .footer-container {
      width: 100%;
      .footer-main {
        height: 65px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .app-footer {
    .footer-container {
      width: 100%;
      .footer-main {
        height: 160px;
        padding: 30px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .app-footer {
    .footer-container {
      width: 100%;
      .footer-main {
        height: 224px;
        padding: 30px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .app-footer {
    .footer-container {
      width: 1200px;
      .footer-main {
        height: 224px;
        padding: 30px;
      }
    }
  }
}
</style>
