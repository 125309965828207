<template>
  <div class="app-container">
    <header class="app-header">
      <Header :login="login" @updateLogin="updateLogin" />
    </header>
    <div class="app-main">
      <Main-mobile v-if="isMobile" @refreshLoginData="refreshLoginData" :login="login" />
      <Main v-else @refreshLoginData="refreshLoginData" :login="login" />
    </div>
    <div class="app-footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './Header'
import Main from './Main/index'
import MainMobile from './Main/mobileIndex'
import Footer from '@/views/Layout/Footer'
// import { getCookie } from '@/utils/cookie'
import layout from '@/views/Layout'
// import { getMe } from '@/api/client/member'
// import resStatus from '@/constants/resStatus'

export default {
  components: {
    Header,
    Main,
    MainMobile,
    Footer
  },
  data() {
    return {
      ...layout.data()
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisiable)
  },
  destroyed() {
    document.removeEventListener('visibilitychange', this.handleVisiable)
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile
    }
  },
  methods: {
    ...layout.methods
  },
  created() {
    this.checkLogin()
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .app-header {
    width: 100vw;
    position: relative;
    z-index: 99;
  }
  .app-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f3f9ff;
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .app-container {
    .app-main {
      height: auto;
      min-height: calc(
        100vh - 52px - 65px - 35px
      ); //NavHeight:52px, Footer: 65px, CopyRight: 35px
      justify-content: flex-start;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .app-container {
    .app-main {
      height: auto;
      min-height: calc(
        100vh - 52px - 65px - 35px
      ); //NavHeight:52px, Footer: 65px, CopyRight: 35px
      justify-content: flex-start;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
}
</style>
