const mainRoutes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home'),
    meta: {
      context: '首頁',
    },
  },
  {
    path: '/lotteryResult',
    name: 'LotteryResult',
    component: () => import('@/views/LotteryResult'),
    meta: {
      context: '开奖查询',
    },
  },
  {
    path: '/lotteryChart',
    name: 'LotteryChart',
    component: () => import('@/views/LotteryChart'),
    meta: {
      context: '走势图表',
    },
    children: [
      {
        path: '/lotteryChart/detail',
        name: 'LotteryChartDetail',
        component: () => import('@/views/LotteryChart/Detail'),
        hidden: true,
        meta: {
          context: '彩種走勢',
        },
      },
    ],
  },
  // {
  //   path: '/godPlay',
  //   name: 'GodPlay',
  //   redirect: '/godPlay/latestSolution',
  //   component: () => import('@/views/GodPlay'),
  //   meta: {
  //     context: '大神带玩',
  //   },
  //   children: [
  //     {
  //       path: 'latestSolution',
  //       name: 'LatestSolution',
  //       component: () => import('@/views/GodPlay/RecommendAndLatest/LatestSolution'),
  //       hidden: true,
  //       meta: {
  //         context: '最新彩票方案',
  //       },
  //     },
  //     {
  //       path: 'expertsRecommend',
  //       name: 'ExpertsRecommend',
  //       component: () => import('@/views/GodPlay/RecommendAndLatest/ExpertsRecommend'),
  //       hidden: true,
  //       meta: {
  //         context: '专家推荐',
  //       },
  //     },
  //     {
  //       path: 'onlinePredict',
  //       name: 'OnlinePredict',
  //       component: () => import('@/views/GodPlay/OnlinePredict'),
  //       hidden: true,
  //       meta: {
  //         context: '线上预测',
  //       },
  //     },
  //     {
  //       path: 'predictHistory',
  //       name: 'PredictHistory',
  //       component: () => import('@/views/GodPlay/PredictHistory'),
  //       hidden: true,
  //       meta: {
  //         context: '专家预测页_历史',
  //       },
  //     },
  //     {
  //       path: 'expertProfile/:id',
  //       name: 'ExpertProfile',
  //       component: () => import('@/views/GodPlay/ExpertProfile'),
  //       hidden: true,
  //       meta: {
  //         context: '商店',
  //       },
  //     },
  //   ],
  // },
  {
    path: '/lotteryInfo',
    name: 'LotteryInfo',
    component: () => import('@/views/LotteryInfo'),
    meta: {
      context: '彩票资讯',
    },
    children: [
      {
        path: '/lotteryInfo/lotteryInfoDetail',
        name: 'LotteryInfoDetail',
        hidden: true,
        component: () => import('@/views/LotteryInfo/lotteryInfoDetail'),
        meta: {
          context: '彩票資訊內頁',
        },
      },
    ],
  },
  {
    path: '/SportsGames',
    name: 'SportsGames',
    component: () => import('@/views/SportsGames'),
    meta: {
      context: '体育赛事',
    },
  },
  {
    path: '/lotteryIntro',
    name: 'LotteryIntro',
    component: () => import('@/views/LotteryIntro'),
    meta: {
      context: '彩种介绍',
    },
  },
  {
    path: '/welfare',
    name: 'Welfare',
    component: () => import('@/views/Welfare'),
    meta: {
      context: '专属福利',
    },
    children: [
      {
        path: '/welfare/detail',
        name: 'WelfareDetail',
        component: () => import('@/views/Welfare/WelfareDetail'),
        hidden: true,
        meta: {
          context: '专属福利內頁',
        },
      },
    ],
  },
  {
    path: '/helpCenter',
    name: 'HelpCenter',
    component: () => import('@/views/HelpCenter'),
    meta: {
      context: '帮助中心',
    },
  },
  {
    path: '/clause',
    name: 'Clause',
    component: () => import('@/views/Clause'),
    hidden: true,
    meta: {
      context: '多彩网服务条款',
    },
  },
  {
    path: '/download-app',
    name: 'Download',
    component: () => import('@/views/DownloadAppPage'),
    hidden: true,
    meta: {
      context: '多彩网服务条款',
    },
  },
]

export default mainRoutes
