import { chartLotNation, chartLot3D, chartTCPL5, chartLotXG } from './chart/lotNation'
import imgFC3D from '@/assets/lot-logo/FC3D.png'
import imgTCPL3 from '@/assets/lot-logo/TCPL3.png'
import imgTCPL5 from '@/assets/lot-logo/TCPL5.png'
import imgXGLHC from '@/assets/lot-logo/XGLHC.png'
import { remove } from 'lodash'

const duplicateChart = JSON.parse(JSON.stringify(chartLotNation))
// remove analysis: numAnalysis
remove(duplicateChart.analysis, (item) => {
  return item.id === 'numAnalysis'
})

export const lotNation = [
  {
    id: 'FC3D',
    name: '福彩3D',
    logo: imgFC3D,
    rule: {
      display: {
        uth: true
      },
      link: {
        history: true,
        trend: true,
        prediction: true
        // LSHistory: true
      },
      chart: chartLot3D
    }
  },
  {
    id: 'TCPL3',
    name: '体彩P3',
    logo: imgTCPL3,
    rule: {
      display: {
        total: true
      },
      link: {
        history: true,
        trend: true,
        prediction: true
      },
      chart: chartLotNation
    }
  },
  {
    id: 'TCPL5',
    name: '体彩排列5',
    logo: imgTCPL5,
    rule: {
      display: {
        total: true
      },
      link: {
        history: true,
        trend: true,
        prediction: true
      },
      chart: chartTCPL5
    }
  },
  {
    id: 'XGLHC',
    name: '香港六合彩',
    logo: imgXGLHC,
    rule: {
      display: {
        // total: true
      },
      link: {
        history: true
      },
      chart: chartLotXG
    }
  }
]
