export const allTrend = [
  {
    id: 'basicTrend',
    context: '基本走势'
  },
  {
    id: 'dyTrend',
    context: '定位走势'
  },
  {
    id: 'dtTrend',
    context: '龙虎走势'
  },
  {
    id: 'xtTrend',
    context: '形态走势'
  },
  {
    id: 'numTrend',
    context: '号码走势'
  },
  {
    id: 'championTrend',
    context: '冠亚和走势'
  },
  {
    id: 'hzTrend',
    context: '和值走势'
  },
  {
    id: 'oeTrend',
    context: '奇偶走势'
  },
  {
    id: 'lsTrend',
    context: '大小走势'
  },
  {
    id: 'dsTrend',
    context: '单双走势'
  },
  {
    id: 'zhTrend',
    context: '质合走势'
  },
  {
    id: 'ztTrend',
    context: '型态走势'
  }
]
