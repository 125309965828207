<template>
  <div>
    <!-- <div class="side">
      <side-bar />
    </div>
    <div class="main">
      <div class="main-head">
      </div>
      <div class="main-content">
        <router-view />
      </div>
    </div> -->
    <el-container>
      <el-aside width="200px">
        <side-bar />
      </el-aside>
      <el-container>
        <el-header height="40px">
          <head-nav />
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import SideBar from './SideBar'
import HeadNav from './HeadNav'

export default {
  components: {
    SideBar,
    HeadNav
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  height: 100vh;
  .el-aside {
    height: inherit;
  }
}
</style>
