import { lotMM } from './lotMM'
import { chartLotHH } from './chart/lotHH'
import imgCQSSC from '@/assets/lot-logo/CQSSC.png'
import imgXJSSC from '@/assets/lot-logo/XJSSC.png'
import imgTJSSC from '@/assets/lot-logo/TJSSC.png'
import imgJDCQSSC from '@/assets/lot-logo/JDCQSSC.png'

const lotHHBasic = [
  {
    id: 'CQSSC',
    name: '欢乐生肖',
    logo: imgCQSSC
  },
  {
    id: 'XJSSC',
    name: '新疆时时彩',
    logo: imgXJSSC
  },
  {
    id: 'TJSSC',
    name: '天津时时彩',
    logo: imgTJSSC
  },
  {
    id: 'JDCQSSC',
    name: '经典重庆时时彩',
    logo: imgJDCQSSC
  }
]

const lotHHRuleTemplate = {
  display: {
    total: true,
    dg: true,
    f3: true,
    m3: true,
    l3: true
  },
  link: {
    history: true,
    trend: true,
    prediction: true
    // LSHistory: true
  },
  chart: chartLotHH
}

const lotHHTmp = lotHHBasic
  .map(lot => {
    return ({
      ...lot,
      rule: lotHHRuleTemplate
    })
  })

export const lotHH = [
  ...lotHHTmp,
  ...lotMM
]

// export const lotHH = [
//   {
//     id: 'CQSSC',
//     name: '欢乐生肖',
//     logo: imgCQSSC,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         mf3: true,
//         mm3: true,
//         ml3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLotHH
//     }
//   },
//   {
//     id: 'XJSSC',
//     name: '新疆时时彩',
//     logo: imgXJSSC,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         mf3: true,
//         mm3: true,
//         ml3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLotHH
//     }
//   },
//   {
//     id: 'TJSSC',
//     name: '天津时时彩',
//     logo: imgTJSSC,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         mf3: true,
//         mm3: true,
//         ml3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLotHH
//     }
//   },
//   {
//     id: 'JDCQSSC',
//     name: '经典重庆时时彩',
//     logo: imgJDCQSSC,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // LSHistory: true
//       },
//       chart: chartLotHH
//     }
//   },
//   ...lotMM
// ]
