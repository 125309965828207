<template>
  <div class="app-header">
    <header-head
      :login="login"
      @updateLogin="updateLogin" />
    <header-main />
    <header-nav
      @refreshView="refreshView" />
  </div>
</template>

<script>
import HeaderHead from './Head'
import HeaderMain from './Main'
import HeaderNav from './Nav'

export default {
  name: 'MCHeader',
  components: {
    HeaderHead,
    HeaderMain,
    HeaderNav
  },
  props: {
    login: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateLogin (obj) {
      this.$emit('updateLogin', obj)
    },
    refreshView (boo) {
      this.$emit('refreshView', boo)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  width: 100vw;
  // position: relative;
  // z-index: 99;
}
@media (max-width: 575.98px) {
  .app-header {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    background-color: #FAFAFA;
    z-index: 999;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .app-header {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    background-color: #FAFAFA;
    z-index: 999;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {}
</style>
