import { chartLotPK10 } from './chart/lotPK10'
import imgBJPK10 from '@/assets/lot-logo/BJPK10.png'
import imgXGPK10 from '@/assets/lot-logo/XGPK10.png'
import imgtxpk10 from '@/assets/lot-logo/TXPK10.png'
import imgMCPK10 from '@/assets/lot-logo/MCPK10.png'
import imgXIYFT from '@/assets/lot-logo/XIYFT.png'
import imgXYFT from '@/assets/lot-logo/XYFT.png'

const lotPK10Basic = [
  {
    id: 'BJPK10',
    name: '北京PK10',
    logo: imgBJPK10
  },
  {
    id: 'XGPK10',
    name: '香港PK10',
    logo: imgXGPK10
  },
  {
    id: 'TXPK10',
    name: '腾讯PK10',
    logo: imgtxpk10
  },
  {
    id: 'MCPK10',
    name: '极速赛车',
    logo: imgMCPK10
  },
  {
    id: 'XIYFT',
    name: '幸运飞艇',
    logo: imgXIYFT
  },
  {
    id: 'XYFT',
    name: '皇家幸运飞艇',
    logo: imgXYFT
  }
]

const lotPK10RulteTemplate = {
  display: {
    cr: true,
    dg5: true
  },
  link: {
    history: true,
    trend: true,
    prediction: true
    // CRTrend: true
  },
  chart: chartLotPK10
}

export const lotPK10 = lotPK10Basic
  .map(lot => {
    switch (lot.id) {
      case 'XGPK10':
      case 'MCPK10':
        return ({
          ...lot,
          rule: {
            ...lotPK10RulteTemplate,
            display: {
              total: true,
              dg: true,
              f3: true,
              m3: true,
              l3: true
            }
          }
        })
      default:
        return ({
          ...lot,
          rule: lotPK10RulteTemplate
        })
    }
  })

// export const lotPK10 = [
//   {
//     id: 'BJPK10',
//     name: '北京PK10',
//     logo: imgBJPK10,
//     rule: {
//       display: {
//         cr: true,
//         dg5: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // CRTrend: true
//       },
//       chart: chartLotPK10
//     }
//   },
//   {
//     id: 'XGPK10',
//     name: '香港PK10',
//     logo: imgXGPK10,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // CRTrend: true
//       },
//       chart: chartLotPK10
//     }
//   },
//   {
//     id: 'TXPK10',
//     name: '腾讯PK10',
//     logo: imgtxpk10,
//     rule: {
//       display: {
//         cr: true,
//         dg5: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // CRTrend: true
//       },
//       chart: chartLotPK10
//     }
//   },
//   {
//     id: 'MCPK10',
//     name: '极速赛车',
//     logo: imgMCPK10,
//     rule: {
//       display: {
//         total: true,
//         dg: true,
//         f3: true,
//         m3: true,
//         l3: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // CRTrend: true
//       },
//       chart: chartLotPK10
//     }
//   },
//   {
//     id: 'XIYFT',
//     name: '幸运飞艇',
//     logo: imgXIYFT,
//     rule: {
//       display: {
//         cr: true,
//         dg5: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // CRTrend: true
//       },
//       chart: chartLotPK10
//     }
//   },
//   {
//     id: 'XYFT',
//     name: '皇家幸运飞艇',
//     logo: imgXYFT,
//     rule: {
//       display: {
//         cr: true,
//         dg5: true
//       },
//       link: {
//         history: true,
//         trend: true,
//         prediction: true
//         // CRTrend: true
//       },
//       chart: chartLotPK10
//     }
//   }
// ]
