<template>
  <div class="info-container" :class="!isMobile ? 'card-container' : ''">
    <div class="title-container">
      <h2>我的资料</h2>
    </div>
    <div class="content-container">
      <!-- 头像 -->
      <div class="content-item">
        <label class="content-label">头像</label>
        <div class="content-info">
          <div class="info-text-img">
            <div
              v-if="login && photo"
              class="img-container"
              :style="{ 'background-image': `url('${$common.resSrc(photo)}')` }"
            >
              <!-- <img :src="this.photo" /> -->
            </div>
            <span class="img-tip tip">支持jpg、png、jpeg、bmp，图片大小5M以内</span>
          </div>
          <div @click="handleBtnChangeImg" class="info-edit"> 更换头像 </div>
          <!-- <div
            @click="handleBtnDelImg"
            class="info-edit">
            刪除頭像
          </div> -->
          <input
            style="display: none"
            ref="uploadImg"
            type="file"
            accept="image/*"
            @change="uploadImg($event)"
          />
        </div>
      </div>
      <!-- 昵称 -->
      <div class="content-item">
        <label class="content-label">昵称</label>
        <div class="content-info">
          <div class="info-text">
            <div v-if="!editNickname">{{ getContentText('nickname') }}</div>
            <div v-else class="edit-container">
              <input v-model="tmpData.nickname" />
              <div class="btn-container">
                <button class="btn btn-save" @click="handleBtnSave('nickname')"> 保存 </button>
                <button class="btn btn-cancel" @click="handleBtnCancel('nickname')"> 取消 </button>
              </div>
            </div>
          </div>
          <div class="info-edit" @click="handleTriggerEdit('nickname')" v-if="!editNickname">
            修改昵称
          </div>
        </div>
      </div>
      <!-- 我的密码 -->
      <div class="content-item">
        <label class="content-label">我的密码</label>
        <div class="content-info">
          <div class="info-text">
            <div v-if="!editPassword">********</div>
            <div v-else class="edit-container edit-container-pw">
              <input placeholder="请输入旧密码" v-model="queryPw.old_password" type="password" />
              <input placeholder="请输入新密码" v-model="queryPw.new_password" type="password" />
              <input
                placeholder="请再次输入新密码"
                v-model="queryPw.confirm_password"
                type="password"
              />
              <div class="btn-container">
                <button class="btn btn-save" @click="handleBtnSavePw()"> 保存 </button>
                <button class="btn btn-cancel" @click="handleBtnCancel('password')"> 取消 </button>
              </div>
            </div>
          </div>
          <div class="info-edit" @click="handleTriggerEdit('password')" v-if="!editPassword">
            修改密码
          </div>
        </div>
      </div>
      <!-- 手机号码 -->
      <!-- <div class="content-item">
        <label class="content-label">手机号码</label>
        <div class="content-info">
          <div :class="getContentClass('mobile')">{{ getContentText('mobile') }}</div>
          <div class="info-edit">绑定手机</div>
        </div>
      </div> -->
      <!-- 邮箱地址 -->
      <div class="content-item">
        <label class="content-label">邮箱地址</label>
        <div class="content-info">
          <div :class="getContentClass('email')">
            <div> {{ getContentText('email') }}<info-check v-if="isChecked('email')" /> </div>
          </div>
          <!-- <div class="info-edit">修改邮箱</div> -->
        </div>
      </div>
      <!-- QQ -->
      <div class="content-item">
        <label class="content-label">QQ</label>
        <div class="content-info">
          <div :class="getContentClass('qq')">
            <div v-if="!editQq">
              {{ getContentText('qq') }}<info-check v-if="isChecked('qq')" />
            </div>
            <div v-else class="edit-container">
              <input v-model="tmpData.qq" />
              <div class="btn-container">
                <button class="btn btn-save" @click="handleBtnSave('qq')"> 保存 </button>
                <button class="btn btn-cancel" @click="handleBtnCancel('qq')"> 取消 </button>
              </div>
            </div>
          </div>
          <div class="info-edit" @click="handleTriggerEdit('qq')" v-if="!editQq">
            {{ getEditText('qq') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoCheck from './Check'
import resStatus from '@/constants/resStatus'
import { postMe, postHeadshot, postModifyPass, delHeadshot } from '@/api/client/member'
import { capitalize } from 'lodash'
// import Nickname from './Nickname'
// import ContentUnit from './ContentUnit'
import { isMobile } from '@/utils/device'

export default {
  props: {
    login: {
      type: Object,
      required: true,
    },
  },
  components: {
    InfoCheck,
    // Nickname,
    // ContentUnit
  },
  computed: {
    photo: {
      get() {
        if (this.hasData('photo')) {
          return this.login.data.photo
        }
        return ''
      },
    },
    isMobile() {
      return isMobile()
    },
  },
  data() {
    return {
      // pageData: null,
      tmpData: {
        nickname: '',
        qq: '',
      },
      queryPw: {
        old_password: '',
        new_password: '',
        confirm_password: '',
      },
      editNickname: false,
      editPassword: false,
      editQq: false,
    }
  },
  // computed: {
  //   nickname: {
  //     set (val) {
  //       if (this.hasData('nickname')) {
  //         this.login.data.nickname = val
  //       }
  //     },
  //     get () {
  //       if (this.hasData('nickname')) {
  //         return this.login.data.nickname
  //       }
  //       return ''
  //     }
  //   }
  // },
  methods: {
    init() {
      // this.$emit('refreshLoginData')
      // getMe()
      //   .then(res => {
      //     switch (res.status) {
      //       case resStatus.OK:
      //         this.login.data = res.data
      //         this.syncTmpData()
      //         break
      //     }
      //   })
      //   .catch(err => {
      //     this.$message.error(err)
      //   })
    },
    syncTmpData() {
      this.tmpData = JSON.parse(JSON.stringify(this.login.data))
      Object.keys(this.queryPw).forEach((key) => {
        this.queryPw[key] = ''
      })
    },
    hasData(key) {
      if (this.login.data && this.login.data[key].length !== 0) {
        return true
      }
      return false
    },
    getContentClass(key) {
      const retClass = 'info-text'
      if (this.hasData(key)) {
        return retClass
      }
      return `${retClass} unset`
    },
    getContentText(key) {
      if (this.hasData(key)) {
        return this.login.data[key]
      } else {
        switch (key) {
          case 'mobile':
            return '未绑定手机'
          case 'qq':
            return '未绑定QQ'
          default:
            return ''
        }
      }
    },
    getEditText(key) {
      switch (key) {
        case 'qq':
          if (this.hasData('qq')) {
            return '修改QQ'
          }
          return '绑定QQ'
      }
    },
    isChecked(key) {
      if (this.hasData(key)) {
        return this.login.data[key].length !== 0
      }
      return false
    },
    handleTriggerEdit(key) {
      this[`edit${capitalize(key)}`] = !this[`edit${capitalize(key)}`]
    },
    handleBtnChangeImg() {
      this.$refs.uploadImg.click()
    },
    handleBtnDelImg() {
      delHeadshot()
        .then((res) => {
          this.$emit('refreshLoginData')
        })
        .catch((err) => {
          console.error(err)
        })
    },
    uploadImg(event) {
      postHeadshot(event.target.files[0])
        .then((res) => {
          switch (res.status) {
            case resStatus.OK:
              this.$emit('refreshLoginData')
              break
            default:
              this.$message.error(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    handleBtnSave(key) {
      postMe(this.tmpData)
        .then((res) => {
          switch (res.status) {
            case resStatus.OK:
              this.handleSaveSuccess(res)
              this.handleBtnCancel(key)
              break
            default:
              this.$message.error(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    handleBtnSavePw() {
      postModifyPass(this.queryPw)
        .then((res) => {
          switch (res.status) {
            case resStatus.OK:
              this.handleSaveSuccess(res)
              this.handleBtnCancel('password')
              break
            default:
              this.$message.error(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    handleSaveSuccess(res) {
      // this.login.data = res.data
      this.$emit('refreshLoginData')
      this.$message('已经成功更新你的资料')
    },
    handleBtnCancel(key) {
      this.syncTmpData()
      this.handleTriggerEdit(key)
    },
  },
  created() {
    this.init()
  },
  watch: {
    login() {
      this.syncTmpData()
    },
  },
}
</script>

<style lang="scss" scoped>
.info-container {
  background-color: #fff;
  .title-container {
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    h2 {
      color: #000000;
    }
  }
  .content-container {
    .content-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      .content-label {
        color: #333333;
        text-align: left;
      }
      .content-info {
        flex-grow: 99;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .info-text {
          color: #4f4f4f;
          display: flex;
          align-items: center;
          .edit-container {
            display: flex;
            flex-direction: column;
            input {
              color: #4f4f4f;
              border: 1px solid #bdbdbd;
            }
            .btn-container {
              display: flex;
              align-items: center;
              .btn {
                width: fit-content;
              }
              .btn-save {
                background-color: #005fde;
                color: #ffffff;
              }
              .btn-cancel {
                background: #ffffff;
                color: #005fde;
                border: 1px solid #005fde;
              }
            }
          }
        }
        .unset {
          color: #bdbdbd;
        }
        .info-text-img {
          display: flex;
          align-items: center;
          .img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            // img {
            //   width: 100%;
            //   height: 100%;
            //   border-radius: 50%;
            // }
          }
        }
        .info-edit {
          color: #2f84ed;
          cursor: pointer;
        }
      }
    }
    .content-item:last-child {
      border-bottom: none;
    }
  }
}
.tip {
  color: #9b9b9b;
}
@media (max-width: 575.98px) {
  .info-container {
    .title-container {
      padding: 20px 20px 15px;
      background: #56ccf2;
      border-bottom: none;
      h2 {
        font-weight: 500;
        font-size: 21px;
        color: #ffffff;
        text-align: center;
      }
    }
    .content-container {
      padding: 10px 15px 20px;
      .content-item {
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 5px 15px;
        .content-label {
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 5px;
          line-height: 22.4px;
        }
        .content-info {
          width: 100%;
          .info-text {
            width: 100%;
            font-size: 14px;
            line-height: 20px;
            .edit-container {
              width: 100%;
              // gap: 20px;
              input {
                width: 100%;
                height: 40px;
                font-size: 14px;
                padding: 8px 12px;
                border-radius: 4px;
                margin: 5px 0 10px;
              }
              .btn-container {
                // gap: 10px;
                margin-top: 5px;
                .btn {
                  padding: 6px 28px;
                  font-weight: 500;
                  font-size: 14px;
                  margin-right: 10px;
                }
                .btn-cancel {
                  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .edit-container-pw {
            input {
              width: 100% !important;
            }
          }
          .info-text-img {
            // gap: 25px;
            .img-container {
              width: 55px;
              min-width: 55px;
              height: 55px;
              display: inline-block;
              margin-right: 20px;
            }
            .img-tip {
              font-size: 12px;
              text-align: left;
            }
          }
          .info-edit {
            position: absolute;
            top: 17px;
            right: 5px;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .info-container {
    .title-container {
      padding: 40px 20px 15px;
      h2 {
        font-weight: 500;
        font-size: 21px;
      }
    }
    .content-container {
      padding: 0 15px 20px;
      .content-item {
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 5px 15px;
        .content-label {
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 5px;
          line-height: 22.4px;
        }
        .content-info {
          width: 100%;
          .info-text {
            width: 100%;
            font-size: 14px;
            line-height: 20px;
            .edit-container {
              width: 100%;
              // gap: 20px;
              input {
                width: 100%;
                height: 40px;
                font-size: 14px;
                padding: 8px 12px;
                border-radius: 4px;
                margin: 5px 0 10px;
              }
              .btn-container {
                // gap: 10px;
                margin-top: 5px;
                .btn {
                  padding: 6px 28px;
                  font-weight: 500;
                  font-size: 14px;
                  margin-right: 10px;
                }
                .btn-cancel {
                  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .edit-container-pw {
            input {
              width: 100% !important;
            }
          }
          .info-text-img {
            // gap: 25px;
            .img-container {
              width: 55px;
              min-width: 55px;
              height: 55px;
              display: inline-block;
              margin-right: 20px;
            }
            .img-tip {
              font-size: 12px;
              text-align: left;
            }
          }
          .info-edit {
            position: absolute;
            top: 17px;
            right: 5px;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .info-container {
    .title-container {
      padding: 13px 25px;
      h2 {
        font-weight: 500;
        font-size: 20px;
      }
    }
    .content-container {
      padding: 0 25px 20px;
      .content-item {
        padding: 20px 15px 20px 0;
        .content-label {
          width: MAX(110px, 90px);
          font-weight: 500;
          font-size: 16px;
          // min-width: 90px;
        }
        .content-info {
          .info-text {
            font-size: 14px;
            .edit-container {
              // gap: 20px;
              input {
                width: 260px;
                height: 40px;
                font-size: 14px;
                padding: 9px 15px;
                border-radius: 4px;
                margin-bottom: 15px;
              }
              .btn-container {
                // gap: 10px;
                .btn {
                  padding: 6px 30px;
                  font-weight: 500;
                  font-size: 14px;
                  margin-right: 10px;
                }
                .btn-cancel {
                  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .edit-container-pw {
            input {
              width: 300px !important;
            }
          }
          .info-text-img {
            // gap: 25px;
            .img-container {
              width: 55px;
              min-width: 55px;
              height: 55px;
              display: inline-block;
              margin-right: 15px;
            }
            .img-tip {
              font-size: 12px;
              text-align: left;
            }
          }
          .info-edit {
            font-size: 14px;
            min-width: 5.5em;
            text-align: right;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info-container {
    .title-container {
      padding: 18px 30px;
      h2 {
        font-weight: 500;
        font-size: 21px;
      }
    }
    .content-container {
      padding: 0 30px 20px;
      .content-item {
        padding: 25px 20px 25px 0;
        .content-label {
          font-weight: 500;
          font-size: 16px;
          min-width: 120px;
        }
        .content-info {
          .info-text {
            font-size: 16px;
            .edit-container {
              // gap: 20px;
              input {
                width: 260px;
                height: 40px;
                font-size: 16px;
                padding: 9px 17px;
                border-radius: 4px;
                margin-bottom: 20px;
              }
              .btn-container {
                // gap: 10px;
                .btn {
                  padding: 7px 33px;
                  font-weight: 500;
                  font-size: 16px;
                  margin-right: 10px;
                }
                .btn-cancel {
                  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .edit-container-pw {
            input {
              width: 380px !important;
            }
          }
          .info-text-img {
            // gap: 25px;
            .img-container {
              width: 55px;
              height: 55px;
              display: inline-block;
              margin-right: 25px;
            }
            .img-tip {
              font-size: 13px;
            }
          }
          .info-edit {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .info-container {
    .title-container {
      padding: 18px 30px;
      h2 {
        font-weight: 500;
        font-size: 21px;
      }
    }
    .content-container {
      padding: 0 30px 20px;
      .content-item {
        padding: 25px 20px 25px 0;
        .content-label {
          font-weight: 500;
          font-size: 16px;
          min-width: 120px;
        }
        .content-info {
          .info-text {
            font-size: 16px;
            .edit-container {
              // gap: 20px;
              input {
                width: 260px;
                height: 40px;
                font-size: 16px;
                padding: 9px 17px;
                border-radius: 4px;
                margin-bottom: 20px;
              }
              .btn-container {
                // gap: 10px;
                .btn {
                  padding: 7px 33px;
                  font-weight: 500;
                  font-size: 16px;
                  margin-right: 10px;
                }
                .btn-cancel {
                  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .edit-container-pw {
            input {
              width: 380px !important;
            }
          }
          .info-text-img {
            // gap: 25px;
            .img-container {
              width: 55px;
              height: 55px;
              display: inline-block;
              margin-right: 25px;
            }
            .img-tip {
              font-size: 13px;
            }
          }
          .info-edit {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
