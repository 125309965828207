const resSrc = (url) => {
  if (process.env.NODE_ENV == 'development') {
    return process.env.VUE_APP_DEV_DOMAIN + url
  } else {
    return url
  }
}

export default {
  resSrc
}
