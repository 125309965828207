<template>
  <div class="forget-container">
    <div class="form-container">
      <!-- 用户名称 -->
      <mc-input
        v-model="query.username"
        placeholder="用户名" />
      <!-- 邮箱地址 -->
      <mc-input
        v-model="query.email"
        placeholder="请输入邮箱地址"
        :state="state.email"
        invalid-feedback="请输入正确的邮箱地址" />
    </div>
    <div class="submit-container">
      <button
        @click="handleSubmit">提交</button>
    </div>
  </div>
</template>

<script>
import McInput from '@/components/McInput'
import { postForgetPass } from '@/api/client/member'
import resStatus from '@/constants/resStatus'

export default {
  components: {
    McInput
  },
  data () {
    return {
      query: {
        username: '',
        email: ''
      },
      state: {
        email: true
      }
    }
  },
  methods: {
    updateLogin (obj) {
      this.$emit('updateLogin', obj)
    },
    validateEmail () {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.state.email = regex.test(String(this.query.email).toLowerCase())
      return this.state.email
    },
    handleSubmit () {
      if (this.validateEmail()) {
        this.$emit('handleLoading', true)
        postForgetPass(this.query)
        .then(res => {
          this.$emit('handleLoading', false)
          switch (res.status) {
            case resStatus.OK:
              this.showSendForget()
              break
            default:
              this.showSendFail(res.message)
          }
        })
        .catch(err => {
          this.$emit('handleLoading', false)
          this.showSendFail(err)
        })
      }
    },
    showSendForget () {
      this.updateLogin({
        formType: 'ForgetSend'
      })
    },
    showSendFail (msg) {
      this.updateLogin({
        formType: 'Error',
        formErrMsg: msg
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.forget-container {
  display: flex;
  flex-direction: column;
  .form-container {
    display: flex;
    flex-direction: column;
  }
  .submit-container {
    width: 100%;
    button {
      width: 100%;
      border: none;
      background-color: #0056D2;
      color: #FFFFFF;
    }
  }
}
@media (max-width: 575.98px) {
  .forget-container {
    margin-top: 70px;
    // gap: 15px;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 8px;}
      & > div:last-child {margin-bottom: 0;}
    }
    .submit-container {
      margin-top: 15px;
      button {
        font-size: 18px;
        height: 50px;
        border-radius: 6px;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .forget-container {
    margin-top: 70px;
    // gap: 15px;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 8px;}
      & > div:last-child {margin-bottom: 0;}
    }
    .submit-container {
      margin-top: 15px;
      button {
        font-size: 18px;
        height: 50px;
        border-radius: 6px;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .forget-container {
    // gap: 15px;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 8px;}
    & > div:last-child {margin-bottom: 0;}
    }
    .submit-container {
      margin-top: 15px;
      button {
        font-size: 18px;
        height: 50px;
        border-radius: 6px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .forget-container {
    // gap: 15px;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 8px;}
    & > div:last-child {margin-bottom: 0;}
    }
    .submit-container {
      margin-top: 15px;
      button {
        font-size: 18px;
        height: 50px;
        border-radius: 6px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .forget-container {
    // gap: 15px;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 8px;}
    & > div:last-child {margin-bottom: 0;}
    }
    .submit-container {
      margin-top: 15px;
      button {
        font-size: 18px;
        height: 50px;
        border-radius: 6px;
      }
    }
  }
}
</style>
