<template>
  <div>
    <div
      v-if="login.formType"
      class="account-container">
      <div
        class="cover-container"
        @click="handleCloseClick"></div>
      <div
        class="dialog-container"
        :class="dialogClass">
        <Close
          v-if="!isFitContainer"
          :style="closeCss"
          @handleCloseClick="handleCloseClick" />
        <h2
          v-if="!isFitContainer"
          class="title">
          {{ title }}
        </h2>
        <div class="form-container">
          <component
            :is="formType"
            :login="login"
            @updateLogin="updateLogin"
            @handleLoading="handleLoading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from './Close'
import SignIn from './SignIn'
import SignInSuccess from './SignInSuccess'
import SignUp from './SignUp'
import SignUpSuccess from './SignUpSuccess'
import Error from './Error'
import Forget from './Forget'
import ForgetSend from './ForgetSend'
import { isMobile } from '@/utils/device'

export default {
  components: {
    Close,
    SignIn,
    SignInSuccess,
    SignUp,
    SignUpSuccess,
    Error,
    Forget,
    ForgetSend
  },
  props: {
    login: {
      type: Object,
      required: true
    }
  },
  computed: {
    formType: {
      get () {
        return this.login.formType
      }
    },
    title: {
      get () {
        return this.login.allTypes[this.login.formType]
      }
    },
    closeCss: {
      get () {
        if (isMobile()) {
          return {
            position: 'absolute',
            width: '20px',
            height: '20px',
            top: '25px',
            left: '20px'
          }
        } else {
          return {
            position: 'absolute',
            width: '20px',
            height: '20px',
            top: '18px',
            right: '30px'
          }
        }
      },
      showLoad: {
        get () {
          return 
        }
      }
    },
    dialogClass: {
      get () {
        if (this.isFitContainer) {
          return 'fit-container'
        }
        return ''
      }
    },
    isFitContainer: {
      get () {
        switch (this.login.formType) {
          case 'ForgetSend':
            return !isMobile()
          case 'SignInSuccess':
          case 'SignUpSuccess':
          case 'Error':
            return true
          default:
            return false
        }
      }
    }
  },
  methods: {
    handleCloseClick () {
      this.updateLogin({
        formType: null
      })
    },
    updateLogin (obj) {
      this.$emit('updateLogin', obj)
    },
    handleLoading (boo) {
      this.$emit('handleLoading', boo)
    }
  }
}
</script>

<style lang="scss" scoped>
.account-container {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  justify-content: center;
  align-items: center;
  .cover-container {
    position: fixed;
    z-index: 101;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .dialog-container {
    max-height: 94vh;
    z-index: 102;
    position: relative;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    .title {
      color: #494949;
    }
    .form-container {
      width: 100%;
    }
  }
}
@media (max-width: 375.98px) {
  .account-container {
    .dialog-container {
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      overflow-y: scroll;
      padding: 30px;
      justify-content: flex-start;
      // gap: 35px;
      .title {
        font-weight: 500;
        font-size: 24px;
        margin: 30px 0 0;
      }
      .form-container{height: inherit;}
    }
    .fit-container {
      width: fit-content;
      height: fit-content;
      border-radius: 8px;
      padding: 50px 35px 30px;
      margin-top: -50px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }
}
@media (min-width: 376px) and (max-width: 575.98px) {
  .account-container {
    .dialog-container {
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      overflow-y: scroll;
      padding: 30px;
      justify-content: flex-start;
      // gap: 35px;
      .title {
        font-weight: 500;
        font-size: 24px;
        margin: 30px 0 0;
      }
      .form-container{height: inherit;}
    }
    .fit-container {
      width: fit-content;
      height: fit-content;
      border-radius: 8px;
      padding: 50px 35px 30px;
      margin-top: -50px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .account-container {
    .dialog-container {
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      overflow-y: scroll;
      padding: 30px 30px 36px;
      justify-content: flex-start;
      // gap: 35px;
      .title {
        font-weight: 500;
        font-size: 24px;
        margin: 25px 0 0;
      }
      .form-container{height: inherit;}
    }
    .fit-container {
      width: fit-content;
      height: fit-content;
      border-radius: 8px;
      padding: 50px 35px 30px;
      margin-top: -50px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .account-container {
    .dialog-container {
      width: 360px;
      padding: 30px 30px 36px;
      border-radius: 8px;
      // gap: 35px;
      .title {
        font-weight: 500;
        font-size: 21px;
        margin-bottom: 5px;
      }
    }
    .fit-container {
      width: fit-content;
      padding: 50px 35px 30px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .account-container {
    .dialog-container {
      width: 400px;
      padding: 30px 30px 36px;
      border-radius: 8px;
      // gap: 35px;
      .title {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
    .fit-container {
      width: fit-content;
      padding: 50px 35px 30px;
    }
  }
}
@media (min-width: 1200px) {
  .account-container {
    .dialog-container {
      width: 420px;
      padding: 30px 30px 36px;
      border-radius: 8px;
      // gap: 35px;
      .title {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
    .fit-container {
      width: fit-content;
      padding: 50px 35px 30px;
    }
  }
}
</style>
