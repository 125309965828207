<template>
  <div class="header">
    <div class="header-container">
      <div class="header-left-container">
        <span @click="handleGoManycai">前往多彩网商户端</span>
      </div>
      <div v-if="!login.isLogin" class="header-right-container">
        <span @click="handleSignIn">登录</span>
        &nbsp;|&nbsp;
        <span @click="handleSignUp">注冊</span>
      </div>
      <div v-if="login.isLogin" class="header-right-container is-sign-in">
        <account :login="login" @updateLogin="updateLogin" />
        <!-- <div class="notice-container">
          notice
        </div> -->
      </div>
    </div>
    <div class="mobile-header-container" @click="handleMobileLogin">
      <div class="member-photo">
        <div
          v-if="login.isLogin && login.data.photo !== ''"
          :style="{ 'background-image': `url('${$common.resSrc(login.data.photo)}')` }"
        ></div>
        <b-icon v-else icon="person-fill"></b-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { goWebsite, goPage } from '@/utils/route'
import Account from './Account'
import { isMobile } from '@/utils/device'

export default {
  name: 'header-head',
  components: {
    Account,
  },
  props: {
    login: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleGoManycai() {
      goWebsite('https://www.manycai.com/index/index')
    },
    handleSignIn() {
      this.updateLogin({
        formType: 'SignIn',
      })
    },
    handleSignUp() {
      this.updateLogin({
        formType: 'SignUp',
      })
    },
    updateLogin(obj) {
      this.$emit('updateLogin', obj)
    },
    handleGoMember() {
      const name = isMobile() ? 'MemberMobile' : 'Member'
      goPage({ name: name })
    },
    handleMobileLogin() {
      return this.login.isLogin ? this.handleGoMember() : this.handleSignIn()
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  height: 38px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background: #1a265e;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  .header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #f2f2f2;
    .header-left-container,
    .header-right-container {
      display: flex;
      align-items: center;
      font-size: 14px;
      // gap: 5px;
      span {
        display: inline-block;
        margin: 0 5px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .header-right-container {
      justify-content: flex-end;
    }
  }
  .mobile-header-container {
    display: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: #ffffff;
    background: #c2d6ff;
    font-size: 18px;
    overflow: hidden;
    .member-photo {
      width: 100%;
      height: 100%;
      & > div {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
      }
      .b-icon.bi {
        vertical-align: middle;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .header {
    background: #fafafa;
    .header-container {
      display: none;
    }
    .mobile-header-container {
      display: block;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .header {
    background: #fafafa;
    .header-container {
      display: none;
    }
    .mobile-header-container {
      display: block;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
  .header {
    .header-container {
      width: 1200px;
    }
  }
}
</style>
