import { fillDigit } from '@/utils/string'

export function checkDateFormat (date) {
  let dtRegex = /[0-9]{4}[/\-](0[1-9]|1[012])[/\-](0[1-9]|[12][0-9]|3[01])/
  return date.match(dtRegex)
}

export function getDate (date) {
  let d = date
  if (!d) {
    d = new Date()
  } else {
    if (!(d instanceof Date)) {
      if (checkDateFormat(d)) {
        d = d.replace(/\s/, 'T')
      }
    }
  }
  return fillDigit(new Date(d).getDate(), 2, '0')
}

export function getMonth (date) {
  let d = date
  if (!d) {
    d = new Date()
  } else {
    if (!(d instanceof Date)) {
      if (checkDateFormat(d)) {
        d = d.replace(/\s/, 'T')
      }
    }
  }
  return fillDigit(new Date(d).getMonth() + 1, 2, '0')
}

export function getYear (date) {
  return new Date(date).getFullYear()
}

export function getDiffDay (diffSec, date) {
  // const dayMS = 1000 * 24 * 60 * 60
  const day = date ? new Date(date) : new Date()
  const diff = day.getTime() + diffSec
  const diffDay = new Date(diff)
  return diffDay
}

export function getZhWeekday (date) {
  const weekday = new Date(date).getDay()
  switch (weekday) {
    case 0:
      return '日'
    case 1:
      return '一'
    case 2:
      return '二'
    case 3:
      return '三'
    case 4:
      return '四'
    case 5:
      return '五'
    case 6:
      return '六'
    default:
      return '日'
  }
}

export function dateFormat (fmt, date) {
  const CsDate = Date
  if (!Object.prototype.hasOwnProperty.call(CsDate, 'format')) {
    Object.defineProperty(CsDate.prototype, 'format', {
      value: dfmt
    })
  }
  const t = new CsDate(date)

  return t.format(fmt)
}
function dfmt (fmt) {
  // 对Date的扩展，将 Date 转化为指定格式的String
  // 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
  // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
  // 例子：
  // (new Date()).Format('yyyy-MM-dd hh:mm:ss.S') ==> 2006-07-02 08:09:04.423
  // (new Date()).Format('yyyy-M-d h:m:s.S')      ==> 2006-7-2 8:9:4.18
  const o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds() // 毫秒
  }

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }

  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))
      )
    }
  }

  return fmt
}

export function isBetweenTwoDate (start, end, dateCompare) {
  let curDate = new Date()
  if (dateCompare) {
    curDate = new Date(dateCompare)
  }
  return (
    curDate.getTime() >= new Date(start).getTime() &&
    curDate.getTime() <= new Date(end).getTime()
  )
}

export function isBeforeDate (start, end, dateCompare) {
  let curDate = new Date()
  if (dateCompare) {
    curDate = new Date(dateCompare)
  }
  return curDate.getTime() < new Date(start).getTime()
}

export function isAfterDate (start, end, dateCompare) {
  let curDate = new Date()
  if (dateCompare) {
    curDate = new Date(dateCompare)
  }
  return curDate.getTime() > new Date(end).getTime()
}

export function getDateRange (num, start) {
  let date = start ? new Date(start) : new Date()
  let arr = []
  for(let i = 0; i < Math.abs(num); i++) {
    let count = num < 0 ? 0 - i : i
    let last = new Date(date.getTime() + (count * 24 * 60 * 60 * 1000))
    const day = String(last.getDate())
    const month = String(last.getMonth() + 1)
    const year = last.getFullYear()
    let dateStr = `${year}-${month.length < 2 ? '0' : ''}${month}-${day.length < 2 ? '0' : ''}${day}`
    arr.push(dateStr)
  }
  return arr
}
