import router from './router'
// import { getCookie } from '@/utils/cookie'
import { isAdminPage, isAdminLogin } from '@/utils/admin'
import { isMemberLogin } from '@/utils/member'
import { isMobile } from '@/utils/device'

router.beforeEach((to, from, next) => {
  if (isAdminPage(to)) {
    if (isAdminLogin()) {
      next()
    } else {
      // cookie失效時,嘗試訪問後台導回後台登入頁面
      next({ name: 'AdminLogin' })
    }
    // 原始code,神串留名
    // else {
    //   if (to.name === 'AdminLogin') {
    //     next()
    //   } else {
    //     next({ name: 'AdminLogin' })
    //   }
    // }
  } else {
    if (to.path === '/member/home') {
      if (isMemberLogin()) {
        if (isMobile()) {
          next()
        } else {
          next({ name: 'MemberInfo' })
        }
      } else {
        next({ name: 'Home' })
      }
    } else {
      next()
    }
  }
  // if (getCookie('isRegularCustomer')) {
  //   next()
  // } else {
  //   if (to.name === 'Landing') {
  //     next()
  //   } else {
  //     next({
  //       name: 'Landing'
  //     })
  //   }
  // }
})
