import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// reference: https://bootstrap-vue.org/docs
// global boostrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
