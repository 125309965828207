<template>
  <div class="main-container">
    <router-view class="app-content" :login="login" @refreshLoginData="refreshLoginData" />
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { postLogout } from '@/api/client/member'
import { goPage } from '@/utils/route'
import { memberLogout } from '@/utils/member'
import { isMobile } from '@/utils/device'

export default {
  components: {},
  props: {
    login: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isMobile: {
      get() {
        return isMobile()
      },
    },
  },
  methods: {
    refreshLoginData() {
      this.$emit('refreshLoginData')
    },
    updateLogin(obj) {
      this.$emit('updateLogin', obj)
    },
    handleLogout() {
      postLogout()
        .then((res) => {
          switch (res.status) {
            case resStatus.OK:
              if (
                this.$route.matched[0].name === 'Member' ||
                this.$route.matched[1].name === 'GodPlay'
              ) {
                goPage({ name: 'Home' })
              }
              this.handleLogoutSuccess()
              break
            default:
              console.log('logout error: ', res)
          }
        })
        .catch((err) => {
          console.error('logout error: ', err)
        })
    },
    handleLogoutSuccess() {
      this.updateLogin({
        data: null,
        isLogin: false,
      })
      memberLogout()
    },
  },
  watch: {
    login(curData) {
      if (!curData.isLogin && !curData.data) {
        goPage({ name: 'Home' })
      }
      if (!curData.formType) {
        document.querySelector('#app').style.height = 'unset'
        document.querySelector('#app').style.overflow = 'unset'
      } else {
        document.querySelector('#app').style.height = '100vh'
        document.querySelector('#app').style.overflow = 'hidden'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  .main-content {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
  }
  .logout-content {
    width: 100%;
    font-size: 18px;
    line-height: 25px;
    padding: 15px 0;
    text-align: center;
    margin: 10px 0 30px;
    color: #4f4f4f;
    background-color: #fff;
  }
}
@media (max-width: 575.98px) {
  .main-container {
    width: 100%;
    height: auto;
    min-height: inherit;
    .app-content {
      height: auto;
      min-height: inherit;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-container {
    width: 100%;
    height: auto;
    min-height: inherit;
    flex-wrap: wrap;
    .app-content {
      height: auto;
      min-height: inherit;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-container {
    width: 100%;
    padding: 10px 0 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-container {
    width: 100%;
    padding: 10px 0 30px;
  }
}
@media (min-width: 1200px) {
  .main-container {
    width: 1200px;
    padding: 10px 0 30px;
  }
}
</style>
