const adminRoutes = [
  {
    path: '/admin/home',
    name: 'AdminHome',
    meta: {
      isAdminPage: true,
      title: '首頁',
      icon: 'el-icon-house'
    },
    component: () => import('@/views/Admin/Home')
  },
  {
    path: '/admin/market',
    name: 'AdminMarket',
    meta: {
      isAdminPage: true,
      title: '营销管理',
      icon: 'el-icon-s-shop'
    },
    component: () => import('@/views/Admin/Market'),
    children: [
      {
        path: '/admin/market/promote',
        name: 'AdminMarketPromote',
        component: () => import('@/views/Admin/Market/Promote'),
        meta: {
          isAdminPage: true,
          title: '广告'
        },
        children: [
          {
            path: '/admin/market/promote/add',
            name: 'AdminMarketPromoteAdd',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Promote/Add'),
            meta: {
              isAdminPage: true,
              title: '新增广告'
            }
          },
          {
            path: '/admin/market/promote/edit/:id?',
            name: 'AdminMarketPromoteEdit',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Promote/Edit'),
            meta: {
              isAdminPage: true,
              title: '修改广告'
            }
          }
        ]
      },
      {
        path: '/admin/market/domain',
        name: 'AdminMarketDomain',
        component: () => import('@/views/Admin/Market/Domain'),
        meta: {
          isAdminPage: true,
          title: '域名'
        },
        children: [
          {
            path: '/admin/market/domain/add',
            name: 'AdminMarketDomainAdd',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Domain/Add'),
            meta: {
              isAdminPage: true,
              title: '新增域名'
            }
          },
          {
            path: '/admin/market/domain/edit/:id?',
            name: 'AdminMarketDomainEdit',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Domain/Edit'),
            meta: {
              isAdminPage: true,
              title: '修改域名'
            }
          }
        ]
      },
      {
        path: '/admin/market/setting',
        name: 'AdminMarketSetting',
        component: () => import('@/views/Admin/Market/Setting'),
        meta: {
          isAdminPage: true,
          title: '显示设置'
        },
        children: [
          {
            path: '/admin/market/setting/userCount',
            name: 'AdminMarketSettingUserCount',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Setting/UserCount'),
            meta: {
              isAdminPage: true,
              title: '累计服务人数'
            }
          },
          {
            path: '/admin/market/setting/issueResult',
            name: 'AdminMarketSettingIssueResult',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Setting/IssueResult'),
            meta: {
              isAdminPage: true,
              title: '最新开奖结果'
            }
          },
          {
            path: '/admin/market/setting/lotteryHot',
            name: 'AdminMarketSettingLotteryHot',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Setting/LotteryHot/index'),
            meta: {
              isAdminPage: true,
              title: '热门彩种'
            }
          },
          {
            path: '/admin/market/setting/lotteryHot/add',
            name: 'AdminMarketSettingLotteryHotAdd',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Setting/LotteryHot/Add'),
            meta: {
              isAdminPage: true,
              title: '新增热门彩种'
            }
          },
          {
            path: '/admin/market/setting/lotteryHot/edit/:id?',
            name: 'AdminMarketSettingLotteryHotEdit',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Setting/LotteryHot/Edit'),
            meta: {
              isAdminPage: true,
              title: '修改热门彩种'
            }
          },
          {
            path: '/admin/market/setting/lotteryPredict',
            name: 'AdminMarketSettingLotteryPredict',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Setting/LotteryPredict/index'),
            meta: {
              isAdminPage: true,
              title: '彩票资讯-奖号预测'
            }
          },
          {
            path: '/admin/market/setting/lotteryPredict/add',
            name: 'AdminMarketSettingLotteryPredictAdd',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Setting/LotteryPredict/Add'),
            meta: {
              isAdminPage: true,
              title: '新增热门彩种'
            }
          },
          {
            path: '/admin/market/setting/lotteryPredict/edit/:id?',
            name: 'AdminMarketSettingLotteryPredictEdit',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Setting/LotteryPredict/Edit'),
            meta: {
              isAdminPage: true,
              title: '修改热门彩种'
            }
          }
        ]
      },
      {
        path: '/admin/market/benefit',
        name: 'AdminMarketBenefit',
        component: () => import('@/views/Admin/Market/Benefit'),
        meta: {
          isAdminPage: true,
          title: '专属福利'
        },
        children: [
          {
            path: '/admin/market/benefit/add',
            name: 'AdminMarketBenefitAdd',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Benefit/Add'),
            meta: {
              isAdminPage: true,
              title: '新增专属福利'
            }
          },
          {
            path: '/admin/market/benefit/edit/:id?',
            name: 'AdminMarketBenefitEdit',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Benefit/Edit'),
            meta: {
              isAdminPage: true,
              title: '修改专属福利'
            }
          }
        ]
      },
      {
        path: '/admin/market/news',
        name: 'AdminMarketNews',
        component: () => import('@/views/Admin/Market/News'),
        meta: {
          isAdminPage: true,
          title: '彩票新闻'
        },
        children: [
          {
            path: '/admin/market/news/add',
            name: 'AdminMarketNewsAdd',
            invisiable: true,
            component: () => import('@/views/Admin/Market/News/Add'),
            meta: {
              isAdminPage: true,
              title: '新增新闻'
            }
          },
          {
            path: '/admin/market/News/edit/:id?',
            name: 'AdminMarketNewsEdit',
            invisiable: true,
            component: () => import('@/views/Admin/Market/News/Edit'),
            meta: {
              isAdminPage: true,
              title: '修改新闻'
            }
          }
        ]
      },
      {
        path: '/admin/market/trick',
        name: 'AdminMarketTrick',
        component: () => import('@/views/Admin/Market/Trick'),
        meta: {
          isAdminPage: true,
          title: '彩票技巧'
        },
        children: [
          {
            path: '/admin/market/trick/add',
            name: 'AdminMarketTrickAdd',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Trick/Add'),
            meta: {
              isAdminPage: true,
              title: '新增技巧讯息'
            }
          },
          {
            path: '/admin/market/trick/edit/:id?',
            name: 'AdminMarketTrickEdit',
            invisiable: true,
            component: () => import('@/views/Admin/Market/Trick/Edit'),
            meta: {
              isAdminPage: true,
              title: '修改技巧讯息'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/admin/help',
    name: 'AdminHelp',
    meta: {
      isAdminPage: true,
      title: '帮助中心',
      icon: 'el-icon-service'
    },
    component: () => import('@/views/Admin/Help'),
    children: [
      {
        path: '/admin/help/FAQ',
        name: 'AdminHelpFAQ',
        component: () => import('@/views/Admin/Help/FAQ'),
        meta: {
          isAdminPage: true,
          title: '常见问题'
        },
        children: [
          {
            path: '/admin/help/FAQ/add',
            name: 'AdminHelpFAQAdd',
            invisiable: true,
            component: () => import('@/views/Admin/Help/FAQ/Add'),
            meta: {
              isAdminPage: true,
              title: '新增常见问题'
            }
          },
          {
            path: '/admin/help/FAQ/edit/:id?',
            name: 'AdminHelpFAQEdit',
            invisiable: true,
            component: () => import('@/views/Admin/Help/FAQ/Edit'),
            meta: {
              isAdminPage: true,
              title: '修改常见问题'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/admin/member',
    name: 'AdminMember',
    meta: {
      isAdminPage: true,
      title: '会员管理',
      icon: 'el-icon-postcard'
    },
    component: () => import('@/views/Admin/Member'),
    children: [
      {
        path: '/admin/member/account',
        name: 'AdminMemberAccount',
        component: () => import('@/views/Admin/Member/Account'),
        meta: {
          isAdminPage: true,
          title: '帐号管理'
        },
        children: [
          {
            path: '/admin/member/account/add',
            name: 'AdminMemberAccountAdd',
            invisiable: true,
            component: () => import('@/views/Admin/Member/Account/Add'),
            meta: {
              isAdminPage: true,
              title: '新增会员'
            }
          },
          {
            path: '/admin/member/account/edit/:id?',
            name: 'AdminMemberAccountEdit',
            invisiable: true,
            component: () => import('@/views/Admin/Member/Account/Edit'),
            meta: {
              isAdminPage: true,
              title: '修改会员'
            }
          }
        ]
      },
      {
        path: '/admin/member/push',
        name: 'AdminMemberPush',
        component: () => import('@/views/Admin/Member/Push'),
        meta: {
          isAdminPage: true,
          title: '发送消息'
        },
        children: [
          {
            path: '/admin/member/push/add',
            name: 'AdminMemberPushAdd',
            invisiable: true,
            component: () => import('@/views/Admin/Member/Push/Add'),
            meta: {
              isAdminPage: true,
              title: '發送消息'
            }
          },
          {
            path: '/admin/member/push/edit/:id?',
            name: 'AdminMemberPushEdit',
            invisiable: true,
            component: () => import('@/views/Admin/Member/Push/Edit'),
            meta: {
              isAdminPage: true,
              title: '修改消息'
            }
          }
        ]
      },
      {
        path: '/admin/member/feedback',
        name: 'AdminMemberFeedback',
        component: () => import('@/views/Admin/Member/Feedback'),
        meta: {
          isAdminPage: true,
          title: '意见反馈'
        }
      },
      {
        path: '/admin/member/contact',
        name: 'AdminMemberContact',
        component: () => import('@/views/Admin/Member/Contact'),
        meta: {
          isAdminPage: true,
          title: '联络我们'
        }
      },
      {
        path: '/admin/member/report',
        name: 'AdminMemberReport',
        component: () => import('@/views/Admin/Member/Report'),
        meta: {
          isAdminPage: true,
          title: '检举内容'
        }
      }
    ]
  },
  {
    path: '/admin/statistics',
    name: 'AdminStatistics',
    meta: {
      isAdminPage: true,
      title: '平台数据',
      icon: 'el-icon-s-data'
    },
    component: () => import('@/views/Admin/Statistics'),
    children: [
      {
        path: '/admin/statistics/login',
        name: 'AdminStatisticsLogin',
        component: () => import('@/views/Admin/Statistics/Login'),
        meta: {
          isAdminPage: true,
          title: '会员登录'
        }
      },
      {
        path: '/admin/statistics/retention',
        name: 'AdminStatisticsRetention',
        component: () => import('@/views/Admin/Statistics/Retention'),
        meta: {
          isAdminPage: true,
          title: '会员留存'
        }
      }
    ]
  },
  {
    path: '/admin/mobile_app',
    name: 'AdminMobileApp',
    meta: {
      isAdminPage: true,
      title: '手机应用 APP',
      icon: 'el-icon-mobile-phone'
    },
    component: () => import('@/views/Admin/MobileApp'),
    children: [
      {
        path: '/admin/mobile_app/official',
        name: 'AdminMobileAppOfficial',
        component: () => import('@/views/Admin/MobileApp/OnlineOfficial'),
        meta: {
          isAdminPage: true,
          title: '上架官网'
        }
      }
    ]
  }
]

export default adminRoutes
