import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

// 登录 / 登出 / 註冊 / 發送驗證碼
// ------------------------------
// 前台用戶login
export function postLogin (data) {
  // data format:
  // {
  //   username,
  //   password
  // }
  return request({
    url: '/api/login',
    method: 'post',
    data: getFormData(data)
  })
}
// 發送驗證碼
export function postSendCode (data) {
  // data format:
  // {
  //   email
  // }
  return request({
    url: '/api/sendcode',
    method: 'post',
    data: getFormData(data)
  })
}
// 前台用戶logout
export function postLogout () {
  return request({
    url: '/api/logout',
    method: 'post'
  })
}
// 前台用戶註冊
export function postRegister (data) {
  // data format:
  // {
  //   username, // 用戶名稱
  //   password, // 密码
  //   confirm_password, // 確認密码
  //   nickname, // 暱稱，可不給
  //   email,
  //   code // 驗證碼
  // }
  return request({
    url: '/api/register',
    method: 'post',
    data: getFormData(data)
  })
}
// ------------------------------

// 會員中心相關
// ------------------------------
// 取得用戶資料
export function getMe () {
  return request({
    url: '/api/users/me',
    method: 'get'
  })
}
// 修改密碼
export function postModifyPass (data) {
  return request({
    url: '/api/users/me/modifypass',
    method: 'post',
    data: getFormData(data)
  })
}
// 修改用戶資料
export function postMe (data) {
  return request({
    url: '/api/users/me',
    method: 'post',
    data: getFormData(data)
  })
}
// 上傳用戶大頭照
export function postHeadshot (photo) {
  return request({
    url: '/api/users/me/headshot',
    method: 'post',
    data: getFormData({
      upload: photo
    })
  })
}
// 發送手機驗證碼
export function postTelephonecode (data) {
  // data format
  /**
   * {
   *  mobile: '09**',
   *  countrycode: '00886',
   *  code: true // 有給此欄位 - 進行驗證碼驗證, 無 - 發送驗證碼
   * }
   */
  return request({
    url: '/api/users/me/telephonecode',
    method: 'post',
    data: getFormData(data)
  })
}
// 刪除用戶大頭照
export function delHeadshot () {
  return request({
    url: '/api/users/me/headshot',
    method: 'delete'
  })
}
// ------------------------------

// 忘記密码
// ------------------------------
// 忘記密码
export function postForgetPass (data) {
  return request({
    url: '/api/forgetpass',
    method: 'post',
    data: getFormData(data)
  })
}
// ------------------------------

// 到站統計相關
// ------------------------------
// 首頁訪客到站次數
export function getVisits (params) {
  return request({
    url: '/api/visits',
    method: 'get',
    params: params
  })
}
// ------------------------------
