<template>
  <div class="contact-container">
    <h2>客服咨询</h2>
    <!-- <button @click="handleClickContact">点我与客服交谈</button> -->
    <div class="contact-info">
      <p
        v-for="info in contactInfo"
        :key="info.platform">
        {{ info.platform }} : {{ info.id }}
      </p>
    </div>
  </div>
</template>

<script>
import { goWebsite } from '@/utils/route'
export default {
  name: 'footerContact',
  data () {
    return {
      contactInfo: [
        // {
        //   platform: 'SKYPE',
        //   id: 'live:manycai'
        // },
        {
          platform: 'Email',
          id: 'service@manycai.club'
        }
      ]
    }
  },
  methods: {
    handleClickContact () {
      goWebsite('https://vue.livelyhelp.chat/chatWindow.aspx?siteId=5000757&planId=645&chatGroup=1', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  h2 {
    color: #FAFAFA;
  }
  button {
    color: #FFFFFF;
    text-align: center;
    background: linear-gradient(234.8deg, #FF9356 7.81%, #EE4A72 73.1%);
    box-shadow: 0px 2px 8px rgba(235, 87, 87, 0.25);
    border-radius: 5px;
    border: 0px;
  }
  .contact-info {
    color: #E0F7FF;
  }
}
@media (max-width: 575.98px) {
  .contact-container {display: none;}
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .contact-container {display: none;}
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .contact-container {
    h2 {
      font-weight: 500;
      font-size: 17px;
    }
    button {
      width: 166px;
      height: 53px;
      font-weight: 500;
      font-size: 18px;
      margin-top: 15px;
    }
    .contact-info {
      font-weight: 500;
      font-size: 13px;
      margin-top: 18px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .contact-container {
    h2 {
      font-weight: 500;
      font-size: 18px;
    }
    button {
      width: 166px;
      height: 53px;
      font-weight: 500;
      font-size: 18px;
      margin-top: 15px;
    }
    .contact-info {
      font-weight: 500;
      font-size: 14px;
      margin-top: 21px;
    }
  }
}
@media (min-width: 1200px) {
  .contact-container {
    h2 {
      font-weight: 500;
      font-size: 18px;
    }
    button {
      width: 166px;
      height: 53px;
      font-weight: 500;
      font-size: 18px;
      margin-top: 15px;
    }
    .contact-info {
      font-weight: 500;
      font-size: 14px;
      margin-top: 21px;
    }
  }
}
</style>
