export const allAnalysis = [
  {
    id: 'numAnalysis',
    context: '号码预测'
  },
  {
    id: 'historyNum',
    context: '历史开奖'
  },
  {
    id: 'lzAnalysis',
    context: '路珠分析'
  },
  {
    id: 'hcAnalysis',
    context: '冷热分析'
  },
  {
    id: 'dslsHistory',
    context: '单双大小历史'
  },
  {
    id: 'dslslz',
    context: '单双大小路珠'
  },
  {
    id: 'dtlz',
    context: '龙虎路珠'
  }
]
