// pages
import Layout from '@/views/Layout'
import mainRoutes from './mainRoutes'
import MemberLayout from '@/views/Member/Layout'
import MobileLayout from '@/views/Member/Mobile'
import memberRoutes from './memberRoutes'
import AdminLayout from '@/views/Admin/Layout'
import AdminRoutes from './adminRoutes'

const routes = [
  // {
  //   path: '/',
  //   name: 'Landing',
  //   component: () => import('@/views/Landing')
  // },
  {
    path: '/',
    name: 'Root',
    component: Layout,
    redirect: { name: 'Home' },
    children: mainRoutes
  },
  {
    path: '/member',
    name: 'MemberMobile',
    component: MobileLayout,
    redirect: { name: 'MemberHome' },
    meta: {
      context: '会员中心'
    }
  },
  {
    path: '/member',
    name: 'Member',
    component: MemberLayout,
    redirect: { name: 'MemberInfo' }, // MemberHome
    meta: {
      context: '会员中心'
    },
    children: memberRoutes
  },
  {
    path: '/console',
    name: 'AdminLogin',
    component: () => import('@/views/Admin/Login')
  },
  {
    path: '/admin',
    name: 'AdminRoot',
    component: AdminLayout,
    // redirect: { name: 'AdminHome' },
    meta: {
      title: '後台',
      isAdminPage: true
    },
    children: AdminRoutes
  },
  {
    path: '*',
    name: 'Error',
    component: () => import('@/views/Error/index')
  }
]

export default routes
