<template>
  <div class="sign-up-container">
    <div class="form-container">
      <!-- 用户名称 -->
      <mc-input
        v-model="query.username"
        placeholder="请输入用户名称, 8字以上英文数字"
        :state="state.username"
        invalid-feedback="请输入正确的用户名称" />
      <!-- 邮箱地址 -->
      <mc-input
        v-model="query.email"
        placeholder="请输入邮箱地址"
        :state="state.email"
        invalid-feedback="请输入正确的邮箱地址" />
      <!-- 邮箱验证码 -->
      <div class="code-input-container">
        <div
          v-if="!state.code"
          class="invalid-container">
          请输入正确的验证码
        </div>
        <input
          v-model="query.code"
          class="mc-input"
          :class="!state.code ? 'is-invalid' : ''"
          placeholder="请输入邮箱验证码" />
        <button
          class="btn-get-code"
          @click="handleGetCode">获取邮箱验证码</button>
      </div>
      <!-- 登录密码 -->
      <mc-input
        v-model="query.password"
        placeholder="请输入登录密码, 6字以上英文数字"
        :state="state.password"
        invalid-feedback="请输入正确的登录密码"
        type="password" />
      <!-- 再次输入密码 -->
      <mc-input
        v-model="query.confirm_password"
        placeholder="请再次输入密码"
        :state="state.confirm_password"
        invalid-feedback="請輸入相同密码"
        type="password" />
    </div>
    <div class="agree-container">
      <input
        v-model="submitState"
        type="checkbox" />
      <label>我已阅读并同意<a @click="handleGoClause">《多彩网服务条款》</a></label>
    </div>
    <div class="href-sign-in-container">
      <a @click="handleHrefSignIn">已有帐号？前往登录</a>
    </div>
    <div class="submit-container">
      <button
        :disabled="!submitState"
        :class="!submitState ? 'disable' : ''"
        @click="handleSubmit">注册</button>
    </div>
  </div>
</template>

<script>
import McInput from '@/components/McInput'
import { values } from 'lodash'
import { goPageBlank } from '@/utils/route'
import { postSendCode, postRegister } from '@/api/client/member'
import resStatus from '@/constants/resStatus'

export default {
  components: {
    McInput
  },
  data () {
    return {
      query: {
        username: '',
        email: '',
        code: '',
        password: '',
        confirm_password: ''
      },
      state: {
        username: true,
        email: true,
        code: true,
        password: true,
        confirm_password: true
      },
      submitState: false
    }
  },
  methods: {
    updateLogin (obj) {
      this.$emit('updateLogin', obj)
    },
    validateUsername () {
      const regex = /^[a-zA-Z0-9._]{8,}$/
      this.state.username = regex.test(String(this.query.username))
      return this.state.username
    },
    validateEmail () {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.state.email = regex.test(String(this.query.email).toLowerCase())
      return this.state.email
    },
    validateCode () {
      this.state.code = String(this.query.code).length !== 0
      return this.state.code
    },
    validatePassword () {
      const regex = /^[a-zA-Z0-9._@]{6,}$/
      this.state.password = regex.test(String(this.query.password))
      return this.state.password
    },
    validateConfirmPassword () {
      if (
        this.query.confirm_password === this.query.password &&
        this.query.confirm_password.length !== 0
      ) {
        this.state.confirm_password = true
      } else {
        this.state.confirm_password = false
      }
      return this.state.confirm_password
    },
    validateAll () {
      this.validateUsername()
      this.validateEmail()
      this.validateCode()
      this.validatePassword()
      this.validateConfirmPassword()

      const allCheckVals = values(this.state)
      for (let i = 0, c = allCheckVals.length; i < c; i++) {
        if (allCheckVals[i] === false) {
          return false
        }
      }
      return true
    },
    handleGetCode () {
      if (this.validateEmail()) {
        const objQuery = {
          email: this.query.email
        }
        postSendCode(objQuery)
          .then(res => {
            alert(res.message)
            // console.log('sendcode: ', res)
          })
          .catch(err => {
            console.error('sendcode: ', err)
          })
      }
    },
    handleGoClause () {
      goPageBlank({
        name: 'Clause'
      })
    },
    handleSubmit () {
      if (this.validateAll()) {
        postRegister(this.query)
          .then(res => {
            switch (res.status) {
              case resStatus.OK:
                this.showSignUpSuccess()
                break
              default:
                this.showSignUpFail(res.message)
            }
            // console.log('register success: ', res)
          })
          .catch(err => {
            this.showSignUpFail(err)
            console.error('Register error: ', err)
          })
      }
    },
    showSignUpSuccess () {
      this.updateLogin({
        formType: 'SignUpSuccess'
      })
    },
    showSignUpFail (msg) {
      this.updateLogin({
        formType: 'Error',
        formErrMsg: msg
      })
    },
    handleHrefSignIn () {
      this.updateLogin({
        formType: 'SignIn'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.code-input-container {
  width: 100%;
  min-height: 73px;
  height: 73px;
  position: relative;
  .invalid-container {
    width: 100%;
    text-align: left;
    color: #FF3D00;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 3px;
  }
  .invalid-container::before {
    content: 'i';
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 13px;
    background-color: #FF3D00;
    color: #FFFFFF;
    text-align: center;
    vertical-align: middle;
    font-size: 9px;
    font-weight: bold;
  }
  .mc-input {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    // height: calc(1.5em + 0.75rem + 2px);
    height: 50px;
    // padding: 0.375rem 0.75rem;
    padding: 14px 140px 14px 18px;
    // font-size: 1rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 6px;
  }
  .mc-input.is-invalid {
    border: 1px solid #FF3D00;
  }
  .btn-get-code {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: #0056D2;
    color: #FFFFFF;
    font-size: 14px;
    border: none;
    padding: 5px 11px;
    border-radius: 3px;
    &:focus-visible{
      outline: none;
    }
  }
}
$calcHeight: calc(100vh - 30px - 58px - 558px - 30px);
.sign-up-container {
  display: flex;
  flex-direction: column;
  margin-top: clamp(20px, 70px, $calcHeight);
  .form-container {
    display: flex;
    flex-direction: column;
  }
  .agree-container {
    text-align: left;
    input {
      cursor: pointer;
      vertical-align: bottom;
    }
    label {
      line-height: 18px;
      a {
        color: #007bff;
      }
    }
  }
  .href-sign-in-container {
    text-align: left;
    a {
      color: #007bff;
      cursor: pointer;
    }
  }
  .submit-container {
    width: 100%;
    button {
      width: 100%;
      border: none;
      background-color: #0056D2;
      color: #FFFFFF;
    }
    .disable {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
}
@media (max-width: 320.98px) {
  .code-input-container {
    min-height: 66px;
    height: 66px;
    .btn-get-code {
      right: 7px;
      bottom: 8px;
    }
  }
  .sign-up-container {
    // gap: 15px;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 3px;}
      & > div:last-child {margin-bottom: 0;}
      .mc-input {
        height: 45px;
        padding: 10px 12px;
        border: 1px solid #9B9B9B;
        &::placeholder{
          color: #A7A7A7;
          font-size: clamp(12px, 85%, 16px);
        }
        .btn-get-code {
          font-weight: 400;
        }
      }
    }
    .agree-container {
      input {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      label {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .href-sign-in-container {
      a {
        font-size: 14px;
      }
    }
    .submit-container {
      margin-top: 20px;
      padding-bottom: 110px;
      button {
        font-size: 18px;
        height: 50px;
        font-weight: 500;
        border-radius: 6px;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
@media (min-width: 321px) and (max-width: 375.98px){
  .sign-up-container {
    // gap: 15px;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 8px;}
      & > div:last-child {margin-bottom: 0;}
      .mc-input {
        padding: 14px 12px;
        border: 1px solid #9B9B9B;
        &::placeholder{
          color: #A7A7A7;
        }
        .btn-get-code {
          font-weight: 400;
        }
      }
    }
    .agree-container {
      input {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      label {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .href-sign-in-container {
      a {
        font-size: 14px;
      }
    }
    .submit-container {
      margin-top: 25px;
      padding-bottom: 110px;
      button {
        font-size: 18px;
        height: 50px;
        font-weight: 500;
        border-radius: 6px;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
@media (min-width: 376px) and (max-width: 575.98px) {
  .sign-up-container {
    // gap: 15px;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 7px;}
      & > div:last-child {margin-bottom: 0;}
      .mc-input {
        padding: 14px 12px;
        border: 1px solid #9B9B9B;
        &::placeholder{
          color: #A7A7A7;
        }
        .btn-get-code {
          font-weight: 400;
        }
      }
    }
    .agree-container {
      input {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      label {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .href-sign-in-container {
      a {
        font-size: 14px;
      }
    }
    .submit-container {
      margin-top: 20px;
      padding-bottom: 50px;
      button {
        font-size: 18px;
        height: 50px;
        font-weight: 500;
        border-radius: 6px;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .sign-up-container {
    // gap: 15px;
    // margin-top: 10px;
    & > div {margin-bottom: 10px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 0;}
      & > div:last-child {margin-bottom: 0;}
      .mc-input {
        padding: 14px 12px;
        border: 1px solid #9B9B9B;
        &::placeholder{
          color: #A7A7A7;
        }
        .btn-get-code {
          font-weight: 400;
        }
      }
    }
    .agree-container {
      input {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      label {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .href-sign-in-container {
      a {
        font-size: 14px;
      }
    }
    .submit-container {
      margin-top: 20px;
      button {
        font-size: 18px;
        height: 50px;
        font-weight: 500;
        border-radius: 6px;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .sign-up-container {
    // gap: 15px;
    margin-top: 0;
    & > div {margin-bottom: 10px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 3px;}
      & > div:last-child {margin-bottom: 0;}
    }
    .agree-container {
      input {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      label {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .href-sign-in-container {
      a {
        font-size: 14px;
      }
    }
    .submit-container {
      margin-top: 15px;
      button {
        font-size: 18px;
        height: 50px;
        border-radius: 6px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .sign-up-container {
    // gap: 15px;
    margin-top: 0;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 8px;}
      & > div:last-child {margin-bottom: 0;}
    }
    .agree-container {
      input {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      label {
        font-size: 14px;
      }
    }
    .href-sign-in-container {
      a {
        font-size: 14px;
      }
    }
    .submit-container {
      margin-top: 15px;
      button {
        font-size: 18px;
        height: 50px;
        border-radius: 6px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .sign-up-container {
    // gap: 15px;
    margin-top: 0;
    & > div {margin-bottom: 15px;}
    & > div:last-child {margin-bottom: 0;}
    .form-container {
      // gap: 8px;
      & > div {margin-bottom: 8px;}
      & > div:last-child {margin-bottom: 0;}
    }
    .agree-container {
      input {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      label {
        font-size: 14px;
      }
    }
    .href-sign-in-container {
      a {
        font-size: 14px;
      }
    }
    .submit-container {
      margin-top: 15px;
      button {
        font-size: 18px;
        height: 50px;
        border-radius: 6px;
      }
    }
  }
}
</style>
