<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->

    <router-view v-loading.fullscreen.lock="isLoading"> </router-view>
  </div>
</template>
<script>
export default {
  created() {
    this.resizeEvent()
    window.addEventListener('resize', this.resizeEvent)
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading
    },
  },
  methods: {
    resizeEvent() {
      if (window.innerWidth < 768) {
        this.$store.commit('setIsMobile', true)
      } else {
        this.$store.commit('setIsMobile', false)
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeEvent)
  },
}
</script>
<style lang="scss">
html {
  overflow-y: scroll;
}
#app {
  font-family: Helvetica, 'PingFang', 'Microsoft Yahei', '微軟雅黑', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  input,
  button,
  select {
    font-family: Helvetica, 'PingFang', 'Microsoft Yahei', '微軟雅黑', Arial, sans-serif;
    font-weight: 400;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
